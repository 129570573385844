import React, { Component } from 'react';
import { Modal, Input, Button, message } from 'antd';
import styles from './index.module.css';
import { Map, Marker, InfoWindow } from 'react-amap';
import { getDataGeo } from '../../services/serviceArea';
import { analysisAddress } from '../../services/newOder';

class Address extends Component {
  constructor(props) {
    super(props)
    this.state = {
      signAddrList: {
        formatted_address: '',
        province: '',
        city: '',
        district: '',
        longitude: '120.145369',
        latitude: '30.238845',
        hasWorker: false,
      },
      price: 0,
      minDistance: 0,
      noneWorker: false,
    }
  }

  render() {
    let { signAddrList, hasWorker, price, minDistance, noneWorker } = this.state
    return (
      <div>
        <div className={`${styles['serach']} row-l vertical-lt`}>
          <div className='col vertical-lt padding20'>
            <div className='row-l'>
              <div className={styles['div_address_title']}>地址查询</div>
              <div className={styles['div_address_explain']}>(请详细输入客户的地址到市县区和小区名字，以方便地址确认无误；本地址查询仅针对县城及以上区域的主城区的查询工作，乡镇以及村乡等因地图功能受限，以在线客服查询结果为准！)</div>
            </div>
            <div className='row-l margin-t10 vertical-lt'>
              <div className={'margin-r10'}>快速识别: </div>
              <Input.TextArea
                rows={7}
                className={styles['div_textArea']}
                placeholder="可将客户地址黏贴到这里"
                name='analysisAddress'
                onBlur={this.getQueryGeo} />
            </div>
            <div className='row-l margin-t20'>
              <div className={styles['query_button']} onClick={this.getQueryGeo}>查询</div>
              <div className='margin-l20 row-l fz20'>

                {hasWorker && price === 0 && <div className='color6'>该地区师傅资源充足，请放心下单！</div>}
                {hasWorker && price > 0 && <div className='color2'>距离最近的师傅相差{minDistance}公里，需要收取远程费{price}元</div>}
                {noneWorker && <div className='color2'>该地区暂无师傅，请联系客服开发</div>}
              </div>
              {/* <div className={styles['hasWorker']}> */}


              {/* '该地区暂无师傅，请联系客服开发' */}
              {/* </div> */}
            </div>
          </div>
        </div>
        <div id="content" className={styles['content']}>
          <Map
            plugins={["ToolBar", 'Scale']}
            center={[signAddrList.longitude, signAddrList.latitude]}
            zoom={15}>
            <Marker position={[signAddrList.longitude, signAddrList.latitude]} />
          </Map>
        </div>
      </div>
    )
  }
  //节流
  throttled = (fn, delay = 1000) => {
    let preTiem = Date.now();
    return (event) => {
      const context = this;
      event.persist && event.persist() //保留对事件的引用
      let doTime = Date.now();
      if (doTime - preTiem >= delay) {
        fn.apply(context);
        preTiem = Date.now()
      }
    }
  }
  getQueryGeo = this.throttled(async (e) => {
    let inputEl = document.querySelector('[name="analysisAddress"]');
    let val = inputEl.value;
    if (val === '') {
      message.warning('快速识别客户地址不能为空')
      return;
    }
    // let newVal = '15148751245' + val
    let res = await getDataGeo(val)
    // let rsp = await analysisAddress(newVal)
    let rsp = await analysisAddress(val)
    if (res.status !== 200) {
      message.error(res.data)
      return;
    }
    if (rsp.status !== 200) {
      message.error(rsp.data)
      return;
    }
    let signAddrList = {
      formatted_address: res.data.geocodes[0].formatted_address,
      province: res.data.geocodes[0].province,
      city: res.data.geocodes[0].city ? res.data.geocodes[0].city : res.data.geocodes[0].district,
      district: res.data.geocodes[0].district ? res.data.geocodes[0].district : res.data.geocodes[0].city,
      longitude: res.data.geocodes[0].location.split(',')[0],
      latitude: res.data.geocodes[0].location.split(',')[1],
    }
    let noneWorker = !rsp.data.hasWorker;
    this.setState({
      signAddrList: signAddrList,
      hasWorker: rsp.data.hasWorker,
      price: rsp.data.disFee,
      minDistance: rsp.data.minDistance,
      noneWorker,
    })
  })
}

export default Address