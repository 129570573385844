import React, { Component, createContext } from 'react';
import styles from './BasicLayout.module.css'
import mmLogo from '../resource/png/mmLogo.png';
import history from '../config/history';
import { assembly, getChildren } from './children';
import { CloseCircleFilled, LogoutOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import { Menu, Dropdown, Modal, InputNumber } from 'antd';
import { getFinancebase, getCharge } from '../services/finance';
import { getDepartment_Data, setNavigation_Type, setRequest, getNavigation_Type, removeSession, removeUser, getSession, getBalance } from '../config/storage';
import { connect } from 'dva';
import PayModal from '../routes/finance/pay';

// const SubMenu = Menu.SubMenu;

export const AppContext = createContext();
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: [],
      close: false,
      tagName: [],
      tagType: '',
      shopName: '',
      subMenu: false,
      subMenuType: '',
      balance: '',
      debt: '',
      userName: '',
      sale: '',
    };
    this.childRef = React.createRef();
  }
  componentDidMount() {
    if (!getDepartment_Data() || !getSession()) {
      this.outLogin();
      return;
    };
    const { shopName, balance, debt, userName, sale, role } = getDepartment_Data();
    let tagName = ['工作台'];
    let tagType = '工作台';
    if (role === '加盟商') {
      tagName = ['新建订单'];
      tagType = '新建订单';
    }
    this.setState({
      tagName,
      tagType,
      shopName: shopName,
      balance: balance,
      debt: debt,
      userName: userName,
      sale: sale,
    });
  }

  render() {
    const { balance, } = this.props;
    const { path, close, tagName, tagType, shopName, debt, userName, subMenu, subMenuType, } = this.state;
    const menu = () => {
      return (
        <Menu>
          <Menu.Item key={'全部'} onClick={this.onDel.bind(this, '全部')}>
            关闭全部选项
          </Menu.Item>
          <Menu.Item key={'其他'} onClick={this.onDel.bind(this, '其他')}>
            关闭其他选项
          </Menu.Item>
        </Menu>
      )
    }
    return (
      <AppContext.Provider value={{
        path: path,
        // setPath: setPath
      }}>
        <div style={{ minWidth: '1920px' }}>
          <div className={`${styles['sidebar']} ${close && styles['close']}`}>
            <div className={`${styles['logo-details']} col`}>
              <i>
                <img alt="logo" src={mmLogo} />
              </i>
              <span className={styles['logo_name']} >墨墨管家</span>
            </div>
            <ul className={styles['nav-links']}>
              {this.createMenu(assembly)}
              {
                false && assembly.map((item, index) => {
                  if (this.getRole(item)) {
                    return (
                      <li key={item.path} className={`${tagType === item.name && styles['left-li-style']}`}>
                        <div className={`${styles['iocn-link']} cursor-pointer`} onClick={this.onSetTagName.bind(this, item.name)}>
                          <div>
                            <i>
                              {item.icon}
                            </i>
                            <span className={styles['link_name']}>{item.name}</span>
                          </div>
                          <ul className={`${styles['sub-menu']} ${styles['blank']}`}>
                            <li>
                              <div className={styles['link_name']}>{item.name}</div>
                            </li>
                          </ul>
                        </div>
                      </li>
                    );
                  }
                })
              }
            </ul>
          </div>
          <section id='contents' className={styles['home-section']}>
            <div className={styles['home-top']}>
              <div className={`${styles['home-content']} row-sb`}>
                <div className='row-l'>
                  <div className={`${close ? styles['rotateChange1'] : styles['rotateChange']}`} onClick={this.toClose}>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <span className={styles['text']}>欢迎登录墨墨管家测量安装服务系统</span>
                </div>
                <div className={`${styles['home-content-rigth']} row-l`}>
                  <div>账户余额:<span className='fz24 color2 margin-l10'>{balance === 0 ? getBalance() : balance}</span>元</div>
                  <div onClick={this.showModal} className={`${styles['home-content-button']} margin-l10`}>充值</div>
                  <div className='margin-l10 margin-r10'>信用额度: <span>{debt}</span></div>
                  <div className='margin-l10 fz20'>{'【' + shopName + '】'}</div>
                </div>
              </div>
              <div className={`${styles['navigation-tag']} row-sb`}>
                <div className={`${styles['tag-div']} row-l`}>
                  {
                    tagName.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className={`${styles['tag-right']} row ${tagType === item && styles['tag-right-color']}`}
                          onClick={this.onClickTagName.bind(this, item)}
                        >
                          <span style={{ marginRight: 5 }}>{item}</span>
                          <div onClick={this.onDelTagName.bind(this, item)}>
                            <CloseCircleFilled className={styles['div-icon-color']} />
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
                <div className={`${styles['tag-operation']} row-l`}>
                  <div className={styles['tag-right']}>
                    <Dropdown overlay={menu}>
                      <div>
                        关闭操作
                        <DownOutlined />
                      </div>
                    </Dropdown>
                  </div>
                  <div className={styles['tag-right']}>{userName},您好!</div>
                  <div className={`${styles['tag-right']} row`} onClick={this.outLogin}>
                    <LogoutOutlined style={{ fontSize: 14, marginRight: 5 }} />退出登录
                  </div>
                </div>
              </div>
            </div>
            <div className={`${tagType === '服务区域' ? styles['home-children1'] : styles['home-children']}`}>
              {getChildren(tagType, this.onSetTagName)}
              {/* {children} */}
            </div>
          </section>
        </div>
        <PayModal ref={this.childRef} />
      </AppContext.Provider>
    );
  }
  toClose = () => {
    this.setState({
      close: !this.state.close
    })
  }
  //左侧导航
  createMenu = (data) => {
    const { tagType, subMenu, subMenuType } = this.state;
    let newData = data.map((item, index) => {
      if (this.getRole(item)) {
        if (item.childRoutes && item.childRoutes.length > 0) {
          return (
            <li key={item.path} onClick={this.subMenuTypes.bind(this, item.name)}>
              <div className={`${styles['iocn-link']} col vertical-lt`}>
                <div className='col'>
                  <div>
                    <i>
                      {item.icon}
                    </i>
                    <span className={styles['link_name']}>{item.name}</span>
                    {(subMenu && subMenuType === item.name) ?
                      <UpOutlined className={`${styles['bxs-chevron-down']}`} />
                      : <DownOutlined className={`${styles['bxs-chevron-down']}`} />}
                  </div>
                </div>
                {(subMenu && subMenuType === item.name) && <div className={'col w100'}>
                  {
                    item.childRoutes.map((cItem, cIndex) => {
                      return (
                        <div key={cItem.path} onClick={this.onSetNameClick.bind(this, cItem.name)}
                          className={`${styles['link_name1']} ${tagType === cItem.name && styles['left-li-style']}`}
                        >{cItem.name}</div>
                      )
                    })
                  }
                </div>}
              </div>
            </li>
          )
        } else {
          return (
            <li key={item.path} className={`${tagType === item.name && styles['left-li-style']}`}>
              <div className={styles['iocn-link']} onClick={this.onSetTagName.bind(this, item.name)}>
                <div>
                  <i>
                    {item.icon}
                  </i>
                  <span className={styles['link_name']}>{item.name}</span>
                </div>
                <ul className={`${styles['sub-menu']} ${styles['blank']}`}>
                  <li>
                    <div className={styles['link_name']}>{item.name}</div>
                  </li>
                </ul>
              </div>
            </li>
          )
        }
      }
    });
    return newData;
  };
  getRole = (item) => {
    const { sale } = this.state;
    let permiss = false;
    const { role } = getDepartment_Data();
    if (role === '加盟商') {
      if (['新建订单', '订单中心', '好评订单', '测量数据', '售后列表'].includes(item.name)) {
        permiss = true;
      }
    } else if (['合伙人', '会员'].includes(role)) {
      if (item.name === '杆轨商城') {
        permiss = sale === '是' ? true : false;
      } else {
        permiss = true;
      }
    } else {
    }
    return permiss
  }

  //退出登录
  outLogin = () => {
    removeSession();
    removeUser();
    history.push('/login');
    window.location.replace('');
    window.localStorage.clear();
  }
  //删除标签
  onDelTagName = (item, e) => {
    const { tagName, } = this.state;
    let newTagName = tagName.filter(r => r !== item);
    let tagType = this.state.tagType;
    e.stopPropagation();
    if (item === tagType) {
      setRequest(false)
      tagType = newTagName[0]
    }
    setNavigation_Type(newTagName);
    this.setState({
      tagName: newTagName,
      tagType
    });
  }
  //切换标签
  onClickTagName = (item) => {
    setRequest(false)
    this.setState({
      tagType: item
    });
  }
  //新增标签
  onSetTagName = (e) => {
    const { tagName, } = this.state;
    let type = tagName.includes(e);
    if (!type) {
      setNavigation_Type([...tagName, e])
      setRequest(true)
      this.setState({
        tagName: [...tagName, e]
      });
    } else {
      setRequest(false)
    }
    this.setState({
      tagType: e
    })
  }
  subMenuTypes = (e) => {
    this.setState({
      subMenu: !this.state.subMenu,
      subMenuType: e,
    })
  }

  onSetNameClick = (name, e) => {
    this.onSetTagName(name);
    e.stopPropagation();
  }
  //关闭操作
  onDel = (item) => {
    const { tagType, } = this.state;
    if (item === '全部') {
      setRequest(true);
      setNavigation_Type([]);
      this.setState({
        tagName: [],
        tagType: '',
      });
    } else if (item === '其他') {
      setRequest(false);
      setNavigation_Type([tagType]);
      this.setState({
        tagName: [tagType]
      });
    }
  }

  showModal = () => {

    this.childRef.current.showModal(this.state.balance);
  }


}

// export default Index;
export default connect(({ orderRenter }) => (orderRenter))(Index);