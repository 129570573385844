import React, { Component } from 'react';
import { message, Modal, InputNumber } from 'antd';
import { getCharge } from '../../services/finance';

class PayModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      chargePrice: 0,//充值金额
      balance: '',
    };
  }
  render() {
    const { modal, chargePrice, balance } = this.state;
    return (
      <Modal
        title={'充值'}
        visible={modal}
        width={400}
        onCancel={this.closeModal}
        okText='充值'
        cancelText='取消'
        onOk={this.toCharge}
      >
        <div className='row-l margin-b10'>
          <div className='w110 tl'>当前余额：</div>
          <div className='fz24'>{balance}</div>
          <div className='fz13 color2'>{balance < 0 ? '（当前余额为负哦）' : ''}</div>
        </div>
        <div className='row-l'>

          <div className='w110 tl'>充值金额：</div>
          <InputNumber onChange={this.onChangeCharge.bind(this)} value={chargePrice} className='w150 margin-r10' />
          <div>元</div>
        </div>
      </Modal>
    );

  }
  showModal = (e) => {
    this.setState({
      modal: true,
      balance: e
    });
  }
  onChangeCharge = (e) => {
    this.setState({
      chargePrice: e
    });
  }
  closeModal = () => {
    this.setState({
      modal: false
    });
  }
  toCharge = async () => {
    const { chargePrice } = this.state;
    if (!chargePrice || chargePrice < 0) {
      message.error('请输入正确金额')
      return;
    }
    let res = await getCharge(chargePrice);
    if (res.status === 200) {
      let divForm = document.getElementsByTagName('div')
      // let divForm = document.getElementsByTagName('divform')
      if (divForm.length) {
        document.body.removeChild(divForm[0])
      }
      const div = document.createElement('div');
      // const div = document.createElement('divform');
      div.innerHTML = res.data;
      document.body.appendChild(div);
      // document.forms[0].setAttribute('target', '_blank')
      document.forms[0].submit();
      this.setState({
        modal: false,
      });
    }
  }
}
export default PayModal;