import React, { Component } from 'react';
import { Modal, Image, Radio, Upload, Popconfirm, message, Input, Table, Spin, InputNumber, Button } from 'antd';
import styles from './index.module.css';
import { searchConvertObj } from '../../utils/getSearch';
import { getOrderInfo, } from '../../services/orderRenter';

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderid: 484952,
      shop: 128362,
      orderInfo: {
        serviceDatas: [],
        pic_remark: [],
      },
    };
    // /orderinfo?orderid=484950&shop=128362
  }
  componentDidMount() {
    let result = searchConvertObj(window.location.search);
    this.setState({
      orderid: result.orderid,
      shop: result.shop,
    });
    this.getOrderInfo(result.orderid, result.shop);
  }
  render() {
    const { orderInfo, } = this.state;
    return (
      <div className='border1'>
        <div className='w100 tc fz20 margin-b20'>订单详情</div>
        <div className='row-l'>
          <div className='w80  margin-r10'> 订单编号：</div>
          <div >{orderInfo.id}</div>
        </div>
        <div className='row-l'>
          <div className='w80  margin-r10'> 类型：</div>
          <div >{orderInfo.service}</div>
        </div>
        <div className='row-l'>
          <div className='w80 margin-r10'> 客户名称：</div>
          <div >{orderInfo.name}</div>
        </div>
        <div className='row-l'>
          <div className='w80 margin-r10'> 电话：</div>
          <div >{orderInfo.phone}</div>
        </div>
        <div className='row-l'>
          <div className='w80 margin-r10'> 地址：</div>
          <div >{orderInfo.province}{orderInfo.city}{orderInfo.district}</div>
        </div>
        <div className='row-l margin-b20'>
          <div className='w80 margin-r10'> 详细地址：</div>
          <div >{orderInfo.address}</div>
        </div>
        {
          orderInfo.service === '安装' &&
          <div className='margin-b20 border-t2'>
            <div></div>
            {
              orderInfo.serviceDatas.map((item, index) => {
                return (
                  <div key={index}>
                    <div className='fz18 margin-b5'>{item.room}</div>
                    <table>
                      <tbody>
                        <tr className={styles['trd']}>
                          <td className={styles['trd']}>数量</td>
                          <td className={styles['trd']}>长度</td>
                          <td className={styles['trd']}>品类</td>
                          <td className={styles['trd']}>类型</td>
                          <td className={styles['trd']}>层数</td>
                        </tr>
                        <tr className={styles['trd']}>
                          <td className={styles['trd']}>{item.prop2}</td>
                          <td className={styles['trd']}>{item.width}</td>
                          <td className={styles['trd']}>{item.prop1}</td>
                          <td className={styles['trd']}>
                            {item.prop3 && JSON.parse(item.prop3) ? JSON.parse(item.prop3).name : ''}
                          </td>
                          <td className={styles['trd']}>
                            {item.prop4 && JSON.parse(item.prop4) ? JSON.parse(item.prop4).name : ''}
                          </td>
                        </tr>
                        <tr className={styles['trd']}>
                          <td className={styles['trd']}>四角钩</td>
                          <td className={styles['trd']}>墙钩</td>
                          <td className={styles['trd']}>窗幔</td>
                          <td className={styles['trd']}>超高</td>
                          <td className={styles['trd']}>其他</td>
                        </tr>
                        <tr className={styles['trd']}>
                          <td className={styles['trd']}>
                            {item.prop5 && JSON.parse(item.prop5) ? JSON.parse(item.prop5).name : ''}
                          </td>
                          <td className={styles['trd']}>
                            {item.prop6 && JSON.parse(item.prop6) ? JSON.parse(item.prop6).name : ''}
                          </td>
                          <td className={styles['trd']}>
                            {item.prop7 && JSON.parse(item.prop7) ? JSON.parse(item.prop7).name : ''}
                          </td>
                          <td className={styles['trd']}>
                            {item.prop8 && JSON.parse(item.prop8) ? JSON.parse(item.prop8).name : ''}
                          </td>
                          <td className={styles['trd']}>
                            {item.prop9 && JSON.parse(item.prop9) ? JSON.parse(item.prop9).name : ''}
                          </td>
                        </tr>
                      </tbody>
                    </table>

                  </div>
                );
              })
            }
          </div>
        }
        <div className='row-w w100 border-t2 padding-tb10'>
          <div className='w80 margin-r10 fz20'> 图片备注</div>
          <div className='w100'>
            {
              orderInfo.pic_remark.map((item, index) => {
                return (
                  <Image src={`https://ljkj-mmgj-temp.oss-cn-hangzhou.aliyuncs.com/upload/${item}`} className='w100' key={index} />
                );
              })
            }
          </div>
        </div>
      </div>
    );
  }
  getOrderInfo = async (id, shop) => {
    let res = await getOrderInfo(id, shop);
    if (res.status === 200) {
      let orderInfo = res.data;
      orderInfo.pic_remark = res.data.pic_remark.includes('[') ? JSON.parse(res.data.pic_remark) : [];
      this.setState({
        orderInfo,
      });
    }
  }
}
export default Index;