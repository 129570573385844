import request from '../utils/request';

export async function postlogin(data) {
    return await request('open/login/shop', 'post', data);
}
export async function getRegisterCode(department, uid) {
    return await request(`open/shop/register/code?department=${department}&uid=${uid}`, 'get');
}
export async function postRegister(data) {
    return await request('open/shop/register', 'post', data);
}

export async function getMdypwdCode(department, uid) {
    return await request(`open/shop/mdypwd/code?department=${department}&uid=${uid}`, 'get');
}
export async function putMdypwd(data) {
    return await request('open/shop/mdypwd', 'put', data);
}
