import request from '../utils/request';

export async function getBasedata() {
  return await request('shopBiz/basedata', 'get');
}

export async function putBasedata(data) {
  return await request('shopBiz/basedata', 'put', data);
}

export async function postAddUser(data) {
  return await request('shopBiz/adduser', 'post', data);
}

export async function putMdyUser(id, name, phone, enable) {
  return await request(`shopBiz/mdyuser?id=${id}&name=${name}&phone=${phone}&enable=${enable}`, 'put');
}

export async function putResetpwd(id, data) {
  return await request(`shopBiz/resetpwd?id=${id}`, 'put', data);
}

export async function getShopVideos(id) {
  return await request(`platBiz/getvideo?id=${id}`, 'get');
}
export async function putShopVideos(id, data) {
  return await request(`platBiz/putvideo?id=${id}`, 'put', data);
}

