import React, { Component } from 'react';
import { Modal, Image, Radio, Upload, Popconfirm, message, Input, Table, Spin, InputNumber, Button } from 'antd';
import { DeleteOutlined, EyeOutlined, PlusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { getOutInfo, } from '../../services/directOrder';
import { setDepartment_Data, setUser, setSession, setOssBaseAuth, setBalance, setOutAuth, setClient } from '../../config/storage';
import { getCategory, getPrice, } from '../../services/newOder';
import { putOrder } from '../../services/orderRenter';
import styles from './index.module.css';
import Regin from '../../components/region';
import RoomModal from './roomModal';
import { searchConvertObj } from '../../utils/getSearch';
import _ from 'lodash';

const serviceDatas = {
  sort: 0,
  id: 0,
  order: 0,
  room: '窗户1',
  width: '1',
  width_remark: '',
  height: '0',
  height_remark: '',
  prop1: '',
  prop2: '1',
  prop3: '',
  prop4: '',
  prop5: '',
  prop6: '',
  prop7: '',
  prop8: '',
  prop9: '',
  prop10: '',
  prop11: '',
  prop12: '',
  prop13: '',
  prop14: '',
  prop15: '',
  state: '已确认',
  pic: '',
  remark: '',
}

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      baseUrl: '',
      orderInfo: {
        add_app: 0,
      },
      loginInfo: {},
      shopRemark: {
        shop_remark: '',
        customTime: '',
        customRemark: '',
      },
      pics: [
        '1154-92-128362-105090-109ltsc0gkh.jpg',
        '1154-92-128362-105090-109ltsc0k9i.jpg',
        '1154-92-128362-105090-109ltsc0gkh.jpg',
        '1154-92-128362-105090-109ltsc0k9i.jpg',
        '1154-92-128362-105090-109ltsc0gkh.jpg',
      ],
      columns: [],
      columnsDetails: [],
      postData: [],
      roomModal: {
        visible: false,
        index: -1,
        name: '',
      },
      datas: [],
      itemPrice: 0,
      details: [],
      modify: false,
      tableLoading: false,
    };
    this.roomModal = React.createRef();
  }
  componentDidMount() {
    let result = searchConvertObj(window.location.search);
    setOutAuth(result.sign);
    this.getOrderInfo(result.orderid);
  }
  render() {
    const { orderInfo, pics, baseUrl, shopRemark, modify,
      columns, columnsDetails, postData, itemPrice, tableLoading } = this.state;
    return (
      <div className='col-t vertical-lt padding5'>
        <div className='fz20'>当前订单详情：</div>
        <div className='row-l margin-t10 margin-l20'>
          <div className=''>当前状态：</div>
          <div className='color2 fz18'>{orderInfo.state}</div>

        </div>
        <div className='row-l'>
          <div className='w80 margin-l20'>客户姓名：</div>
          <Input disabled={!modify} value={orderInfo.name} className='w200' onChange={this.changeInfo.bind(this, 'name')} />
          <div className='w80 margin-l10'>客户手机：</div>
          <Input disabled={!modify} value={orderInfo.phone} className='w200' onChange={this.changeInfo.bind(this, 'phone')} />
          <div className='margin-l10'>客户地址：</div>
          {/* <Input placeholder='' className='w295'/> */}
          <Regin disable={!modify} value={[orderInfo.province, orderInfo.city, orderInfo.district]} widths={'w150'}
            onChange={this.changeInfo.bind(this, 'provinces')} />
          <div className='margin-r10  margin-l20'> 详细地址：</div>
          <Input.TextArea disabled={!modify} autoComplete="off" rows={2} className='w500' value={orderInfo.address}
            onChange={this.changeInfo.bind(this, 'address')} />
        </div>
        {
          orderInfo.service === '安装' && orderInfo.category !== '其他' && orderInfo.category !== '地板'
          && orderInfo.type !== '二次上门' && orderInfo.type !== '售后订单' &&
          <div className='col-t vertical-lt w100 margin-t10 padding10'>
            <div className='row-l margin-l20 margin-b10'>
              <div className='w80'>安装信息：</div>
              {
                modify &&
                <div className={`${styles['top-right-empty']}`} onClick={this.onAddData}><PlusCircleOutlined /> 新增产品</div>
              }
            </div>
            <Table
              columns={modify ? columns : columnsDetails}
              dataSource={postData}
              size={'small'}
              rowKey={record => record.id + Math.random()}
              className='w100'
              bordered
              pagination={false}
              loading={tableLoading}
            />
          </div>
        }
        <div className='row-l vertical-lt w100 margin-t10'>
          <div className='w80 margin-l20'>图片备注：</div>
          <div className='row-w w100'>
            {
              modify &&
              <Upload
                type='file'
                beforeUpload={this.onAddPic}
                fileList={[]}
                accept='image/*'
                multiple={true}
              >
                <div className={`row cursor-pointer ${styles['pic-div']}`}>
                  <div className="row h100">
                    <PlusOutlined />
                    <span>
                      新增图片
                    </span>
                  </div>
                </div>
              </Upload>
            }
            {
              pics.map((item, index) => {
                return (
                  <div key={index} className={`${styles['content']}`}>
                    <div className={`row ${styles['pic-icon']}`}
                      onClick={this.delPic.bind(this, index)}
                    >
                      <DeleteOutlined />
                    </div>
                    <img
                      alt="暂无图片"
                      className={`${styles['pic']}`}
                      src={`${baseUrl}/upload/${item}`}
                    />
                    <div className={`row ${styles['content-div']}`}>
                      <EyeOutlined
                        className={`${styles['pic-icon-E']}`}
                        onClick={this.preview.bind(this, `${baseUrl}/upload/${item}`)}
                        theme="outlined"
                        title="预览"
                      />
                    </div>
                  </div>
                );
              })
            }

          </div>
        </div>
        <div className='row-l vertical-lt w100 margin-t10'>
          <div className='w80 margin-l20'>订单备注：</div>
          <Input.TextArea disabled={!modify} value={shopRemark.shop_remark} onChange={this.changeInfo.bind(this, 'shop_remark')} className='w1550' rows={2} />
        </div>
        <div className='w100 padding10 h200'></div>
        <div className={`${styles['footer']} row-sb w100`}>
          <div>
            <div className='fz22'>订单费用：</div>
            <div className='fz16 margin-t10'>特殊费用：{orderInfo.add_app}</div>
            <div className='row-l w900'>
              <div className='fz16'>特殊费用备注：</div>
              <div>{orderInfo.add_remark}</div>
            </div>
            <div className='row-l w100'>
              <div className='fz16'>应付金额：</div>
              <div className='fz18 bold color2'>
                {
                  orderInfo.service === '安装' && !['地板', '其他'].includes(orderInfo.category) ?
                    this.getAllPrices() + orderInfo.add_app : itemPrice + orderInfo.add_app
                }
              </div>
            </div>
          </div>
          {
            orderInfo.state === '草稿箱' &&
            <div className='col margin-r140'>
              <Button onClick={this.onOk.bind(this, '修改')} type='danger' className='w180 h34 tc'>保存</Button>
              <Button onClick={this.onOk.bind(this, '下单')} type='primary' className='w180 h34 tc margin-t10'>下单</Button>
            </div>
          }
        </div>
        <RoomModal ref={this.roomModal} updateRoom={this.getInputVaule} />
      </div>
    );
  }
  changeInfo = (type, e) => {
    const { } = this.state;
    let orderInfo = _.cloneDeep(this.state.orderInfo);
    let shopRemark = _.cloneDeep(this.state.shopRemark);
    switch (type) {
      case 'name':
      case 'phone':
      case 'address':
        orderInfo[type] = e.target.value;
        break;
      case 'provinces':
        orderInfo.province = e[0];
        orderInfo.city = e[1];
        orderInfo.district = e[2];
        break;
      case 'shop_remark':
        shopRemark[type] = e.target.value;
        break;
    }
    this.setState({
      orderInfo, shopRemark
    });
  }
  getOrderInfo = async (id) => {
    let res = await getOutInfo(id);
    if (res.status === 200) {
      setDepartment_Data(res.data.login);
      setUser(res.data.login.key);
      setSession(res.data.login.session);
      setBalance(res.data.login.balance)
      this.setState({
        loading: false,
      });
      let oss = {
        host: res.data.login.service + '/',
        expiration: 0,
        key: '',
        policy: '',
        prefix: '',
        signature: '',
      }
      setOssBaseAuth(oss);
      let shopRemark = {
        shop_remark: '',
        customTime: '',
        customRemark: '',
      };
      this.getData(res.data.order.category, res.data.login.shopId, res.data.order);

      let remarkSum = res.data.order.shop_remark.split('✱');
      if (remarkSum.length === 3) {
        shopRemark.customTime = remarkSum[1] ? remarkSum[1] : '';
        shopRemark.customRemark = remarkSum[2] ? remarkSum[2] : '';
      }
      shopRemark.shop_remark = remarkSum[0]
      let pics = JSON.parse(res.data.order.pic_remark);
      let modify = res.data.order.state === '草稿箱' ? true : false;

      this.setState({
        orderInfo: res.data.order,
        loginInfo: res.data.login,
        postData: res.data.order.serviceDatas,
        baseUrl: res.data.login.service,
        pics, modify,
      });
    }
  }
  //获取表格类型
  getCaseType = (types, prop) => {
    let caseType = '';
    if (types === '窗帘') {
      switch (prop) {
        case 'prop4':
          caseType = '层数';
          break;
        case 'prop5':
          caseType = '四角钩';
          break;
        case 'prop6':
          caseType = '墙钩';
          break;
        case 'prop7':
          caseType = '窗幔';
          break;
        case 'prop8':
          caseType = '超高';
          break;
        case 'prop9':
          caseType = '其他';
          break;
        default:
          break;
      }
    } else if (types === '墙布墙纸') {
      switch (prop) {
        case 'prop4':
          caseType = '规格';
          break;
        case 'prop5':
          caseType = '施工方式';
          break;
        case 'prop6':
          caseType = '施工工艺';
          break;
        case 'prop7':
          caseType = '拆旧';
          break;
        default:
          break;
      }
    } else if (types === '晾衣架') {
      switch (prop) {
        case 'prop4':
          caseType = '驱动方式';
          break;
        case 'prop5':
          caseType = '安装面';
          break;
        case 'prop6':
          caseType = '超高';
          break;
        case 'prop7':
          caseType = '拆旧';
          break;
        default:
          break;
      }
    }
    return caseType;
  }
  delPic = (e) => {
    let pics = _.cloneDeep(this.state.pics);
    pics.splice(e, 1);
    this.setState({
      pics
    });
  }
  //预览
  preview = (url) => {
    let features = "height=500, width=800, top=100, left=100, toolbar=no, menubar=no,scrollbars=no,resizable=no, location=no, status=no";
    window.open(url, "newW", features);
  }
  getData = async (type, shop, orderAll) => {
    this.setState({
      tableLoading: true,
    });
    let res = await getCategory(type, shop);
    if (res.status === 200) {
      let itemPrice = 0;
      orderAll.serviceItems.forEach(item => {
        itemPrice += item.price_app
      })
      let details = [];
      if (type === '测量') {
        let dataIndex = 0;
        res.data.map((ditem, dindex) => {
          if (ditem.type.includes('测量')) {
            dataIndex = dindex
          }
        })
        details[0] = {
          price_app: res.data.price_app
        };
        this.setState({
          datas: res.data[dataIndex],
          details,
          // orderPrice: [res.data.price_app],
          itemPrice: itemPrice,
          tableLoading: false,
        });
      } else {
        let dataIndex = 0;
        res.data.map((ditem, dindex) => {
          if (ditem.type.includes('安装')) {
            dataIndex = dindex
          }
        })
        if (orderAll.category === '地板' || orderAll.category === '其他') {
          itemPrice = orderAll.price_app
        }
        orderAll.serviceDatas.map(async (item, index) => {
          let singleRoom = {
            group: item.prop1,
            price_app: 0,
            price_oms: 0,
          };
          if (orderAll.category !== '地板') {
            singleRoom.detailId = JSON.parse(item.prop3).id;
            singleRoom.detailType = JSON.parse(item.prop3).name;
          }
          details.push(singleRoom);

        })
        this.setState({
          datas: res.data[dataIndex],
          itemPrice: itemPrice,
          details,
        }, () => {
          this.getColumns(type);
          this.getOptionsRadios();
        });
      }

    } else {
      this.setState({
        tableLoading: false,
      });
    }
  }
  //第一次获取品类数据
  getOptionsRadios = () => {
    const { orderInfo, loginInfo, postData, } = this.state;
    let newDetails = _.cloneDeep(this.state.details);
    postData.map(async (item, index) => {
      let detail = this.state.datas.grid.filter(r => r.group === newDetails[index].group)[0].detail;
      let detailAdd = detail.filter(r => r.name === newDetails[index].detailType)[0].add;
      detailAdd.map(ditem => {
        switch (ditem.group) {
          case this.getCaseType(orderInfo.category, 'prop4'):
            newDetails[index][ditem.group] = JSON.parse(item.prop4)
            break;
          case this.getCaseType(orderInfo.category, 'prop5'):
            newDetails[index][ditem.group] = JSON.parse(item.prop5)
            break;
          case this.getCaseType(orderInfo.category, 'prop6'):
            newDetails[index][ditem.group] = JSON.parse(item.prop6)
            break;
          case this.getCaseType(orderInfo.category, 'prop7'):
            newDetails[index][ditem.group] = JSON.parse(item.prop7)
            break;
          case this.getCaseType(orderInfo.category, 'prop8'):
            newDetails[index][ditem.group] = JSON.parse(item.prop8)
            break;
          case this.getCaseType(orderInfo.category, 'prop9'):
            newDetails[index][ditem.group] = JSON.parse(item.prop9)
            break;
          default:
            break;
        }
      })
      if (orderInfo.category === '窗帘') {
        let ids = [];
        ids.push(JSON.parse(item.prop3).id)
        if (item.prop4) {
          ids.push(JSON.parse(item.prop4).id)
        }
        if (item.prop5) {
          ids.push(JSON.parse(item.prop5).id)
        }
        if (item.prop6) {
          ids.push(JSON.parse(item.prop6).id)
        }
        if (item.prop7) {
          ids.push(JSON.parse(item.prop7).id)
        }
        if (item.prop8) {
          ids.push(JSON.parse(item.prop8).id)
        }
        if (item.prop9) {
          ids.push(JSON.parse(item.prop9).id)
        }
        let itemid = ids.join('|');
        let result = await getPrice(itemid, orderInfo.work, loginInfo.shopId);
        if (result.status !== 200) {
          message.error(result.data)
        }
        let local = index;
        newDetails[local].price_app = result.data.price_app;
        newDetails[local].price_oms = result.data.price_oms;
        this.setState({
          details: newDetails,
        })
      } else if (res.data.category === '墙布墙纸' || res.data.category === '晾衣架') {
        newDetails[local].price_app = item.prop8;
        newDetails[local].price_oms = item.prop9;
      }
    });
    this.setState({
      details: newDetails,
    })
  }
  //列名
  getColumns = (type) => {
    let columns = [];
    let columnsDetails = [];
    if (type === '窗帘') {
      columns = [
        {
          title: '房间名称',
          align: 'center',
          width: 100,
          render: (text, record, index) => {
            return (
              <div className={styles['room_div_style']} onClick={this.getRoomModal.bind(this, index, record.room)}>{record.room}</div>
            )
          }
        },
        {
          title: '长度（米）',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <InputNumber mix={0} defaultValue={record.width} onBlur={this.getInputVaule.bind(this, 'width', index)} />
            )
          }
        },
        {
          title: '品类',
          align: 'center',
          width: 300,
          render: (text, record, index) => {
            if (JSON.stringify(this.state.datas) === '{}') {
              return;
            }
            let detail = this.state.datas.grid.filter(r => r.group === this.state.details[index].group)[0].detail;
            return (
              <div className='row-sb'>
                <div className='col'>
                  {
                    this.state.datas.grid.map((ditem, dindex) => {
                      return (
                        <div
                          className={`${styles['group-div']} ${this.state.details[index].group === ditem.group && styles['group-div-color']}`}
                          key={dindex} onClick={this.onClickGrid.bind(this, ditem.group, index)}
                        >
                          {ditem.group}
                        </div>
                      )
                    })
                  }
                </div>
                <div className={styles['vertical-line']}></div>
                <Radio.Group value={this.state.details[index].detailType} className='row-w'>
                  {
                    detail.map((ditem, dindex) => {
                      return (
                        <Radio value={ditem.name} key={dindex}
                          onClick={this.onClickDetail.bind(this, ditem.name, ditem.id, index)}
                        >{ditem.name}</Radio>
                      )
                    })
                  }
                </Radio.Group>
              </div>
            );
          }
        },
        {
          title: '层数',
          align: 'center',
          width: 150,
          render: (text, record, index) => {
            return (
              this.tableType('层数', index)
            )
          }
        },
        {
          title: '四角钩',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              this.tableType('四角钩', index)
            )
          }
        },
        {
          title: '墙钩',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              this.tableType('墙钩', index)
            )
          }
        },
        {
          title: '窗幔',
          align: 'center',
          width: 150,
          render: (text, record, index) => {
            return (
              this.tableType('窗幔', index)
            )
          }
        },
        {
          title: '超高',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              this.tableType('超高', index)
            )
          }
        },
        {
          title: '其他',
          align: 'center',
          width: 200,
          render: (text, record, index) => {
            return (
              this.tableType('其他', index)
            )
          }
        },
        {
          title: '数量',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <InputNumber min={1} defaultValue={record.prop2} onBlur={this.getInputVaule.bind(this, 'prop2', index)} />
            )
          }
        },
        {
          title: '费用',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div>
                {this.getProp2Price(record.prop2, index)}
              </div>
            )
          }
        },
        {
          title: '操作',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div><DeleteOutlined className={styles['delete-style']} onClick={this.ondelData.bind(this, index)} /></div>
            )
          }
        },
      ];
      columnsDetails = [
        {
          title: '名称',
          align: 'center',
          dataIndex: 'room',
          width: 100,
        },
        {
          title: '长度（米）',
          align: 'center',
          dataIndex: 'width',
          width: 80,
        },
        {
          title: '品类',
          align: 'center',
          dataIndex: 'prop1',
          width: 80,
        },
        {
          title: '类型',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div>{record.prop3 !== '' && JSON.parse(record.prop3).name}</div>
            )
          }
        },
        {
          title: '层数',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div>{record.prop4 !== '' && JSON.parse(record.prop4).name}</div>
            )
          }
        },
        {
          title: '四角钩',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div>{record.prop5 !== '' && JSON.parse(record.prop5).name}</div>
            )
          }
        },
        {
          title: '墙钩',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div>{record.prop6 !== '' && JSON.parse(record.prop6).name}</div>
            )
          }
        },
        {
          title: '窗幔',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div>{record.prop7 !== '' && JSON.parse(record.prop7).name}</div>
            )
          }
        },
        {
          title: '超高',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div>{record.prop8 !== '' && JSON.parse(record.prop8).name}</div>
            )
          }
        },
        {
          title: '其他',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div>{record.prop9 !== '' && JSON.parse(record.prop9).name}</div>
            )
          }
        },
        {
          title: '数量',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div>{record.prop2}</div>
            )
          }
        },
        {
          title: '费用',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div>{record.prop10}</div>
            )
          }
        },
      ];
    }
    this.setState({
      columns: columns,
      columnsDetails: columnsDetails,
      tableLoading: false,
    })
  }
  //table品类
  tableType = (itemType, index) => {
    const { datas, details } = this.state;
    if (JSON.stringify(datas) === '{}' || !details[index]) {
      return;
    }
    let detail = datas.grid.filter(r => r.group === details[index].group)[0].detail;
    let detailAdd = detail.filter(r => r.name === details[index].detailType)[0].add;
    let options = detailAdd.filter(r => r.group === itemType);
    return (
      <div className='col'>
        {
          options.length === 0 ? <div className={styles['options0']}></div> : <Radio.Group value={details[index][itemType] ? details[index][itemType].id : 0} className='row-w'>
            {
              options[0].options.map((oItem, oIndex) => {
                return (
                  <Radio value={oItem.id} key={oIndex} onChange={this.onChangeRadio.bind(this, oItem.name, oItem.id, itemType, index)}>{oItem.name}</Radio>
                )
              })
            }
          </Radio.Group>
        }
      </div>
    )
  }
  //输入框数据
  getInputVaule = (type, index, e) => {
    let value;
    if (type === 'room') {
      value = e;
    } else if (type === 'prop2') {
      value = e.target.value ? e.target.value : '1';
    } else {
      value = e.target.value ? e.target.value : '1';
    }
    let postData = _.cloneDeep(this.state.postData);
    postData[index][type] = value + '';
    this.setState({
      postData: postData,
    })
  }
  getRoomModal = (index, name) => {
    this.roomModal.current.onShow(index, name);
  }
  //品类切换
  onClickGrid = (type, index) => {
    const { datas } = this.state;
    let postData = _.cloneDeep(this.state.postData);
    let details = _.cloneDeep(this.state.details);
    let detail = datas.grid.filter(r => r.group === type)[0].detail;
    postData[index].prop1 = type;
    details[index].group = type
    details[index].detailId = detail[0].id;
    details[index].detailType = detail[0].name;
    detail[0].add.map(ditem => {
      details[index][ditem.group] = { id: ditem.options[0].id, name: ditem.options[0].name };
    })
    this.setState({
      details, postData,
    }, async () => {
      this.adjust(index)
    });
  }
  //类型切换
  onClickDetail = (type, id, index) => {
    const { datas } = this.state;
    let postData = _.cloneDeep(this.state.postData);
    let details = _.cloneDeep(this.state.details);
    let detail = datas.grid.filter(r => r.group === details[index].group)[0].detail;
    let detailAdd = detail.filter(r => r.name === type)[0].add;
    details[index].detailId = id;
    details[index].detailType = type;
    postData[index].prop3 = JSON.stringify({ id: id, name: type });
    detailAdd.map(ditem => {
      details[index][ditem.group] = { id: ditem.options[0].id, name: ditem.options[0].name };
    })
    this.setState({
      details, postData,
    }, async () => {
      this.adjust(index)
    });
  }
  onChangeRadio = (name, id, type, index) => {
    const { orderInfo } = this.state;
    let postData = _.cloneDeep(this.state.postData);
    let details = _.cloneDeep(this.state.details);
    details[index][type] = { id: id, name: name };
    switch (type) {
      case this.getCaseType(orderInfo.category, 'prop4'):
        postData[index].prop4 = JSON.stringify({ id, name });
        break;
      case this.getCaseType(orderInfo.category, 'prop5'):
        postData[index].prop5 = JSON.stringify({ id, name });
        break;
      case this.getCaseType(orderInfo.category, 'prop6'):
        postData[index].prop6 = JSON.stringify({ id, name });
        break;
      case this.getCaseType(orderInfo.category, 'prop7'):
        postData[index].prop7 = JSON.stringify({ id, name });
        break;
      case this.getCaseType(orderInfo.category, 'prop8'):
        postData[index].prop8 = JSON.stringify({ id, name });
        break;
      case this.getCaseType(orderInfo.category, 'prop9'):
        postData[index].prop9 = JSON.stringify({ id, name });
        break;
      default:
        break;
    }
    this.setState({
      details: details,
      postData: postData
    }, async () => this.getPrices(index))
  }
  //数据修改后，修改prop数据
  adjust = (index) => {
    const { details, datas, orderInfo } = this.state;
    let postData = _.cloneDeep(this.state.postData);
    let detail = datas.grid.filter(r => r.group === details[index].group)[0].detail;
    let detailAdd = detail.filter(r => r.name === details[index].detailType)[0].add;
    let prop3ID = detail.filter(r => r.name === details[index].detailType)[0].id
    postData[index].prop3 = JSON.stringify({ id: prop3ID, name: details[index].detailType });
    postData[index].prop4 = '';
    postData[index].prop5 = '';
    postData[index].prop6 = '';
    postData[index].prop7 = '';
    postData[index].prop8 = '';
    postData[index].prop9 = '';
    detailAdd.map(item => {
      switch (item.group) {
        case this.getCaseType(orderInfo.category, 'prop4'):
          postData[index].prop4 = JSON.stringify({ id: item.options[0].id, name: item.options[0].name });
          break;
        case this.getCaseType(orderInfo.category, 'prop5'):
          postData[index].prop5 = JSON.stringify({ id: item.options[0].id, name: item.options[0].name });
          break;
        case this.getCaseType(orderInfo.category, 'prop6'):
          postData[index].prop6 = JSON.stringify({ id: item.options[0].id, name: item.options[0].name });
          break;
        case this.getCaseType(orderInfo.category, 'prop7'):
          postData[index].prop7 = JSON.stringify({ id: item.options[0].id, name: item.options[0].name });
          break;
        case this.getCaseType(orderInfo.category, 'prop8'):
          postData[index].prop8 = JSON.stringify({ id: item.options[0].id, name: item.options[0].name });
          break;
        case this.getCaseType(orderInfo.category, 'prop9'):
          postData[index].prop9 = JSON.stringify({ id: item.options[0].id, name: item.options[0].name });
          break;
        default:
          break;
      }
    })
    this.setState({
      postData,
    }, async () => this.getPrices(index))
  }
  //单条价格
  getProp2Price = (prop2, index) => {
    let { postData, details } = this.state;
    let price = 0;
    let multiple = this.getTypeProp2(postData[index].prop1, postData[index].width);
    let grapplePrice = this.getGrapplePrice(index, multiple);
    price = details[index].price_app * Number(prop2) * multiple - grapplePrice.price_app * Number(prop2);
    return price;
  }
  //超宽系数
  getTypeProp2 = (type, width) => {
    let quantity = 0;
    let widths = Number(width);
    let prop2Multiple = 0;
    if (type === '布帘') {
      quantity = 3;
      if (widths > 1) {
        widths = widths - 1;
      }
    } else if (type === '成品帘') {
      quantity = 2.5;
    } else if (type === '电动成品帘') {
      quantity = 3;
    } else {
      quantity = 5;
    }
    prop2Multiple = Math.floor(widths / quantity);
    prop2Multiple = prop2Multiple + 1
    if (type === '布帘' && widths === 3) {
      prop2Multiple = prop2Multiple - 1
    }
    return prop2Multiple;
  }
  getTypePropOms = (type, width, category) => {
    let prop2Multiple = 1;
    let widths = Number(width);
    if (type === '布帘') {
      if (category.includes('梦幻帘')) {
        prop2Multiple = 1;
      } else {
        if (widths > 4) {
          prop2Multiple = 2;
        }
      }
    }
    return prop2Multiple;
  }
  getGrapplePrice = (index, multiple) => {
    let { details, } = this.state;
    if (details.length === 0) {
      return { price_app: 0, price_oms: 0 };
    }
    if (multiple === 1) {
      return { price_app: 0, price_oms: 0 };
    }
    if (!details[index]['墙钩']) {
      return { price_app: 0, price_oms: 0 };
    }
    if (details[index]['墙钩'].name === '无') {
      return { price_app: 0, price_oms: 0 };
    }
    let newServiceItemG = details[index].serviceItemG;
    let data = newServiceItemG.filter(r => r.service === details[index]['墙钩'].id)[0];
    if (!data) { return { price_app: 0, price_oms: 0 }; }
    let price_app = data.price_app;
    let price_oms = data.price_oms;
    return {
      price_app: price_app * multiple - price_app,
      price_oms: price_oms * multiple - price_oms,
    }
  }
  //计算单个房间价格
  getPrices = async (index) => {
    const { orderInfo, loginInfo, datas } = this.state;
    let details = _.cloneDeep(this.state.details);
    let num = [];
    if (orderInfo.service === '安装') {
      if (['窗帘', '墙布墙纸', '晾衣架'].includes(orderInfo.category)) {
        let circleNum = orderInfo.category === '窗帘' ? 9 : 7;
        for (let i = 4; i < circleNum + 1; i++) {
          if (details[index][this.getCaseType(orderInfo.category, 'prop' + i)]) {
            num.push(Number(details[index][this.getCaseType(orderInfo.category, 'prop' + i)].id));
          }
        }
      }
      if (orderInfo.category === '其他' || orderInfo.category === '地板') {
        measureID = num.push(datas.grid[0].detail[0].id);
      } else {
        num.push(details[index].detailId);
      }
    }
    let itemid = num.join('|');
    let rsp = await getPrice(itemid, orderInfo.work, loginInfo.shopId);
    if (rsp.status !== 200) {
      message.error(res.data)
      return;
    };
    details[index].serviceItemG = rsp.data.serviceItems;
    details[index].price_app = rsp.data.price_app;
    details[index].price_oms = rsp.data.price_oms;
    this.setState({
      details: details,
    });
  }
  onAddData = () => {
    const { orderInfo, datas } = this.state;
    let postData = _.cloneDeep(this.state.postData);
    let details = _.cloneDeep(this.state.details);
    let newData = { ...serviceDatas };
    newData.order = orderInfo.id
    postData.push(newData);

    let newDateil = {
      group: datas.grid[0].group,
      detailId: datas.grid[0].detail[0].id,
      detailType: datas.grid[0].detail[0].name,
      price_app: 0,
      price_oms: 0,
    };
    datas.grid[0].detail[0].add.map(ditem => {
      newDateil[ditem.group] = { id: ditem.options[0].id, name: ditem.options[0].name };
    })
    details.push(newDateil);
    this.setState({
      details, postData
    }, async () => {
      this.adjust(postData.length - 1)
    });
  }
  ondelData = (index) => {
    let postData = _.cloneDeep(this.state.postData);
    let details = _.cloneDeep(this.state.details);
    postData.splice(index, 1);
    details.splice(index, 1);
    this.setState({
      postData, details
    });
  }
  //获取金额
  getAllPrices = () => {
    const { details, orderInfo, postData, itemPrice } = this.state;
    let orderPriceNUM = 0;
    details.map((item, index) => {
      if (orderInfo.service === '测量') {
        orderPriceNUM += item.price_app
      } else {
        let multiple = this.getTypeProp2(postData[index].prop1, postData[index].width);
        let grapplePrice = this.getGrapplePrice(index, multiple);
        orderPriceNUM += item.price_app * Number(postData[index].prop2) * multiple - grapplePrice.price_app * Number(postData[index].prop2);
      }
    });
    let price = orderPriceNUM > orderInfo.shopPrice ? orderPriceNUM : orderInfo.shopPrice;
    return price + itemPrice;
  }
  getPrices_app = (price_app) => {

    return price_app > this.state.shopPrice ? price_app : this.state.shopPrice
  }
  //师傅金额
  getAllPricesOms = () => {
    const { details, postData } = this.state;
    let orderPriceNUM = 0;
    details.forEach((item, index) => {
      let prop3 = postData[index].prop3 ? JSON.parse(postData[index].prop3).name : '';
      let prop9 = postData[index].prop9 ? JSON.parse(postData[index].prop9).name : '';
      let multiple = this.getTypePropOms(postData[index].prop1, postData[index].width, prop3);
      let grapplePrice = this.getGrapplePrice(index, multiple);
      orderPriceNUM += item.price_oms * Number(postData[index].prop2) * multiple - grapplePrice.price_oms * Number(postData[index].prop2);
    });
    let price = orderPriceNUM;
    return price;
  }
  onOk = async (e) => {
    const { shopRemark, details, postData, pics } = this.state;
    let orderInfo = _.cloneDeep(this.state.orderInfo);
    orderInfo.shop_remark = shopRemark.shop_remark + '✱' + shopRemark.customTime + '✱' + shopRemark.customRemark;
    orderInfo.serviceDatas = postData;
    if (orderInfo.service === '安装' && orderInfo.category !== '地板' && orderInfo.category !== '其他') {
      orderInfo.price_app = this.getAllPrices();
      orderInfo.price_oms = this.getAllPricesOms();
    } else {
      orderInfo.price_app = orderInfo.price_app > orderInfo.shopPrice ? orderInfo.price_app : orderInfo.shopPrice;
      orderInfo.price_oms = orderInfo.price_oms > orderInfo.workerPrice ? orderInfo.price_oms : orderInfo.workerPrice;
    }
    orderInfo.pic_remark = JSON.stringify(pics);
    orderInfo.serviceDatas.forEach((item, index) => {
      let prop3 = item.prop3 ? JSON.parse(item.prop3).name : '';
      let prop9 = item.prop9 ? JSON.parse(item.prop9).name : '';
      let multiple = this.getTypeProp2(item.prop1, item.width);
      let multiple2 = this.getTypePropOms(item.prop1, item.width, prop3);
      if (orderInfo.category === '窗帘') {
        item.prop10 = details[index].price_app * Number(item.prop2) * multiple + '';
        item.prop11 = details[index].price_oms * Number(item.prop2) * multiple2 + '';
      } else if (orderInfo.category === '墙布墙纸' || orderInfo.category === '晾衣架') {
        item.prop8 = details[index].price_app * Number(item.prop2) * multiple + '';
        item.prop9 = details[index].price_oms * Number(item.prop2) * multiple + '';
      }
    })
    let serviceWidth = true;
    if (orderInfo.serviceDatas.length > 0) {
      orderInfo.serviceDatas.forEach(item => {
        if (item.width === 0) {
          serviceWidth = false;
        }
      })
    }

    if (!serviceWidth) {
      this.setState({
        postSpin: false
      })
      message.error('请填写长度');
      return;
    }
    let res = await putOrder(orderInfo.id, e, orderInfo);
    if (res.status === 204) {
      if (e === '下单') {
        message.success('下单成功')
        let orderInfos = _.cloneDeep(this.state.orderInfo);
        orderInfos.state = '待分配';
        this.setState({
          orderInfo: orderInfos,
          modify: false,
        });
      } else {
        message.success('保存成功')
      }
    } else {
      message.error(res.data);
      return;
    }
  }
}
export default Index; 