import Cookies from 'js-cookie';

const department_Data = 'department_Data';
export function getDepartment_Data() {
  let obj = localStorage.getItem(department_Data);
  return JSON.parse(obj);
}
export function setDepartment_Data(obj) {
  localStorage.setItem(department_Data, JSON.stringify(obj));
}


const navigation_Type = 'navigation_Type';
export function getNavigation_Type() {
  let obj = localStorage.getItem(navigation_Type);
  return JSON.parse(obj);
}
export function setNavigation_Type(obj) {
  localStorage.setItem(navigation_Type, JSON.stringify(obj));
}


const request = 'request';
export function getRequest() {
  let obj = localStorage.getItem(request);
  return JSON.parse(obj);
}
export function setRequest(obj) {
  localStorage.setItem(request, JSON.stringify(obj));
}
const balance = 'balance';
export function getBalance() {
  let obj = localStorage.getItem(balance);
  return JSON.parse(obj);
}
export function setBalance(obj) {
  localStorage.setItem(balance, JSON.stringify(obj));
}


const enterprise = 'mm_enterprise';
export function getEnterprise() {
  return localStorage.getItem(enterprise);
}
export function setEnterprise(obj) {
  localStorage.setItem(enterprise, obj);
}


const session = 'mm_session';
export function getSession() {
  return Cookies.get(session);
}
export function setSession(obj) {
  Cookies.set(session, obj, { path: '/' });
}
export function removeSession() {
  Cookies.remove(session);
}


const id = 'mm_id';
export function getUser() {
  return Cookies.get(id);
}
export function setUser(obj) {
  Cookies.set(id, obj, { path: '/' });
}
export function removeUser() {
  Cookies.remove(id);
}
//oss权限upload
const ossBaseAuth = 'cloud_ossBaseAuth';
export function getOssBaseAuth() {
  let setting = localStorage.getItem(ossBaseAuth);
  return JSON.parse(setting);
}
export function setOssBaseAuth(obj) {
  localStorage.setItem(ossBaseAuth, JSON.stringify(obj));
}
export function clearOssBaseAuth() {
  localStorage.removeItem('ossBaseAuth');
}
//转单专用Authorization
const outAuth = 'outAuth';
export function getOutAuth() {
  return Cookies.get(outAuth);
}
export function setOutAuth(obj) {
  Cookies.set(outAuth, obj, { path: '/' });
}
const client = 'client';
export function getClient() {
  return Cookies.get(client);
}
export function setClient(obj) {
  Cookies.set(client, obj, { path: '/' });
}
