import React, { Component } from 'react';
import { message, } from 'antd';
import { comom, comom1, comom2, comom3, comom4, } from '../../utils/bonusRemark';
import styles from './index.module.css'

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: [],
      currentIndex: 0,
      title: ['通用好评模板', '麦文好评模板', '金蝉好评模板', '三千金好评模板', '寻家好评模板'],
    };
  }
  componentDidMount() {
    let current = comom;
    current.sort(function () {
      return Math.random() - 0.5
    })
    this.setState({
      current,
    });
  }
  render() {
    const { title, currentIndex, current, } = this.state;
    return (
      <div className='padding5 w100'>
        <div className={`${styles['box']} w100 `}>
          <div className={` ${styles['title']}`}>
            {
              title.map((item, index) => {
                return (
                  <span onClick={this.changeTit.bind(this, index)} key={index} className={`${styles['menu']} ${currentIndex === index ? styles['select'] : ''}`}>{item}</span>
                );
              })
            }
          </div>
        </div>
        <div className='row-l margin-b10'>
          <div className='fz16 color2'>（点击复制）</div>
        </div>
        <div className='col-t vertical-lt w100'>
          {
            current.map((item, index) => {
              return (
                <div onClick={this.copy.bind(this, item)} key={index} className={`${styles['remark']}`}>{index + 1}、{item}</div>
              );
            })
          }
        </div>
      </div>
    );
  }
  changeTit = (e) => {
    let current = [];
    switch (e) {
      case 0:
        current = comom; break;
      case 1:
        current = comom1; break;
      case 2:
        current = comom2; break;
      case 3:
        current = comom3; break;
      case 4:
        current = comom4; break;
    }
    //   var arr = [1, 2, 3, 4, 5]

    current.sort(function () {
      return Math.random() - 0.5
    })
    this.setState({
      currentIndex: e, current,
    });
  }
  copy = (e) => {
    var aux = document.createElement("textarea");
    aux.value = e;
    document.body.appendChild(aux);
    aux.select();
    document.execCommand("copy");
    document.body.removeChild(aux);
    message.success('复制成功');
  }
}
export default Index;

