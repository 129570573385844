function getSearch(url) {
  let newUrl;
  if (url.includes('&search=')) {
    let urlSplit = url.split('&search=');
    if (urlSplit[1].split('&')) {
      let search = urlSplit[1].split('&');
      if (search[0] !== '') {
        search[0] = encodeURIComponent(search[0]);
        urlSplit[1] = search.join('&');
      }
    }
    newUrl = urlSplit.join('&search=');
  }
  return newUrl;
}
export function searchConvertObj(str) {
  let search = decodeURI(str);
  if (search.length === 0) {
    return {};
  }
  let s = search.slice(1);
  let arr = s.split('&');
  if (arr.length === 0) {
    return {};
  }
  let obj = {};
  arr.forEach((element) => {
    let arr = element.split('=');
    if (arr.length === 2) {
      obj[arr[0]] = arr[1];
    }
  });
  return obj;
}
export default getSearch;
