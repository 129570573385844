import 'core-js/stable';
import 'regenerator-runtime/runtime';
import dva from 'dva';
import { createLogger } from 'redux-logger';
import { message } from 'antd';
import history from './config/history';
import { RouterConfig as router } from './config/router';
import './styles/global.css';
import './styles/color.css';
import orderRenter from './models/orderRenter';

// export const history = process.env.NODE_ENV === 'development' ?
//   createBrowserHistory() :
//   createHashHistory();

let config = {
  history: history,
};

if (process.env.NODE_ENV === 'development') {
  config.onError = (e) => { message.error(e.message, 3); };
  config.onAction = createLogger({ collapsed: true });
}

const app = dva(config);
app.model(orderRenter);
app.router(router);
app.start('#root');