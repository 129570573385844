import React, { Component } from 'react';
import { Modal, Image, Popconfirm, Input, Table, Button } from 'antd';
import styles from './index.module.css';
import Code from '../../resource/png/code.png';

class ChargeModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      orderInfo: {},

    };
  }
  render() {
    const { visible } = this.state;
    return (
      <Modal
        title={'充值'}
        width={500}
        visible={visible}
        onCancel={this.onCancel}
        className={'padding0'}
        cancelText='取消'
        okText='关闭'
        onOk={this.onCancel}
      >
        <div className='col w100'>
          <div className='w100 row fz18'>请添加客服微信，进行开户/充值</div>
          <Image src={Code} className='w400' preview={false} />
        </div>
      </Modal>
    );
  }
  onShow = () => {
    this.setState({
      visible: true,
    });
  }
  onCancel = () => {
    this.setState({
      visible: false,
    });
  }
}
export default ChargeModal;