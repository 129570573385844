import React, { Component } from 'react';
import { Modal, message, Input, Table, Button, Radio } from 'antd';
import { getPost, pushOrder, } from '../../services/product';
import Regin from '../../components/region';
const receive = {
  name: '',
  phone: '',
  province: '',
  city: '',
  district: '',
  address: '',
  remark: '',
};
class PostModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      delivery: { ...receive },
      oldInfo: {},
      postlist: [],
    };
  }
  render() {
    const { visible, delivery, postlist } = this.state;
    return (
      <Modal
        title={'快递信息'}
        width={820}
        visible={visible}
        onCancel={this.onCancel}
        className={'padding0'}
        footer={
          <div className='w100 row-r'>
            <Button onClick={this.onCancel}>取消</Button>
            <Button onClick={this.onOk} type='primary'>确认</Button>
          </div>
        }
      >
        <div className='w100'>
          <div className='col-t vertical-lt w100'>
            <div className='fz18'>收货地址：</div>
            <div className='row-l w100'>
              <div className='w80 tr'>收货人：</div>
              <Input value={delivery.name} onChange={this.onChangeInfo.bind(this, 'name')} className='w200 border3' />
              <div className='w80 tr'>联系方式：</div>
              <Input value={delivery.phone} onChange={this.onChangeInfo.bind(this, 'phone')} className='w200 border3' />
            </div>
            <div className='row-l margin-t10'>
              <div className='w80 tr'>省市区：</div>
              <Regin value={[delivery.province, delivery.city, delivery.district]} widths={'w180'}
                onChange={this.onChangeInfo.bind(this, 'provinces')} />
              {/* <Regin value={[delivery.province, delivery.city, delivery.district]} onChange={this.onChangeInfo.bind(this, 'provinces')} /> */}
            </div>
            <div className='row-l vertical-lt margin-t10'>
              <div className='w80 tr'>详细地址：</div>
              <Input.TextArea value={delivery.address} rows={2} onChange={this.onChangeInfo.bind(this, 'address')} className='w500 border3' />
            </div>
          </div>
          <div className='fz18 margin-t10'>快递选择：</div>
          {
            postlist.map((item, index) => {
              return (
                <div className='col-t vertical-lt' key={index}>
                  <div className='w80 tr'>包裹{index + 1}：</div>
                  <div className='row-l vertical-lt'>
                    <div className='w80'></div>
                    <div>选择快递：</div>
                    <Radio.Group value={item.company} onChange={this.changePost.bind(this, 'company', index)} className='w500'>
                      {
                        item.select.map((sitem, sindex) => {
                          return (
                            <Radio value={sitem.company} key={sindex}>{sitem.name}</Radio>
                          );
                        })
                      }
                    </Radio.Group>
                  </div>
                  <div className='row-l vertical-lt margin-t5'>
                    <div className='w80'></div>
                    <div>付款方式：</div>
                    <Radio.Group value={item.choose} onChange={this.changePost.bind(this, 'pay', index)} className='w500'>
                      {
                        item.pays.map((sitem, sindex) => {
                          return (
                            <Radio value={sitem} key={sindex}>{sitem}</Radio>
                          );
                        })
                      }
                    </Radio.Group>
                  </div>
                  <div className='row-l vertical-lt margin-t5'>
                    <div className='w80'></div>
                    <div>备注：</div>
                    <Input value={item.remark} onChange={this.changePost.bind(this, 'remark', index)} className='w500' />
                  </div>
                </div>
              );
            })
          }
        </div>
      </Modal>
    );
  }
  onChangeInfo = (type, e) => {
    let delivery = { ...this.state.delivery };
    let reSelect = false;
    // eslint-disable-next-line default-case
    switch (type) {
      case 'name':
      case 'phone':
      case 'address':
        delivery[type] = e.target.value;
        break;
      case 'provinces':
        if (delivery.province !== e[0]) {
          reSelect = true;
        }
        delivery.province = e[0];
        delivery.city = e[1];
        delivery.district = e[2];
        break;
    }
    this.setState({
      delivery,
    }, () => {
      if (reSelect) {
        this.getPostInfo();
      }
    });
  }
  getPostInfo = async () => {
    const { oldInfo, delivery } = this.state;
    let res = await getPost(oldInfo.mmId, oldInfo.ljId, delivery.province);
    if (res.status === 200) {
      let postlist = res.data ? JSON.parse(res.data) : [];
      postlist = postlist.map(item => {
        let single = {
          name: '',
          company: 0,
          pays: [],
          choose: '',
          select: [],
          platform: '',
          price: 0,
          supplier: 0,
          factory: item.factory,
          deliverDays: item.deliverDays,
        };
        if (item.posts.length > 0) {
          let local = 0;
          // eslint-disable-next-line array-callback-return
          item.posts.map((pitem, pindex) => {
            if (pitem.default) {
              local = pindex;
            }
          });
          single.company = item.posts[local].company;
          single.name = item.posts[local].name;
          if (item.posts[local].default_Pay === '包邮') {
            single.pays = ['包邮'];
          } else if (item.posts[local].pay_type === '仅寄付') {
            single.pays = ['寄付'];
          } else if (item.posts[local].pay_type === '仅到付') {
            single.pays = ['到付'];
          } else if (item.posts[local].pay_type === '均可') {
            single.pays = ['寄付', '到付'];
          }
          // single.pays = item.posts[0].pays;
          if (item.posts[local].default_Pay) {
            single.choose = item.posts[local].default_Pay;
          } else {
            single.choose = single.pays[0];
          }

          single.platform = item.posts[local].platform;
          single.price = item.posts[local].price;
          single.supplier = item.supplier;
          single.select = item.posts;
        }
        return single;
      });
      this.setState({
        postlist
      })
    }
  }
  changePost = (type, index, e) => {
    let posts = _.cloneDeep(this.state.postlist);
    if (type === 'pay') {
      posts[index].choose = e.target.value;
    } else if (type === 'company') {
      let current = posts[index].select.findIndex(r => r.company === e.target.value);
      posts[index].company = e.target.value;
      posts[index].name = posts[index].select[current].name;
      if (posts[index].select[current].default_Pay === '包邮') {
        // posts[index].choose = '包邮';
        posts[index].pays = ['包邮'];
      } else if (posts[index].select[current].pay_type === '仅寄付') {
        // posts[index].choose = '寄付';
        posts[index].pays = ['寄付'];
      } else if (posts[index].select[current].pay_type === '仅到付') {
        // posts[index].choose = '到付';
        posts[index].pays = ['到付'];
      } else if (posts[index].select[current].pay_type === '均可') {
        // posts[index].choose = '寄付';
        posts[index].pays = ['寄付', '到付'];
      }
      if (posts[index].select[current].default_Pay) {
        posts[index].choose = posts[index].select[current].default_Pay;
      } else {
        posts[index].choose = posts[index].pays[0];
      }
      // posts[index].pays = posts[index].select[current].pays;
      posts[index].platform = posts[index].select[current].platform;
      posts[index].price = posts[index].select[current].price;
    } else if (type === 'remark') {
      posts[index].remark = e.target.value;
    }
    this.setState({
      postlist: posts
    });
  }
  onShow = (oldInfo, orderData) => {
    let delivery = {
      ...receive,
      name: orderData.customer2,
      phone: orderData.phone,
      province: orderData.province,
      city: orderData.city,
      district: orderData.district,
      address: orderData.address,
    };
    this.setState({
      visible: true,
      oldInfo, orderData, delivery,
    }, () => this.getPostInfo())
  }
  onCancel = () => {
    this.setState({
      visible: false
    })
  }
  onOk = async () => {
    const { oldInfo, orderData, delivery, postlist } = this.state;
    let postData = postlist.map(item => {
      let single = {
        id: oldInfo.ljId,
        order: 0,
        sort: 0,
        send_type: '',
        send_time: 0,
        urgent: 0,
        length: 0,
        weight: 0,
        add: 0,
        factory: item.factory,
        remark: item.remark ? item.remark : '',
        default: '',
        platform_trade: '',
        state: '',
        deliver: item.deliverDays,
        company: item.company,
        name: item.name,
        platform: item.platform,
        pay: item.choose,
        price: item.price,
        receiver: delivery.name,
        phone: delivery.phone,
        province: delivery.province,
        city: delivery.city,
        district: delivery.district,
        address: delivery.address,
        supplier: item.supplier,
      };
      return single;
    });
    let data = {
      order: oldInfo.ljId,
      receiver: 0,
      packages: postData,
      price: 0,
    };

    let res = await pushOrder(oldInfo.mmId, oldInfo.ljId, data);
    if (res.status === 200) {
      message.warn('推送成功');
      this.onCancel();
      this.props.updateList();
    }
  }
}
export default PostModal;