import React, { Component } from 'react';
import { Modal, message, Popconfirm, Input, Table, Button } from 'antd';
import styles from './index.module.css';
import Regin from '../../components/region';
import { getBasedata, putBasedata } from '../../services/storeManagement';
import { postNewOrder, getOrderInfo, delProduct, putOrder, getPost, pushOrder } from '../../services/product';
import ProductModal from './productModal';
import PostModal from './postModal';
import _ from 'lodash';

const newRoom = {
  id: 0,
  shop: 0,
  name: '',
  phone: '',
  province: '',
  city: '',
  district: '',
  address: '',
  remark: '',
  rooms: [],
};
class OrderModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      orderInfo: {},
      oldInfo: {},
      shopInfo: {},
      data: {
        province: '',
        city: '',
        district: '',
      },
      dataSource: [],
      rooms: [],
    };
    this.productModal = React.createRef();
    this.postModal = React.createRef();
  }
  async componentDidMount() {
    let res = await getBasedata();
    if (res.status === 200) {
      this.setState({
        shopInfo: res.data
      });
    }
  }
  render() {
    const { visible, data, shopInfo, oldInfo, dataSource, rooms, } = this.state;
    let editDisable = data.order_state === '意向' ? false : true;
    this.columns = [
      {
        title: '序号',
        align: 'center',
        dataIndex: 'index',
        width: 60,
        render: (text, record, index) => {
          let rooms = this.state.rooms.filter(r => r.id === record.room);
          return (<div>{index + 1}</div>);
        }
      },
      {
        title: '房间',
        align: 'center',
        dataIndex: 'room',
        width: 120,
        render: (text, record, index) => {
          let rooms = this.state.rooms.filter(r => r.id === record.room);
          return (<div>{rooms[0] ? rooms[0].name : ''}</div>);
        }
      },
      {
        title: '产品名称',
        align: 'center',
        dataIndex: 'name',
        width: 160,
      },
      {
        title: '类型',
        align: 'center',
        dataIndex: 'type',
        width: 120,
      },
      {
        title: '产品尺寸',
        align: 'center',
        dataIndex: 'padding5',
        width: 160,
        render: (text, record, index) => {
          return `长：${record.prop2}米`;
        }
      },
      {
        title: '加工方式',
        align: 'center',
        dataIndex: 'index',
        width: 160,
        render: (text, record, index) => {
          return `安装方式:${record.prop3}`;
        }
      },
      {
        title: '产品参数',
        align: 'center',
        dataIndex: 'index',
        width: 400,
        render: (text, record, index) => {
          let arr = [];
          let row = record.components.filter(r => ['配件'].includes(r.type));
          if (row.length > 0) {
            let complexarr = row.map(r => r.name).join('|');
            arr.push(`复杂加工:${complexarr}`);
          }
          arr.push(`层数:${record.prop1}，分段:${record.prop4}，装饰头:${record.prop5}`);
          return arr.join('；');
        }
      },
      {
        title: '备注信息',
        align: 'center',
        dataIndex: 'remark',
        width: 160,
      },
      {
        title: '单价',
        align: 'center',
        dataIndex: 'index',
        width: 100,
        render: (text, record, index) => {
          return (<div>{record.unit_app + '(' + record.unit_oms + ')'}</div>);
        }
      },
      {
        title: '小计',
        align: 'center',
        dataIndex: 'index',
        width: 100,
        render: (text, record, index) => {
          return (<div>{record.total_app + '(' + record.total_oms + ')'}</div>);
        }
      },
      {
        title: '操作',
        align: 'center',
        dataIndex: 'index',
        width: 100,
        render: (text, record, index) => {
          return (
            <div className='row'>
              <Button disabled={data.order_state !== '意向'} onClick={this.editProduct.bind(this, record)} type='primary' className='margin-r10'>编辑</Button>
              <Popconfirm
                title='您确定要删除这个产品吗?'
                onConfirm={this.deleteProduct.bind(this, record)}
                disabled={data.order_state !== '意向'}
                okText='确认'
                cancelText='取消'
              >
                <Button disabled={data.order_state !== '意向'} type='danger'>删除</Button>
              </Popconfirm>
            </div>
          );
        }
      },
    ];
    return (
      <Modal
        title={'订单信息'}
        width={1600}
        visible={visible}
        onCancel={this.onCancel}
        className={'padding0'}
        footer={
          data.order_state === '意向' ?
            <div className='w100 row-r'>
              <Button onClick={this.onCancel}>取消</Button>
              <Button onClick={this.onOk} type='primary'>保存</Button>
              <Button onClick={this.onPush} type='primary'>推送订单</Button>
            </div>
            : <div className='w100 row-r'>
              <Button onClick={this.onCancel}>关闭</Button>
            </div>
        }
      >
        <div className='col-t vertical-lt'>
          <div className='fz18'>客户信息</div>
          <div className='row-l w100 margin-b10'>
            <div className='w80 tc'>姓名：</div>
            <Input disabled={editDisable} value={data.name} onChange={this.changeInfo.bind(this, 'name')} className='w295' />
            <div className='w80 tc'>电话：</div>
            <Input disabled={editDisable} value={data.phone} onChange={this.changeInfo.bind(this, 'phone')} className='w295' />
            <div className='w80 tc'>地址：</div>
            <Regin disable={editDisable} value={[data.province, data.city, data.district]} onChange={this.changeInfo.bind(this, 'provinces')} />
            <Input disabled={editDisable} value={data.address} onChange={this.changeInfo.bind(this, 'address')} className='w295' />
          </div>
          <div className='row-l margin-b10'>
            <div className='w80 tc'>备注：</div>
            <Input disabled={editDisable} value={data.remark} onChange={this.changeInfo.bind(this, 'remark')} className='w500' />
          </div>
          <div className='row-l w100 margin-b10'>
            <div className='fz18 margin-r20'>订单明细</div>
            <div className='margin-r20 color3 bold'>
              {data.order_state}
            </div>
            <Button disabled={editDisable} onClick={this.showPrduct} type='primary'>新增</Button>
          </div>
          <Table
            columns={this.columns}
            dataSource={dataSource}
            size={'small'}
            rowKey={record => record.id}
            bordered
          />
        </div>
        <ProductModal
          ref={this.productModal}
          shop={shopInfo}
          mm={oldInfo}
          rooms={rooms}
          updateList={this.updateList}
        />
        <PostModal
          ref={this.postModal}
          updateList={this.pushComplete}
        />
      </Modal>
    );
  }
  showPrduct = () => {
    this.productModal.current.onShow()
  }
  editProduct = (e) => {
    this.productModal.current.onShow(e)
  }
  deleteProduct = async (e) => {
    const { oldInfo } = this.state;
    let res = await delProduct(oldInfo.mmId, oldInfo.ljId, e.id);
    if (res.status === 200) {
      this.updateList()
    }
  }
  changeInfo = (type, e) => {
    let data = _.cloneDeep(this.state.data);
    switch (type) {
      case 'name':
      case 'phone':
      case 'address':
      case 'remark':
        data[type] = e.target.value;
        break;
      case 'provinces':
        data.province = e[0];
        data.city = e[1];
        data.district = e[2];
        break;
    }
    this.setState({
      data
    });
  }
  getOrder = async (e) => {
    const { shopInfo } = this.state;
    if (e.id === 0) {
      let newData = {
        ...newRoom,
        shop: shopInfo.id,
        name: shopInfo.name + '客户',
        phone: shopInfo.phone,
        province: shopInfo.province,
        city: shopInfo.city,
        district: shopInfo.district,
        address: shopInfo.address,
      };
      let res = await postNewOrder(newData);
      if (res.status === 200) {
        let oldInfo = {
          ljId: res.data.lJorder.id,
          mmId: res.data.mMorder
        };
        let rsp = await getOrderInfo(res.data.mMorder, res.data.lJorder.id);
        if (rsp.status === 200) {
          let result = JSON.parse(rsp.data);
          this.setState({
            data: result.order,
            rooms: result.rooms,
            dataSource: [],
            oldInfo,
          });
        }
      }
    } else {
      let orderid = e.links[0].link;
      let res = await getOrderInfo(e.id, orderid);
      if (res.status === 200) {
        let result = JSON.parse(res.data);
        let oldInfo = {
          ljId: orderid,
          mmId: e.id
        };
        this.setState({
          data: result.order,
          rooms: result.rooms,
          oldInfo,
          dataSource: result.items
        });
      }
    }
  }
  updateList = async () => {
    const { oldInfo } = this.state;
    let res = await getOrderInfo(oldInfo.mmId, oldInfo.ljId);
    if (res.status === 200) {
      let result = JSON.parse(res.data);
      this.setState({
        rooms: result.rooms,
        dataSource: result.items
      });
    }
  }
  onOk = async () => {
    const { data, oldInfo } = this.state;
    let putData = {
      id: data.id,
      name: data.name,
      phone: data.phone,
      province: data.province,
      city: data.city,
      district: data.district,
      address: data.address,
      remark: data.remark,
    };
    let res = await putOrder(oldInfo.mmId, putData);
    if (res.status === 200) {
      this.props.updateList();
      this.onCancel();
    }
  }
  onPush = async () => {
    const { oldInfo, data } = this.state;
    // let res = await pushOrder(oldInfo.mmId, oldInfo.ljId)
    // if (res.status === 200) {
    //   message.success('推送成功');
    //   this.onCancel();
    // }
    this.postModal.current.onShow(oldInfo, data);
  }
  pushComplete = () => {
    this.onCancel();
    this.props.updateList();
  }
  onShow = (e) => {
    this.getOrder(e);
    this.setState({
      visible: true,
      orderInfo: e,
    });
  }
  onCancel = () => {
    this.setState({
      visible: false,
      data: {
        province: '',
        city: '',
        district: '',
      },
      oldInfo: {},
      dataSource: [],
    });
    this.props.updateList();
  }
}
export default OrderModal;