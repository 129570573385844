import React, { Fragment, Component } from 'react';
import DocumentTitle from 'react-document-title';
import LoginFrom from './LoginFrom';
import styles from './login.module.css';

const copyright = (
  <div className={styles.copyright}>
    <a className={`${styles['ahover']} row cursor-pointer color0 fz18`} href="http://beian.miit.gov.cn/">
      {/* href="http://beian.miit.gov.cn */}
      豫ICP备17008288号-3
    </a>
    {/* <a className="row cursor-pointer" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33060302000713">
      <img src={recordIcon} alt="暂无图片" />
      <p style={{ margin: 0 }}>浙公网安备 33060302000713号</p>
    </a>
    <a className="cursor-pointer" href="http://www.lijuyun.cn/">
      Copyright <CopyrightOutlined /> 2020 绍兴力聚信息科技有限公司
    </a> */}
  </div>
);
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  render() {
    return (
      <Fragment>
        <DocumentTitle title="登陆">
          <div className={`col ${styles.container}`}>
            {/* <div className='w100 tc color6 bold fz40 margin-b20'>墨墨管家商户订单系统</div> */}
            <LoginFrom />
            {copyright}
          </div>
        </DocumentTitle>
      </Fragment>
    );
  }

}
export default Index;
