import React, { Component } from 'react';
import { message, Table, Input, Button, DatePicker, Select, Checkbox, Radio } from 'antd';
import { putFeedbackList, getFeeds, } from '../../services/feed';
import PageTurning from '../../components/pageTurning';
import FeedbackModal from './feedbackModal';
import FeedModal from '../orderRenters/feedModal';
import { getDepartment_Data } from '../../config/storage';
import styles from './index.module.css';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/zh_CN';

const dateFormat = 'YYYY-MM-DD';
const today = moment().add(1, 'day').format(dateFormat);
const monthAgo = moment().subtract(1, 'month').format(dateFormat);

class Feedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      info: {
        info: '',
        start: monthAgo,
        end: today,
        reason: 0,
        myArea: true,
        skip: 0,
        take: 4,
      },
      feeds: [],
      dataSource: [],
    };
    this.selfId = getDepartment_Data().id;
    this.feedbackModal = React.createRef();
    this.feedModal = React.createRef();
    this.colums = [
      {
        title: '订单号',
        align: 'center',
        width: 90,
        dataIndex: 'order',
        render: (text, record, index) => {
          return (
            <div>
              <div>{record.service} - {record.category}</div>
              <div>{record.order}</div>
            </div>
          );
        }
      },
      {
        title: '客户信息',
        align: 'center',
        width: 200,
        dataIndex: 'worker_name',
        render: (text, record, index) => {
          return (
            <div className='col-t vertical-lt w100'>
              <div>客户：{record.name}</div>
              <div>电话：{record.phone}</div>
              <div className='tl w100'>地址：{record.province}{record.city}{record.district}{record.address}</div>
            </div>
          );
        }
      },
      {
        title: '申请信息',
        align: 'center',
        width: 120,
        dataIndex: 'worker_name',
        render: (text, record, index) => {
          return (
            <div className='col-t'>
              {/* <div>{record.sub_name}</div> */}
              <div>{moment(record.submit_time).format('YYYY-MM-DD HH:mm:ss')}</div>
            </div>
          );
        }
      },
      {
        title: '问题类型',
        align: 'center',
        width: 110,
        dataIndex: 'feed_str',
      },
      {
        title: '子问题',
        align: 'center',
        width: 140,
        dataIndex: 'detail_str',
      },
      {
        title: '问题描述',
        align: 'center',
        width: 180,
        dataIndex: 'remark',
        render: (text, record, index) => {
          return (
            <div className=''>
              {/* <div className='mh160 over mw250'> */}
              {record.remark}
            </div>
          );
        }
      },
      {
        title: '处理结果',
        align: 'center',
        width: 180,
        dataIndex: 'content',
        render: (text, record, index) => {
          return (
            <div>
              <div>{record.state}</div>
              <div>{record.content}</div>
            </div>
          );
        }
      },
      {
        title: '操作',
        align: 'center',
        width: 180,
        dataIndex: 'worker_name',
        render: (text, record, index) => {
          return (
            <div className='row'>
              {/* {
                <div className={styles['style-button2']} onClick={this.toEdit.bind(this, record)}>
                  编辑反馈
                </div>
              } */}
              <div className={styles['columns2-copy-button']} onClick={this.toDetail.bind(this, record)}>
                反馈详情
              </div>
            </div>
          );
        }
      },

    ];
  }
  componentDidMount() {
    this.getFeeds();
    this.getList();
  }
  render() {
    const { info, feeds, dataSource, } = this.state;
    return (
      <div>
        <div className='bar w100 margin-b10'>
          {
            false &&
            <div className='row-l margin-b10 w100'>
              <div className='w90'>问题类型：</div>
              <div className='w100 row-l'>
                <Radio.Group value={info.reason} onChange={this.changeInfo.bind(this, 'reason')}>
                  {
                    feeds.map((item, index) => {
                      return (
                        <Radio value={item.id} key={index}>{item.name}</Radio>
                      );
                    })
                  }
                </Radio.Group>
              </div>
            </div>
          }
          <div className='row-l'>
            <div>时间范围：</div>
            <DatePicker placeholder='开始时间' className='w198' value={moment(info.start, dateFormat)}
              onChange={this.changeInfo.bind(this, 'start')} locale={locale} clearIcon={false} />
            <div className='margin-l5 margin-r5'>至</div>
            <DatePicker placeholder='结束时间' className='w198' value={moment(info.end, dateFormat)}
              onChange={this.changeInfo.bind(this, 'end')} locale={locale} clearIcon={false} />
            <div className='margin-l20'>搜索：</div>
            <Input value={info.info} onChange={this.changeInfo.bind(this, 'info')} className='w200' />
            <Button onClick={this.getList} type='primary'>搜索</Button>
          </div>
        </div>
        <div className='bar'>
          <Table
            columns={this.colums}
            dataSource={dataSource}
            size={'small'}
            className='w100'
            rowKey={record => record.id}
            bordered
            pagination={false}
          />
          <PageTurning pageMaps={this.pageMaps} pageSize={4} />
        </div>

        <FeedbackModal
          ref={this.feedbackModal}
          update={this.getList}
        />
        <FeedModal
          ref={this.feedModal}
          update={this.getList}
        />
      </div>
    );
  }
  getFeeds = async () => {
    let res = await getFeeds();
    if (res.status === 200) {
      let feeds = [].concat([{ id: 0, name: '全部' }], res.data);
      this.setState({
        feeds,
      });
    }
  }
  changeInfo = (type, e) => {
    let info = { ...this.state.info };
    switch (type) {
      case 'reason':
        info[type] = e.target.value;
        break;
      case 'myArea':
        info[type] = e.target.checked;
        break;
      case 'info':
        info[type] = e.target.value;
        break;
      case 'start':
      case 'end':
        info[type] = moment(e).format(dateFormat);
        break;
    }
    this.setState({
      info,
    });
  }
  getList = async () => {
    const { info } = this.state;
    let res = await putFeedbackList(info);
    if (res.status === 200) {
      this.setState({
        dataSource: res.data
      });
    }
  }
  pageMaps = ({ skip, take }) => {
    let info = { ...this.state.info };
    info.skip = skip;
    this.setState({
      info,
    }, async () => this.getList())
  }

  toDetail = (e) => {
    this.feedbackModal.current.onShow(e);
  }
  toEdit = (e) => {
    this.feedModal.current.onShow(e, 'edit');
  }
}
export default Feedback;