import React, { Component } from 'react';
import { Table, message, Input, Button, Select } from 'antd';
import styles from './index.module.css';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { getAfterSaleList } from '../../services/afterSale';
import { getDepartment_Data, } from '../../config/storage';
import PageTurning from '../../components/pageTurning';

const stateType = ['全部', '待处理', '已处理'];

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stateTypes: '全部',
      inputSearch: '',
      data: [],
      skip: 0,
      take: 10,
      user: 0,
      users: [],
    };
    this.role = getDepartment_Data().role;
    this.columns = [
      // {
      //     title: '订单编号',
      //     align: 'center',
      //     width: 100,
      //     dataIndex: ''
      // },
      {
        title: '订单类型',
        align: 'center',
        width: 80,
        dataIndex: 'service_type'
      },
      {
        title: '客户信息',
        align: 'center',
        width: 160,
        render: (text, record, index) => {
          return (
            <div>{record.name + ' ' + record.phone}</div>
          )
        }
      },
      {
        title: '客户地址',
        align: 'center',
        width: 160,
        render: (text, record, index) => {
          return (
            <div>{record.province + record.city + record.district + record.address}</div>
          )
        }
      },
      {
        title: '售后类型',
        align: 'center',
        width: 80,
        dataIndex: 'order_type',
      },
      {
        title: '售后原因',
        align: 'center',
        width: 160,
        dataIndex: 'content',
      },
      {
        title: '金额',
        align: 'center',
        width: 80,
        dataIndex: 'price_app',
      },
      {
        title: '提交时间',
        align: 'center',
        width: 100,
        dataIndex: 'submit_time'
      },
      // {
      //     title: '处理时间',
      //     align: 'center',
      //     width: 100,
      // },
      // {
      //     title: '处理备注',
      //     align: 'center',
      //     width: 100,
      // },
      {
        title: '状态',
        align: 'center',
        width: 100,
        dataIndex: 'state'
      }
    ];
  }
  componentDidMount() {
    let shopUsers = getDepartment_Data().shopUsers;
    shopUsers = [{
      id: 0,
      name: '全部'
    },
    {
      id: getDepartment_Data().id,
      name: getDepartment_Data().userName,
    }].concat(shopUsers);
    // shopUsers.unshift(
    //   {
    //     id: 0,
    //     name: '全部'
    //   },
    //   {
    //     id: getDepartment_Data().id,
    //     name: getDepartment_Data().userName,
    //   }
    // )
    let user = shopUsers[0].id;
    if (this.role === '加盟商') {
      user = getDepartment_Data().id;
    }
    this.setState({
      user, users: shopUsers,
    }, () => this.getData());
  }
  render() {
    const { stateTypes, inputSearch, data, users, user } = this.state;
    return (
      <div>
        <div className='col-t vertical-lt bar'>
          <div className='row-l margin-b10'>
            <div>下单客服：</div>
            <Select
              disabled={this.role === '加盟商'}
              // showSearch
              className='w180'
              // placeholder="请输入/选择下单客服"
              // optionFilterProp="children"
              value={user}
              // filterOption={(input, option) =>
              //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              // }
              // filterSort={(optionA, optionB) =>
              //   optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
              // }
              onChange={this.onChangeSelect}
            >
              {
                users.map(item => {
                  return (
                    <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>
                  )
                })
              }
            </Select>
            <div>客户信息:</div>
            <Input value={inputSearch} onChange={this.onSearch} style={{ width: 295 }} className='margin-l10 margin-r20' placeholder='可输入客户姓名、手机、地址等' />
            <Button onClick={this.getData} type='primary'>搜索</Button>
          </div>
          <div className='row-l'>
            <div>处理状态:</div>
            <div className='row-l margin-l10'>
              {
                stateType.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={`${styles['state-style']} ${stateTypes === item && styles['state-color']}`}
                      onClick={this.setType.bind(this, item)}
                    >
                      {item}
                    </div>
                  )
                })
              }
            </div>
          </div>

        </div>
        <div className='w100 back-color0 padding20 margin-t10'>
          <Table
            columns={this.columns}
            dataSource={data}
            size={'small'}
            rowKey={record => record.id}
            bordered
            pagination={false}
          />
          <PageTurning pageMaps={this.pageMaps} />
        </div>
      </div>
    );
  }
  setType = (e) => {
    this.setState({
      stateTypes: e
    }, () => this.getData());
  }
  onChangeSelect = (e) => {
    this.setState({
      user: e
    })
  }
  getData = async () => {
    const { inputSearch, user, stateTypes, skip, take } = this.state;
    let res = await getAfterSaleList(inputSearch, stateTypes, user, skip, take);
    if (res.status !== 200) {
      message.error(res.data);
      return;
    }
    this.setState({
      data: res.data
    });
  }
  onSearch = (e) => {
    this.setState({
      inputSearch: e.target.value,
    })
  }

  //换页
  pageMaps = ({ skip, take }) => {
    this.setState({
      skip: skip,
    }, () => this.getData());
  }
}

export default Index;