import request from '../utils/request';

export async function analysisAddress(address) {
  return await request(`open/address?address=${address}`, 'get');
}
export async function getCategory(category, shop) {
  return await request(`data?category=${category}&shop=${shop}`, 'get');
}
export async function getPrice(itemid, workid, shopid) {
  return await request(`data/price?itemid=${itemid}&workid=${workid}&shopid=${shopid}`, 'get');
}
export async function postOrder(methed, orderid, preoder, couponid, data) {
  return await request(`shopBiz?methed=${methed}&orderid=${orderid}&preoder=${preoder}&couponid=${couponid}`, 'post', data);
}

export async function getCoupon(category, service) {
  return await request(`shopBiz/coupon?category=${category}&service=${service}`, 'get');
}

export async function getOrder(orderid) {
  return await request(`platbiz?orderid=${orderid}`, 'get')
}

//编辑备注
export async function addRemark(data) {
  return await request(`shopBiz/addremark`, 'post', data)
}
export async function editRemark(id, remark) {
  return await request(`shopBiz/mdyremark?id=${id}&remark=${remark}`, 'put')
}
export async function delRemark(id) {
  return await request(`shopBiz/delremark?id=${id}`, 'delete')
}
export async function sortRemark(oldid, newid) {
  return await request(`shopBiz/sortremark?oldid=${oldid}&newid=${newid}`, 'put')
}

//视频参数
export async function getVideoInfo(title, name) {
  return await request(`data/uploadvod?title=${title}&name=${name}`, 'get')
}
export async function getVideoPlay(videoId) {
  return await request(`data/playvod?videoId=${videoId}`, 'get')
}
export async function getVideoSub(videoId) {
  return await request(`data/subvod?videoId=${videoId}`, 'get')
}