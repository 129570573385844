import React, { Component } from 'react';
import styles from './index.module.css';
import { Modal, Input, Table, Button, InputNumber, Radio, Image, Carousel } from 'antd';
import { getProductList, getProductInfo, putCollect } from '../../services/product';
import { getDepartment_Data } from '../../config/storage';
import CollectDrawer from './collectDrawer';
import PageTurning from '../../components/pageTurning';
import noPic from '../../resource/img/404pic.jpg';
import _ from 'lodash';

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      type: '杆轨',
      skip: 0,
      take: 500,
      dataSource: [],
      total: 0,
      preview: false,
      currentProduct: {
        name: '',
        category: '',
        layer: [],
        fix_type: [],
        pic_c: [],
        pic_d: [],
      },
    }
    this.shopInfo = getDepartment_Data();
    this.pageTurning = React.createRef();
    this.collectDrawer = React.createRef();
    this.baseUrl = 'https://ljkj-erp.oss-cn-hangzhou.aliyuncs.com/resource/';
  }
  componentDidMount() {
    this.getList();

    // this.findscroller(document.body)
  }

  render() {
    const { search, dataSource, total, take, preview, currentProduct } = this.state;
    return (
      <div>
        {
          preview ?
            <div>
              <div className='w100 row-sb borb padding-b10 bar'>
                <div className='fz18 none-select margin-l20'>商品信息</div>
                <div onClick={this.returnList} className='fz18 cursor-pointer color3 padding-lr20'>返回列表</div>
              </div>
              <div className='row-l vertical-lt bar'>
                <Carousel className='w500' dotPosition="bottom" autoplay>
                  {
                    currentProduct.pic_c.length > 0 ?
                      currentProduct.pic_c.map((item, index) => {
                        return (
                          <div key={index}>
                            <Image src={this.baseUrl + item} className='w530' preview={false} />
                          </div>
                        );
                      })
                      : <div>
                        <Image src={currentProduct.pic_a ? this.baseUrl + currentProduct.pic_a : currentProduct.pic ? this.baseUrl + currentProduct.pic : ''}
                          className='w530' preview={false} />
                      </div>

                  }
                </Carousel>
                <div className='col-sa vertical-lt margin-l20 padding20 w900 h500'>
                  <div className='fz22 bold'>{currentProduct.name}</div>
                  <div className='margin-t40 fz16'>{currentProduct.info}</div>
                  <div className='row-l margin-t40 fz16'>
                    <div className='w90'>类型：</div>
                    <div>{currentProduct.category}</div>
                  </div>
                  <div className='row-l margin-t40 fz16'>
                    <div className='w90'>层数支持：</div>
                    <div>{currentProduct.layer.join('|')}</div>
                  </div>
                  <div className='row-l margin-t40 fz16'>
                    <div className='w90'>安装方式：</div>
                    <div>{currentProduct.fix_type.join('|')}</div>
                  </div>
                  <div className='row-l margin-t40 fz16'>
                    <div className='w90'>安装单价：</div>
                    <div>￥{currentProduct.price_oms[0]}{currentProduct.unit ? '/' + currentProduct.unit : ''}</div>
                  </div>

                </div>
              </div>
              <div className='w100 row-sb borb padding-b10 bar margin-t10'>
                <div className='fz18 none-select margin-l20'>商品详情</div>
              </div>
              <div className='col-t bar w100'>
                {
                  currentProduct.pic_d.map((item, index) => {
                    return (
                      <Image src={this.baseUrl + item.p} className='w750' key={index} preview={false} />
                    );
                  })
                }
              </div>
            </div>
            :
            <div>
              <div className='bar row-sb w100 margin-b10'>
                <div className='row-l'>
                  <div className='fz18'>产品搜索：</div>
                  <Input value={search} className='w200 ' onChange={this.changeSearch} />
                  <Button onClick={this.toSearch} type='primary'>搜索</Button>
                </div>
                <div onClick={this.showCollect} className='fz22 cursor-pointer back-color6 w150 h40 tc border-r5 color0'>收藏夹</div>
              </div>
              <div className='bar row-w'>
                {
                  dataSource.map((item, index) => {
                    return (
                      <div className={`${styles['product']} col-t margin-b10`} key={index}>
                        <Image src={item.pic ? this.baseUrl + item.pic : noPic} className='w270 cursor-pointer' preview={false} onClick={this.toDetail.bind(this, item)} />
                        <div onClick={this.toDetail.bind(this, item)} className='tl w100 margin-l20 fz18 bold cursor-pointer'>{item.price_oms.includes('[') ? '￥' + JSON.parse(item.price_oms)[0] : ''}</div>
                        <div onClick={this.toDetail.bind(this, item)} className='tl w100 margin-l20 fz15 bold color10 cursor-pointer'>{item.name}</div>
                        <div onClick={this.toDetail.bind(this, item)} className='tl w100 padding-lr10 fz13 color10 twoline h40 cursor-pointer'>{item.info}</div>
                        <div className={`${styles['like']} w100 row-r cursor-pointer`}>
                          {
                            item.collect ?
                              <div className='row margin-r10 color8' onClick={this.toCollect.bind(this, item, false)}>
                                <div className='margin-r5 fz24'>×</div>
                                {/* <div className='margin-r5'>❤</div> */}
                                <div className='fz15'>取消选品</div>
                              </div>
                              :
                              <div className='row margin-r10 color2' onClick={this.toCollect.bind(this, item, true)}>
                                <div className='margin-r5 fz24'>+</div>
                                <div className='fz15'>选品</div>
                              </div>
                          }
                        </div>
                      </div>
                    );
                  })
                }
              </div>
              <div className='bar'>
                <PageTurning ref={this.pageTurning} pageMaps={this.pageMaps} total={total} pageSize={take} />
              </div>
            </div>
        }
        <CollectDrawer ref={this.collectDrawer} update={this.getList} />
      </div>
    );
  }
  findscroller = (element) => {
    element.onscroll = function () {
    }
    Array.from(element.children).forEach(this.findscroller)
  }
  changeSearch = (e) => {
    this.setState({
      search: e.target.value,
    });
  }
  toSearch = () => {
    // this.pageTurning.current.returnFirst();
    this.setState({
      skip: 0
    }, () => this.getList());
  }
  getList = async () => {
    const { search, type, skip, take } = this.state;
    let res = await getProductList(search, type, skip, take, null);
    if (res.status === 200) {
      let product = JSON.parse(res.data);
      this.setState({
        dataSource: product.result,
        total: product.total,
      })
    }
  }
  pageMaps = ({ skip, take }) => {
    this.setState({
      skip: skip,
    }, async () => this.getList())
  }
  toDetail = async (e) => {
    let res = await getProductInfo('杆轨', e.id);
    if (res.status === 200) {
      let currentProduct = JSON.parse(res.data);
      this.setState({
        currentProduct,
        preview: true,
      })
    }
    var element = document.getElementById('contents');
    element.scrollTop = 0;
  }
  returnList = () => {
    this.setState({
      currentProduct: {},
      preview: false,
    })
  }
  toCollect = async (e, enable) => {
    let data = {
      shop: this.shopInfo.lj_shop,
      product: e.id,
      name: e.name,
      enable: enable ? '是' : '否',
    };
    let res = await putCollect(data);
    if (res.status === 200) {
      let dataSource = _.cloneDeep(this.state.dataSource);
      dataSource.map(item => {
        if (item.id === e.id) {
          item.collect = enable;
        }
      })
      this.setState({
        dataSource
      })
    }
  }
  showCollect = () => {
    this.collectDrawer.current.onShow();
  }
}
export default Index;