import request from '../utils/request';

export async function postNewOrder(data) {
  return await request('lj/neworder', 'post', data);
}
export async function putOrder(id, data) {
  return await request(`lj/mdyorder?id=${id}`, 'put', data);
}
export async function delOrder(id, orderid) {
  return await request(`lj/delorder?id=${id}&orderid=${orderid}`, 'delete');
}
export async function pushOrder(id, orderid, data) {
  return await request(`lj/pushorder?id=${id}&orderid=${orderid}`, 'put', data);
}
export async function getPost(id, orderid, province) {
  return await request(`lj/getpost?id=${id}&orderid=${orderid}&province=${province}`, 'get');
}


export async function getOrderInfo(id, orderid) {
  return await request(`lj/getorder?id=${id}&orderid=${orderid}`, 'get');
}
export async function getProductList(search, type, skip, take, collect) {
  return await request(`lj/getlist?search=${search}&type=${type}&collect=${collect}&skip=${skip}&take=${take}`, 'get');
}

export async function getProductInfo(type, id) {
  return await request(`lj/getitemdata?type=${type}&id=${id}`, 'get');
}
export async function postProduct(id, data) {
  return await request(`lj/additem?id=${id}`, 'post', data);
}
export async function putProduct(id, data) {
  return await request(`lj/mdyitem?id=${id}`, 'put', data);
}
export async function delProduct(id, orderid, itemid) {
  return await request(`lj/delitem?id=${id}&orderid=${orderid}&itemid=${itemid}`, 'delete');
}

//收藏
export async function putCollect(data) {
  return await request('lj/putcollect', 'put', data);
}
//余额
export async function getAccount(shop) {
  return await request(`lj/getbalance?shop=${shop}`, 'get');
}
