import React, { Component } from 'react';
import { Modal, Input, Table, Button, InputNumber, Radio, Image, Checkbox } from 'antd';
import styles from './index.module.css';
import PageTurning from '../../components/pageTurning';
import { getProductList, getProductInfo, postProduct, putProduct, } from '../../services/product';

const defaultRooms = [
  { id: -1, name: '客厅' },
  { id: -2, name: '餐厅' },
  { id: -3, name: '书房' },
  { id: -4, name: '儿童房' },
  { id: -5, name: '主卧' },
  { id: -6, name: '客卧' },
  { id: -7, name: '阳台' },
  { id: -8, name: '自定义' },
];
class ProductModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      search: '',
      type: '杆轨',
      skip: 0,
      take: 10,
      isCollect: true,
      dataSource: [],
      total: [],
      productInfo: {},
      data: {},
      roomList: [...defaultRooms],
      diyRoom: '',

    }
    this.baseUrl = 'https://ljkj-erp.oss-cn-hangzhou.aliyuncs.com/resource/';
    this.pageTurning = React.createRef();
    this.columns = [
      {
        title: '品名',
        dataIndex: 'name',
        align: 'left',
        width: 200,
        render: (text, record, index) => {
          return (
            <div className='row-l'>
              <div className='fz18 margin-r5'>{record.collect ? '★' : ''}</div>
              <div>{record.name}</div>
            </div>
          );
        }
      },
      {
        title: '相关信息',
        dataIndex: 'info',
        align: 'left',
        width: 150,
      },
      {
        title: '操作',
        dataIndex: 'operate',
        align: 'center',
        width: 80,
        render: (text, record, index) => (
          <Button type='primary' onClick={this.selectProduct.bind(this, record)} className='btn-style'>
            选中
          </Button>
        )

      },
    ];
  }
  render() {
    const { visible, search, dataSource, total, take, productInfo, data, roomList, diyRoom, isCollect, } = this.state;
    this.partColumns = [
      {
        title: '选择',
        dataIndex: 'select',
        align: 'center',
        width: 60,
        className: 'padding4',
        render: (text, record, index) => {
          let checked = data.parts && data.parts.includes(record.id) ? true : false;
          return (<Checkbox disabled={!record.cancelable} checked={checked}
            onChange={this.changeParts.bind(this, record.id)} />);
        }
      },
      {
        title: '配件名称',
        dataIndex: 'name',
        align: 'center',
        width: 100,
        className: 'padding4',
      },
      {
        title: '备注',
        dataIndex: 'info',
        align: 'center',
        width: 120,
        className: 'padding4',
      }
    ];
    return (
      <Modal
        title={'产品信息'}
        width={1450}
        visible={visible}
        onCancel={this.onCancel}
        className={'padding0'}
        footer={
          <div className='w100 row-r'>
            <Button onClick={this.onCancel}>取消</Button>
            <Button onClick={this.onOk} type='primary'>保存</Button>
          </div>
        }
      >
        <div className='row-l vertical-lt w100'>
          <div className='w550 margin-r40'>
            <div className='row-l margin-b10'>
              <div className='fz16 w80'>搜索：</div>
              <Input className='w300' value={search} onChange={this.changeSearch} />
              <Button onClick={this.toSearch} type='primary' >搜索</Button>
              <div className='margin-l20 margin-r5 fz16'>已收藏 </div>
              <Checkbox checked={isCollect === true}
                onChange={this.changeCollect} />
            </div>
            <Table
              columns={this.columns}
              dataSource={dataSource}
              rowKey='id'
              size='small'
              style={{ width: '100%' }}
              pagination={false}
            />
            <PageTurning ref={this.pageTurning} pageMaps={this.pageMaps} total={total} pageSize={take} />
          </div>
          {
            data.name &&
            <div className='w600'>
              <div className='fz17 margin-b10'>产品名称：{data.name}</div>
              <div className='row-l vertical-lt w100 margin-b10'>
                <div className='fz15 w80 tc'>房间：</div>
                <div className='row-w w650'>
                  {
                    roomList.map((item, index) => (
                      <div className={`bar-item margin-b10 back-color2  fz15 ${item.id > 0 ? 'color1' : 'color5'} 
                    ${data.room === item.id ? 'back-color6 color0' : ''}`} key={index}
                        onClick={this.changeData.bind(this, 'room', item.id)}
                      >
                        {item.name}
                      </div>
                    ))
                  }
                  {
                    data.room === -8 &&
                    <Input value={diyRoom} onChange={this.changeDiyRoom} className='w295 margin-l10 border3' addonBefore='自定义名称' />
                  }
                </div>
              </div>
              <div className='row-l vertical-lt margin-b10'>
                <div className='w80 tc fz15'>长度：</div>
                <InputNumber value={data.width} precision={2} onChange={this.changeData.bind(this, 'width')} min={0} addonAfter='米' className='w200' />
              </div>
              <div className='row-l vertical-lt margin-b10'>
                <div className='w80 tc fz15'>层数：</div>
                <Radio.Group value={data.layer} onChange={this.changeData.bind(this, 'layer')}>
                  {
                    productInfo.layer &&
                    productInfo.layer.map((item, index) => (
                      <Radio value={item} key={index}>{item}</Radio>
                    ))
                  }
                </Radio.Group>
              </div>
              <div className='row-l vertical-lt margin-b10'>
                <div className='w80 tc fz15'>安装方式：</div>
                <Radio.Group value={data.fix_type} onChange={this.changeData.bind(this, 'fix_type')}>
                  {
                    productInfo.fix_type &&
                    productInfo.fix_type.map((item, index) => (
                      <Radio value={item} key={index}>{item}</Radio>
                    ))
                  }
                </Radio.Group>
              </div>
              <div className='row-l vertical-lt margin-b10'>
                <div className='w80 tc fz15'>分段：</div>
                <Radio.Group value={data.piece} onChange={this.changeData.bind(this, 'piece')}>
                  {
                    productInfo.pieces &&
                    productInfo.pieces.map((item, index) => (
                      <Radio value={item} key={index}>{item}</Radio>
                    ))
                  }
                </Radio.Group>
              </div>
              <div className='row-l vertical-lt margin-b10'>
                <div className='w80 tc fz15'>装饰头：</div>
                <Radio.Group value={data.head} onChange={this.changeData.bind(this, 'head')}>
                  {
                    productInfo.heads &&
                    productInfo.heads.map((item, index) => (
                      <Radio value={item} key={index}>{item}</Radio>
                    ))
                  }
                </Radio.Group>
              </div>
              <div className='row-l vertical-lt margin-b10'>
                <div className='w80 tc fz15'>备注：</div>
                <Input value={data.remark} onChange={this.changeData.bind(this, 'remark')} className='w295' />
              </div>
              <div className='margin-b10'>
                <div className='fz16'>配件</div>
                <Table
                  columns={this.partColumns}
                  dataSource={productInfo.partList}
                  rowKey='id'
                  size='small'
                  pagination={false}
                />
              </div>
            </div>
          }
          <div className='col-t margin-l20'>
            <Image src={productInfo.pic ? this.baseUrl + productInfo.pic : ''} className='w200' />
          </div>
        </div>
      </Modal>
    );
  }
  changeCollect = (e) => {
    this.pageTurning.current.returnFirst();
    this.setState({
      isCollect: e.target.checked,
      skip: 0,
    }, () => this.getList());
  }
  changeSearch = (e) => {
    this.setState({
      search: e.target.value
    })
  }
  toSearch = () => {
    this.pageTurning.current.returnFirst();
    this.setState({
      skip: 0,
    }, () => this.getList());
  }
  getList = async () => {
    const { search, type, skip, take, isCollect } = this.state;
    let res = await getProductList(search, type, skip, take, isCollect);
    if (res.status === 200) {
      let product = JSON.parse(res.data);
      this.setState({
        dataSource: product.result,
        total: product.total,
      })
    }
  }
  selectProduct = async (e) => {
    const { roomList } = this.state;
    let res = await getProductInfo('杆轨', e.id);
    if (res.status === 200) {
      let productInfo = JSON.parse(res.data);
      productInfo.pieces = productInfo.multi_piece === '否' ? ['一段', '两段'] : ['一段', '两段', '三段', '四段', '五段'];
      productInfo.heads = productInfo.type === '罗马杆' ? ['无', '一侧', '两侧'] : ['两侧'];
      let data = {
        name: productInfo.name,
        itemId: 0,
        width: 1,
        layer: productInfo.layer[0],
        fix_type: productInfo.fix_type[0],
        piece: '一段',
        remark: '',
        head: '两侧',
        pics: [],
        roomName: roomList[0].name,
        room: roomList[0].id,
      };
      productInfo.partList = productInfo.complexes.filter(r => r.type === '配件');
      if (productInfo.complexes.filter(c => c.selected && c.type === '配件').length > 0) {
        data.parts = productInfo.complexes.filter(c => c.selected && c.type === '配件').map(m => m.id);
      } else {
        data.parts = [];
      }
      this.setState({
        productInfo, data,
      })
    }
  }
  changeData = (type, e) => {
    const { roomList } = this.state;
    let data = _.cloneDeep(this.state.data);
    switch (type) {
      case 'width':
        data[type] = e; break;
      case 'room':
        data[type] = e;
        data.roomName = roomList.filter(r => r.id === e)[0].name;
        break;
      case 'remark':
      case 'layer':
      case 'fix_type':
      case 'piece':
      case 'head':
        // eslint-disable-next-line no-duplicate-case
        data[type] = e.target.value; break;
    }
    this.setState({
      data,
    });
  }
  //选择配件
  changeParts = (id, e) => {
    let data = _.cloneDeep(this.state.data);
    if (e.target.checked) {
      data.parts.push(id);
    } else {
      data.parts = data.parts.filter(r => r !== id);
    }
    this.setState({
      data,
    });
  }
  changeDiyRoom = (e) => {
    this.setState({
      diyRoom: e.target.value
    });
  }
  onOk = async () => {
    const { data, productInfo, diyRoom } = this.state;
    const { mm, shop, updateList } = this.props;
    let roomName = data.roomName === '自定义' ? diyRoom ? diyRoom : '自定义' : data.roomName
    let putdata = {
      id: mm.ljId,
      shop: shop.id,
      itemId: data.itemId,
      room: {
        id: data.room < 0 ? 0 : data.room,
        order: mm.ljId,
        sort: 0,
        name: roomName,
        width: 0,
        height: 0,
        pics: JSON.stringify([]),
        remark: '',
      },
      item: {
        id: data.itemId,
        model: '',
        remark: data.remark,
        pics: JSON.stringify(data.pics),
        supplier: productInfo.enterprise,
        components: [],
        owner: 'order',
        item: 0,
        materials: [],
        bills: [],
        type: productInfo.category,
        sort: 0,
        room: data.room < 0 ? 0 : data.room,
        name: data.name,
        unit_app: productInfo.price_app[0],
        unit_oms: productInfo.price_oms[0],
        total_app: 0,
        total_oms: 0,
        add_oms: 0,
        length: 0,
        weight: 0,
        prop1: data.layer,
        prop2: data.width ? data.width.toString() : '0',
        prop3: data.fix_type,
        prop4: data.piece,
        prop5: data.head,
        prop6: '1',
        factory: JSON.stringify(productInfo.suppliers),
        series: '',
      },
    };
    let fabric = [{
      id: 0,
      item: 0,
      sort: 0,
      type: productInfo.type,
      component: productInfo.id,
      model: productInfo.model ? productInfo.model : 0,
      sn: productInfo.sn,
      name: productInfo.name,
      pic: productInfo.pic,
      stock: productInfo.stock,
      link: productInfo.product
    }];
    let parts = productInfo.partList.filter(r => data.parts.includes(r.id)).map(r => {
      return {
        component: r.id,
        id: 0,
        item: 0,
        name: r.name,
        sn: '',
        pic: r.pic,
        stock: r.stock,
        type: '配件',
        model: r.model ? r.model : 0,
        link: r.complex,
      };
    });
    putdata.item.components = fabric.concat(parts);
    let res;
    if (putdata.itemId > 0) {
      res = await putProduct(mm.mmId, putdata);
    } else {
      res = await postProduct(mm.mmId, putdata);
    }
    if (res.status === 200 || res.status === 204) {
      updateList();
      this.onCancel();
    }
  }
  onShow = (e) => {
    let { rooms } = this.props;
    this.getRoom(rooms);
    this.getList()
    if (e) {
      this.getProduct(e, rooms);
    }
    this.setState({
      visible: true,
    });
  }
  getRoom = (rooms) => {
    const roomsNameArr = rooms.map(r => r.name);
    const addRoomArr = defaultRooms.filter(r => !roomsNameArr.includes(r.name));
    const roomsArr = rooms.map(r => {
      return {
        id: r.id,
        name: r.name
      };
    });
    let roomList = roomsArr.concat(addRoomArr);
    this.setState({
      roomList
    });
  }
  getProduct = async (e, rooms) => {
    let currentRoom = rooms.filter(r => r.id === e.room);
    let data = {
      name: e.name,
      itemId: e.id,
      width: e.prop2,
      layer: e.prop1,
      fix_type: e.prop3,
      piece: e.prop4,
      remark: e.remark,
      head: e.prop5,
      pics: e.pics ? JSON.parse(e.pics) : [],
      roomName: currentRoom[0] ? currentRoom[0].name : '',
      room: e.room,
      oldItem: e,
    };
    let component = e.components.filter(r => r.type === '轨道' || r.type === '罗马杆')[0];
    data.parts = e.components.filter(r => r.type === '配件').map(r => r.component);
    let res = await getProductInfo('杆轨', component.component);
    let productInfo = {}
    if (res.status === 200) {
      productInfo = JSON.parse(res.data);
      productInfo.pieces = productInfo.multi_piece === '否' ? ['一段', '两段'] : ['一段', '两段', '三段', '四段', '五段'];
      productInfo.heads = productInfo.type === '罗马杆' ? ['无', '一侧', '两侧'] : ['两侧'];
      productInfo.partList = productInfo.complexes.filter(r => r.type === '配件');
      // if (productInfo.complexes.filter(c => c.selected && c.type === '配件').length > 0) {
      //   data.parts = data.parts.concat(productInfo.complexes.filter(c => c.selected && c.type === '配件').map(m => m.id));
      // } else {
      //   data.parts = data.parts;
      // }
    }
    this.setState({
      data, productInfo,

    });
  }
  onCancel = () => {
    this.setState({
      visible: false,
      skip: 0,
      productInfo: {},
      data: {},
    });
  }
  //换页
  pageMaps = ({ skip, take }) => {
    this.setState({
      skip: skip,
    }, async () => this.getList())
  }
}
export default ProductModal;