import React, { Component } from 'react';
import { message, Table, Input, Button, Select } from 'antd';
import styles from './index.module.css';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { getMeasureDataList } from '../../services/measureData';
import { getDepartment_Data, setBalance } from '../../config/storage';
import PageTurning from '../../components/pageTurning';
import { getExcel } from '../../services/orderRenter';

const downloadType = ['未下载', '已下载', '全部'];

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      downloadTypes: '未下载',
      state: '全部',
      stateList: ['全部', '已完成', '未完成'],
      inputSearch: '',
      data: [],
      skip: 0,
      take: 10,
      user: 0,
      users: [],
    };
    this.role = getDepartment_Data().role;
    this.columns = [
      {
        title: '订单类型',
        align: 'center',
        dataIndex: 'category',
        width: 80,
      },
      {
        title: '客户信息',
        align: 'center',
        // dataIndex: 'information',
        width: 120,
        render: (text, record, index) => {
          return (
            <div>{record.name + ' ' + record.phone}</div>
          )
        }
      },
      {
        title: '客户地址',
        align: 'center',
        // dataIndex: 'address',
        width: 160,
        render: (text, record, index) => {
          return (
            <div>{record.province + record.city + record.district + record.address}</div>
          )
        }
      },
      {
        title: '更新时间',
        align: 'center',
        dataIndex: 'update_time',
        width: 100,
      },
      // {
      //     title: '下载状态',
      //     align: 'center',
      //     dataIndex: 'state',
      //     width: 100,
      // },
      {
        title: '操作',
        align: 'center',
        width: 100,
        render: (text, record, index) => {
          return (
            <div className='row'>
              <div className={styles['style-button3']} onClick={this.onExcelData.bind(this, record.id, '测量数据详情')}>测量数据详情</div>
              <div className={styles['style-button3']} onClick={this.onExcelData.bind(this, record.id, '测量数据下载')}>测量数据下载</div>
            </div>
          )
        }
      }
    ]
  }
  componentDidMount() {
    let shopUsers = getDepartment_Data().shopUsers;
    shopUsers.unshift(
      {
        id: 0,
        name: '全部'
      },
      {
        id: getDepartment_Data().id,
        name: getDepartment_Data().userName,
      }
    )
    let user = shopUsers[0].id;
    if (this.role === '加盟商') {
      user = getDepartment_Data().id;
    }
    this.setState({
      user, users: shopUsers,
    }, () => this.getData());
  }
  render() {
    const { inputSearch, data, users, user, stateList, state } = this.state;
    return (
      <div>
        <div className='row-l bar'>
          <div>下单客服：</div>
          <Select
            disabled={this.role === '加盟商'}
            // showSearch
            className='w180'
            // placeholder="请输入/选择下单客服"
            // optionFilterProp="children"
            value={user}
            // filterOption={(input, option) =>
            //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            // }
            // filterSort={(optionA, optionB) =>
            //   optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
            // }
            onChange={this.onChangeSelect}
          >
            {
              users.map(item => {
                return (
                  <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>
                )
              })
            }
          </Select>
          <div className='margin-l10'>状态：</div>
          <Select value={state} onChange={this.changeState} className='w180'>
            {
              stateList.map((item, index) => {
                return (
                  <Select.Option value={item} key={index}>{item}</Select.Option>
                );
              })
            }
          </Select>
          <div className='margin-l10'>客户信息：</div>
          <Input value={inputSearch} onChange={this.changeSearch} style={{ width: 295 }} className='margin-l10 margin-r20' placeholder='可输入客户姓名、手机、地址等' name='search' />
          <Button type='primary' className='margin-l20 margin-r10' onClick={this.getData}>搜索</Button>
        </div>
        <div className='w100 back-color0 margin-t10'>
          <Table
            columns={this.columns}
            dataSource={data}
            size={'small'}
            rowKey={record => record.id}
            bordered
            pagination={false}
          />

          <PageTurning pageMaps={this.pageMaps} />
        </div>
      </div>
    );
  }
  onChangeSelect = (e) => {
    this.setState({
      user: e
    })
  }
  changeState = (e) => {
    this.setState({
      state: e
    })
  }
  getData = async () => {
    const { inputSearch, user, state, skip, take } = this.state;
    let res = await getMeasureDataList(inputSearch, user, state, skip, take);
    if (res.status !== 200) {
      message.error(res.data);
      return;
    }
    this.setState({
      data: res.data
    })
  }
  changeSearch = (e) => {
    this.setState({
      inputSearch: e.target.value
    });
  }
  onExcelData = async (id, type) => {
    let res = await getExcel(id);
    if (res.status !== 200) {
      message.error(res.data);
      return;
    }
    if (type === '测量数据下载') {
      window.location.href = res.data
    } else {
      let newFileUrl = encodeURIComponent(res.data)
      window.open(`https://view.officeapps.live.com/op/view.aspx?src=${newFileUrl}`, '_blank')
    }
  }
  //换页
  pageMaps = ({ skip, take }) => {
    this.setState({
      skip,
    }, () => this.getData())
  }
}

export default Index;