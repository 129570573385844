import React, { useEffect, useState, useRef, Component } from 'react';
import styles from './index.module.css'
import finance from '../../resource/png/finance.png';
import discount from '../../resource/png/discount.png';
import voucher from '../../resource/png/voucher.png';
import { getFinancebase, getFinanceList, getCharge } from '../../services/finance';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Input, DatePicker, Button, Table, Radio, message, Modal, InputNumber, Select } from 'antd';
import { exportExcel } from '../../utils/exportExcel.js';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/zh_CN';
import PageTurning from '../../components/pageTurning';
import PayModal from '../../routes/finance/pay';

const dateFormat = 'YYYY-MM-DD';
// let endDate = new Date().toLocaleDateString().split('/').join('-');
// let startDate = new Date(new Date() - 30 * 24 * 60 * 60 * 1000).toLocaleDateString().split('/').join('-');
const startDate = moment(new Date()).subtract(3, 'months').format(dateFormat);
const endDate = moment(new Date()).add(1, 'day').format(dateFormat);

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      method: '下单',
      types: '充值',
      streamType: '全部',
      data: [],
      balance: 0,//余额
      coupons: {},//优惠券
      couponsNum: 0,//优惠券数量
      customerInput: '',//客户信息
      startDates: startDate,
      endDates: endDate,
      skip: 0,
      take: 10,
      couponVisible: false,
    };
    this.childRef = React.createRef();
    this.columns = [
      {
        title: '流水类型',
        align: 'center',
        dataIndex: 'type',
        key: 'type',
        width: 100,
      },
      {
        title: '订单编号',
        align: 'center',
        dataIndex: 'order',
        width: 100,
      },
      {
        title: '订单类型',
        align: 'center',
        dataIndex: 'service_type',
        key: 'service_type',
        width: 100,
      },
      {
        title: '金额',
        align: 'center',
        dataIndex: 'price',
        key: 'price',
        width: 100,
      },
      {
        title: '账户余额',
        align: 'center',
        dataIndex: 'balance',
        key: 'balance',
        width: 100,
      },
      {
        title: '备注信息',
        align: 'center',
        dataIndex: 'content',
        key: 'content',
        width: 160,
      },
      {
        title: '结算时间',
        align: 'center',
        dataIndex: 'check_time',
        key: 'check_time',
        width: 120,
        render: (text, record, index) => {
          return (<div>{record.check_time === '0001-01-01T00:00:00' ? '' : record.check_time}</div>);
        }
      },
      {
        title: '下单时间',
        align: 'center',
        dataIndex: 'add_time',
        key: 'add_time',
        width: 120,
      },
      {
        title: '客户信息',
        align: 'center',
        dataIndex: 'order_info',
        key: 'order_info',
        width: 200,
      },
      // {
      //     title: '备注信息',
      //     align: 'center',
      //     width: 100,
      // },
    ];
    this.couponColumns = [
      {
        title: '优惠券编号',
        align: 'center',
        dataIndex: 'id',
        width: 100,
      },
      {
        title: '类型',
        align: 'center',
        dataIndex: 'service',
        width: 100,
      },
      {
        title: '名称',
        align: 'center',
        dataIndex: 'name',
        width: 100,
      },
      {
        title: '优惠金额',
        align: 'center',
        dataIndex: 'price',
        width: 100,
      },
      {
        title: '失效日期',
        align: 'center',
        dataIndex: 'due_time',
        width: 160,
        render: (text, record, index) => {
          return (moment(record.due_time).format('YYYY/MM/DD-HH:mm'));
        }
      },
    ];
  }
  componentDidMount() {
    this.getData()
    this.financebase()
  }
  render() {
    const { method, types, streamType, data, balance, coupons, couponsNum, customerInput,
      startDates, endDates, couponVisible } = this.state;
    return (
      <div className='w100'>
        <div className='row'>
          <div className={`${styles['bar-styles']} ${styles['finance-back-color']} row-l margin-r20`}
            onClick={this.onClickSetTypes.bind(this, '充值')}>
            <img className={`${styles['img-style']}`} src={finance} />
            <div className={`${styles['num-style']} col vertical-lt`}>
              <div className='fz28 color0'>{balance}</div>
              <div className='fz18 color0'>账户余额 (元)</div>
            </div>
            <div onClick={this.showModal} className={`${styles['type-style']} ${styles['finance-type-style']}`}>充值</div>
            <div className={`${types === '充值' && styles['triangle-style-finance']}`}></div>
          </div>
          <div className={`${styles['bar-styles']} ${styles['discount-back-color']} row-l margin-r20 margin-l20`}
            onClick={this.onClickSetTypes.bind(this, '折扣')}>
            <img className={`${styles['img-style']}`} src={discount} />
            <div className={`${styles['num-style']} col vertical-lt`}>
              <div className='fz28 color0'>{couponsNum}</div>
              <div className='fz18 color0'>折扣券 (张)</div>
            </div>
            <div onClick={this.showCoupon} className={`${styles['type-style']} ${styles['discount-type-style']}`}>说明</div>
            <div className={`${types === '折扣' && styles['triangle-style-discount']}`}></div>
          </div>
          <div className={`${styles['bar-styles']} ${styles['voucher-back-color']} row-l margin-l20`}
            onClick={this.onClickSetTypes.bind(this, '抵用')}>
            <img className={`${styles['img-style']}`} src={voucher} />
            <div className={`${styles['num-style']} col vertical-lt`}>
              <div className='fz28 color0'>0</div>
              <div className='fz18 color0'>抵用券 (张)</div>
            </div>
            <div className={`${styles['type-style']} ${styles['voucher-type-style']}`}>说明</div>
            <div className={`${types === '抵用' && styles['triangle-style-voucher']}`}></div>
          </div>
        </div>
        <div
          className={`
                ${styles['line-style']} 
                ${types === '充值' && styles['finance-back-color']}  
                ${types === '折扣' && styles['discount-back-color']}  
                ${types === '抵用' && styles['voucher-back-color']}`}
        ></div>
        <div className={'w100 col vertical-lt back-color0 padding20'}>
          <div className='row-l vertical-lt'>
            <div className='row-l margin-r10'>
              <span className='margin-r10'>客户信息:</span>
              <Input placeholder='可输入客户姓名、手机、地址等' className='w295' value={customerInput} onChange={this.onCustomerInput.bind(this)} />
            </div>
            <div className='row-l margin-r10'>
              <span className='margin-r10'>时间:</span>
              <Select value={method} onChange={this.changeMethod}>
                {
                  ['结算', '下单'].map((item, index) => {
                    return (
                      <Select.Option value={item} key={index}>{item}</Select.Option>
                    );
                  })

                }
              </Select>
              <DatePicker placeholder='开始时间' className='w198' value={moment(startDates, dateFormat)}
                onChange={this.onChangeDate.bind(this, 'start')} locale={locale} allowClear={false} />
              <div>至</div>
              <DatePicker placeholder='结束时间' className='w198' value={moment(endDates, dateFormat)}
                onChange={this.onChangeDate.bind(this, 'end')} locale={locale} allowClear={false} />
            </div>
            <div className='row-l margin-l20'>
              <Button type='primary' className={'margin-l20'} onClick={this.getData}>搜索</Button>
              <Button className={'margin-l20'} onClick={this.onReset}>重置</Button>
            </div>
          </div>
          <div className='row-sb w100 margin-t20'>
            <div className='row-l margin-r10'>
              <span className='margin-r10'>流水类型:</span>
              <Radio.Group onChange={this.onChangeRadio} value={streamType}>
                <Radio value={'全部'}>全部</Radio>
                <Radio value={'消费'}>消费</Radio>
                <Radio value={'充值'}>充值</Radio>
                <Radio value={'变更'}>变更</Radio>
                <Radio value={'好评'}>好评</Radio>
                <Radio value={'赠送'}>赠送</Radio>
                <Radio value={'修正'}>修正</Radio>
                <Radio value={'理赔'}>理赔</Radio>
                <Radio value={'退款'}>退款</Radio>
              </Radio.Group>
            </div>
            <Button type='primary' onClick={this.onExcel}>表格导出</Button>
          </div>
        </div>
        <div className='w100 back-color0 margin-t20'>
          <Table
            columns={this.columns}
            dataSource={data}
            size={'small'}
            rowKey={record => record.id}
            bordered
            pagination={false}
          />
          <PageTurning pageMaps={this.pageMaps} />
        </div>
        <PayModal ref={this.childRef} />
        <Modal
          title={'优惠券'}
          visible={couponVisible}
          width={700}
          onCancel={this.closeCoupon}
          footer={
            <div>
              <Button onClick={this.closeCoupon} type='primary'>关闭</Button>
            </div>
          }
        >
          <Table
            columns={this.couponColumns}
            dataSource={coupons}
            size={'small'}
            rowKey={record => record.id}
            bordered
            pagination={false}
          />
        </Modal>
      </div>
    );

  }

  //导出Excel
  onExcel = async () => {
    const { customerInput, streamType, startDates, endDates, method, skip, take } = this.state;
    let rsp = await getFinanceList(customerInput, streamType, true, startDates, endDates, method, skip, take)
    if (rsp.status !== 200) {
      message.error(rsp.data);
      return;
    }
    const columns2 = [
      {
        title: '订单类型',
        align: 'center',
        dataIndex: 'service_type',
        key: 'service_type',
        width: 100,
      },
      {
        title: '流水类型',
        align: 'center',
        dataIndex: 'type',
        key: 'type',
        width: 100,
      },
      {
        title: '金额',
        align: 'center',
        dataIndex: 'price',
        key: 'price',
        width: 100,
      },
      {
        title: '账户余额',
        align: 'center',
        dataIndex: 'balance',
        key: 'balance',
        width: 100,
      },
      {
        title: '备注信息',
        align: 'center',
        dataIndex: 'content',
        key: 'content',
        width: 100,
      },
      {
        title: '结算时间',
        align: 'center',
        dataIndex: 'check_time',
        key: 'check_time',
        width: 100,
      },
      {
        title: '下单时间',
        align: 'center',
        dataIndex: 'add_time',
        key: 'add_time',
        width: 100,
      },
      {
        title: '客户名称',
        align: 'center',
        dataIndex: 'order_info',
        key: 'name',
        width: 100,
      },
      {
        title: '联系方式',
        align: 'center',
        dataIndex: 'order_info',
        key: 'phone',
        width: 100,
      },

    ];
    let data = rsp.data.map(item => {
      // item.name=item.order_info.index
      let index = item.order_info.indexOf('1');
      item.name = item.order_info.slice(0, index);
      item.phone = item.order_info.slice(index);
      return item;
    });
    exportExcel(columns2, data, '财务中心表格导出.xlsx');

  }
  //客户信息
  onCustomerInput = (e) => {
    this.setState({
      customerInput: e.target.value
    });
  }
  //下单时间
  onChangeDate = (type, dates, dateStrings) => {
    if (type === 'start') {
      this.setState({
        startDates: dateStrings,
      })
    } else {
      this.setState({
        endDates: dateStrings,
      })
    }
  }
  changeMethod = (e) => {
    this.setState({
      method: e
    });
  }
  //卡片样式
  onClickSetTypes = (e) => {
    this.setState({
      types: e
    });
  }
  showCoupon = () => {
    this.setState({
      couponVisible: true
    });
  }
  closeCoupon = () => {
    this.setState({
      couponVisible: false
    });
  }
  //流水类型
  onChangeRadio = (e) => {
    this.setState({
      streamType: e.target.value
    });
  }
  //余额优惠券
  financebase = async () => {
    let res = await getFinancebase();
    if (res.status !== 200) {
      message.error(res.data);
      return;
    }
    let coupons = [];
    // let couponsNum = 0;
    for (let i in res.data.coupons) {
      // couponsNum += res.data.coupons[i].length
      coupons = coupons.concat(res.data.coupons[i]);
    }
    this.setState({
      balance: res.data.balance,
      coupons: coupons,
      couponsNum: coupons.length
    });
  }
  //财务列表
  getData = async () => {
    const { customerInput, streamType, startDates, endDates, method, skip, take } = this.state;
    let rsp = await getFinanceList(customerInput, streamType, false, startDates, endDates, method, skip, take)
    if (rsp.status !== 200) {
      message.error(rsp.data);
      return;
    }
    this.setState({
      data: rsp.data
    });
  }
  //重置
  onReset = () => {
    this.setState({
      streamType: '全部',
      customerInput: '',
      startDates: startDate,
      endDates: endDate,
    });
  }
  showModal = () => {
    this.childRef.current.showModal(this.state.balance);
  }
  //换页
  pageMaps = ({ skip, take }) => {
    this.setState({
      skip,
    }, () => this.getData())
  }
}

export default Index;