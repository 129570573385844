import React, { Component } from 'react';
import * as echarts from 'echarts';
import { Input, message, Radio, DatePicker, Button, } from 'antd';
import { getDepartment_Data, } from '../../config/storage';
import { analysisAddress } from '../../services/newOder';
import { getShopSummary } from '../../services/workbench';
import styles from './index.module.css';
import moment from 'moment';

const dateForm = 'YYYY-MM-DD';
const tomorrow = moment().add(1, 'day').format(dateForm);
const monthAge = moment().subtract(1, 'month').format(dateForm);
class Workbench extends Component {
  constructor(props) {
    super(props);
    this.state = {
      info: '',
      shopInfo: {},
      start: monthAge,
      end: tomorrow,
      recognize: {
        price: 0,
        minDistance: 0,
        noneWorker: false,
        hasWorker: false,
      },
      dataSource: [],
      count: {
        sum: 0,
        install: 0,
        measure: 0,
      },
    };
  }
  componentDidMount() {
    let shopData = getDepartment_Data();
    let services = [];
    if (shopData.service_type) {
      services = JSON.parse(shopData.service_type);
    }
    this.setState({
      services,
      shopInfo: shopData,
      type: services[0] ? services[0] : ''
    }, () => this.getShopOrder())
  }
  render() {
    const { info, start, end, recognize, count, } = this.state;
    return (
      <div>
        <div className='bar w100 margin-b10'>
          <div className='row-l padding-b10'>
            <div className='fz20 bold'>新功能通知</div>
          </div>
          <div>
            系统已经更新（按Ctrl+F5，强制刷新）
            可以在   店铺管理---视频模块  增加视频并编辑视频名称  记得保存哦
          </div>
          <div>新功能：上传视频、下单时添加图片</div>
          <div>可以在   店铺管理---视频模块  增加视频并编辑视频名称  记得保存哦</div>
          <div>在下单时  可以选择视频或者上传安装清单、安装步骤的图片，下单完成师傅就可以在订单详情中查看视频或图片</div>

        </div>
        <div className='bar w100 margin-b10'>
          <div className='row-l padding-b10'>
            <div className='fz20 bold'>服务区域查询</div>
            <div className='fz16 color8'>(请详细输入客户的地址到市县区和小区名字，
              以方便地址确认无误；本地址查询仅针对县城及以上区域的主城区的查询工作，
              乡镇以及村乡等因地图功能受限，以在线客服查询结果为准！)</div>
          </div>
          <div className='row-l vertical-lt'>
            <div>识别信息：</div>
            <Input.TextArea value={info} onChange={this.changeInfo} className={styles['box']} rows={4} placeholder='请将地址复制到这里' />
            <div className='margin-l20 row-l fz20'>
              {recognize.hasWorker && recognize.price === 0 &&
                <div className='color6'>该地区师傅资源充足，请放心下单！</div>
              }
              {recognize.hasWorker && recognize.price > 0 &&
                <div className='color2'>距离最近的师傅相差{recognize.minDistance}公里，需要收取远程费{recognize.price}元</div>
              }
              {recognize.noneWorker &&
                <div className='color2'>该地区暂无师傅，请联系客服开发</div>
              }
            </div>
          </div>
          <Button onClick={this.getQueryGeo} type='primary' className='w150 h34 margin-l70 margin-t10 btn-style'>查询</Button>
        </div>
        <div className='bar w100'>
          <div className='row-sb w100 padding-b10'>
            <div className='fz18 bold'>下单数据统计</div>
            <div className='row'>
              <DatePicker allowClear={false} value={moment(start)} onChange={this.changeTime.bind(this, 'start')} />
              <div className={styles['between']}>~</div>
              <DatePicker allowClear={false} value={moment(end)} onChange={this.changeTime.bind(this, 'end')} />
              <Button onClick={this.getShopOrder} type='primary' className='btn-style w110 h34'>快速查询</Button>
            </div>
          </div>
          <div className='w100'>
            <div className='row-l w100 fz16'>
              <div>总单量：</div>
              <div className='bold fz18 color3 '>{count.sum}</div>
              <div>（ 测量：</div>
              <div className='bold fz18 color6 '>{count.measure}</div>
              <div>，安装：</div>
              <div className='bold fz18 color9 margin-r5'>{count.install}</div>
              <div>）</div>

            </div>
            <div className='h400 w1550' id='main'></div>
          </div>
        </div>
      </div>
    );
  }
  changeType = (e) => {
    this.setState({
      type: e.target.value
    });
  }
  changeInfo = (e) => {
    this.setState({
      info: e.target.value
    });
  }
  getQueryGeo = async () => {
    const { info, } = this.state;
    let rsp = await analysisAddress(info)
    if (rsp.status !== 200) {
      message.error(rsp.data)
      return;
    }
    let recognize = {
      price: rsp.data.disFee,
      minDistance: rsp.data.minDistance,
      noneWorker: !rsp.data.hasWorker,
      hasWorker: rsp.data.hasWorker,
    };
    this.setState({
      recognize
    })
  }
  changeTime = (type, e) => {
    if (type === 'start') {
      this.setState({
        start: moment(e).format(dateForm)
      });
    } else {
      this.setState({
        end: moment(e).format(dateForm)
      });
    }
  }
  getShopOrder = async () => {
    const { shopInfo, start, end } = this.state;
    let count = {
      sum: 0,
      install: 0,
      measure: 0,
    };
    let res = await getShopSummary(shopInfo.shopId, start, end);
    if (res.status === 200) {
      res.data.list.map(item => {
        count.sum += item.order_num;
        count.install += item.install_num;
        count.measure += item.measure_num;
      })
      this.setState({
        dataSource: res.data.list,
        count,
      }, () => this.create())
    }
  }
  create = () => {
    const { dataSource, } = this.state;
    let sum = dataSource.map(r => r.order_num);
    let max = Math.ceil(Math.max.apply(null, sum) / 50) * 50;
    let option = {
      color: ['#07C160', '#f3a75e', '#1890FF'],
      tooltip: {
        trigger: 'axis',
        show: true,
        axisPointer: {
          type: 'line'
        }
      },
      grid: {
        left: '1%',
        right: '1%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        data: dataSource.map(item => {
          return moment(item.day).format('MM-DD');
        }),
        axisTick: {
          alignWithLabel: true,
        },
      },
      yAxis: {
        type: 'value',
        max: max
      },
      series: [],
      dataZoom: [{
        type: 'slider',//给x轴设置滚动条
        xAxisIndex: 0,
        bottom: 0,
        height: 7,
        showDetail: false,
        startValue: 0,//滚动条的起始位置
        endValue: 25 //滚动条的截止位置（按比例分割你的柱状图x轴长度）
      },]
    };
    option.color = ['#1890FF', '#07C160', '#f3a75e',];
    option.series = [
      {
        name: '每日订单汇总',
        type: 'bar',
        data: dataSource.map(item => {
          return item.order_num;
        }),
        barWidth: '20%',
        itemStyle: {
          normal: {
            label: {
              show: true,
              position: 'top',
              textStyle: {
                color: '#07C160',
                fontsize: 15
              }
            }
          }
        }
      },
      {
        name: '每日测量单',
        type: 'bar',
        data: dataSource.map(item => {
          return item.measure_num;
        }),
        barWidth: '20%',
        itemStyle: {
          normal: {
            label: {
              show: true,
              position: 'top',
              textStyle: {
                color: '#f3a75e',
                fontsize: 15
              }
            }
          }
        }
      },
      {
        name: '每日安装单',
        type: 'bar',
        data: dataSource.map(item => {
          return item.install_num;
        }),
        barWidth: '20%',
        itemStyle: {
          normal: {
            label: {
              show: true,
              position: 'top',
              textStyle: {
                color: '#1890FF',
                fontsize: 15
              }
            }
          }
        }
      },
    ]
    let chartDom = document.getElementById('main');
    let myChart = echarts.init(chartDom);
    myChart.setOption(option)

  }
}
export default Workbench;
