import React, { Component } from 'react';
import { Drawer, Button, Input, Select, Switch, message, Image, InputNumber, Modal } from 'antd';
import { getProductList, putCollect } from '../../services/product';
import { getDepartment_Data } from '../../config/storage';
import noPic from '../../resource/img/404pic.jpg';
import styles from './index.module.css';


class CollectDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      dataSource: [],
      modalVisible: false,
      currentProduct: {

      },
    };
    this.shopInfo = getDepartment_Data();
    this.baseUrl = 'https://ljkj-erp.oss-cn-hangzhou.aliyuncs.com/resource/';
  }
  render() {
    const { visible, dataSource, modalVisible, currentProduct } = this.state;
    return (
      <Drawer
        title='收藏夹'
        width={1000}
        destroyOnClose={true}
        onClose={this.onHide}
        visible={visible}
        footer={
          <div className='w100 row-l'>
            <Button onClick={this.onCancel}>关闭</Button>
          </div>
        }
      >
        <div className='row-w w100'>
          {
            dataSource.map((item, index) => {
              return (
                <div className={`${styles['collect-box']} margin-r20 margin-b10 col-t`}>
                  <Image src={item.pic ? this.baseUrl + item.pic : noPic} className='w200' preview={false} />
                  <div className='w100 tl bold fz15'>{item.name}</div>
                  <div className='w100 tl fz13 w100 twoline h40'>{item.info}</div>
                  <div className={`${styles['like']} w100 row-sb cursor-pointer`}>
                    <Button onClick={this.toCollect.bind(this, item)} type='danger'>取消收藏</Button>
                    <Button onClick={this.toEdit.bind(this, item)} type='primary'>修改别名</Button>
                  </div>
                </div>
              );
            })
          }

        </div>
        <Modal
          title={'修改别名'}
          width={500}
          visible={modalVisible}
          cancelText='取消'
          okText='确认'
          onCancel={this.onCancel}
          onOk={this.onOk}

        >
          <div className='row-l'>
            <div>名称：</div>
            <Input value={currentProduct.name} className='w400' onChange={this.changeName} />
          </div>
        </Modal>
      </Drawer>
    );
  }
  toCollect = async (e) => {
    let data = {
      shop: this.shopInfo.lj_shop,
      product: e.id,
      name: e.name,
      enable: '否',
    };
    let res = await putCollect(data);
    if (res.status === 200) {
      let dataSource = _.cloneDeep(this.state.dataSource);
      dataSource = dataSource.filter(r => r.id !== e.id);
      this.setState({
        dataSource
      })
    }
  }
  toEdit = (e) => {
    this.setState({
      modalVisible: true,
      currentProduct: e,
    });
  }
  changeName = (e) => {
    let currentProduct = { ...this.state.currentProduct };
    currentProduct.name = e.target.value;
    this.setState({
      currentProduct,
    });
  }
  onOk = async () => {
    const { currentProduct } = this.state;
    let data = {
      shop: this.shopInfo.lj_shop,
      product: currentProduct.id,
      name: currentProduct.name,
      enable: '是',
    };
    let res = await putCollect(data);
    if (res.status === 200) {
      let dataSource = _.cloneDeep(this.state.dataSource);
      dataSource.map(item => {
        if (item.id === currentProduct.id) {
          item.name = currentProduct.name;
        }
      })
      this.setState({
        dataSource
      })
      this.props.update();
      this.onCancel();
    }
  }
  onCancel = () => {
    this.setState({
      modalVisible: false,
      currentProduct: {},
    });
  }
  getList = async () => {
    let res = await getProductList('', '杆轨', 0, 500, true);
    if (res.status === 200) {
      let dataSource = JSON.parse(res.data).result.filter(r => r.collect);
      this.setState({
        dataSource,
      })
    }
  }
  onShow = () => {
    this.getList();
    this.setState({
      visible: true,
    })
  }
  onHide = () => {
    this.setState({
      visible: false,
    })
  }
}
export default CollectDrawer;