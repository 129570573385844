export default {
  namespace: 'orderRenter',
  state: {
    data: [],
    newOrderData: {},
    balance: 0,
  },
  subscriptions: {
  },
  effects: {
  },
  reducers: {
    getOrderRenterList(state, {payload}) {
      return {...state, data: payload.data};
    },
    getNewOrderData(state, {payload}) {
      return {...state, newOrderData: payload};
    },
    getNewBalance(state, {payload}) {
      return {...state, balance: payload};
    },
    delNewOrderData(state, {payload}) {
      return {...state, newOrderData: payload};
    },
  }
};
