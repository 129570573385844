import React, { Component } from 'react';
import { message, Spin, DatePicker, Image, Button, } from 'antd';
import Icon from '../../resource/png/mmLogo.png';
import styles from './download.module.css';
import { getExcel } from '../../services/download';
import { searchConvertObj } from '../../utils/getSearch';

class Download extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }
  componentDidMount() {
    let res = searchConvertObj(window.location.search)
    this.setState({
      id: res.id,
      shop: res.shop
    }, () => this.onSearch())
    // this.onSearch(res.id, res.shop)
  }
  render() {
    return (
      <div className='padding20'>
        <div className='w100 row margin-t100'>
          <Image src={Icon} />
          <div className='fz28 bold margin-l10'>墨墨数据下载</div>
        </div>
        <div className='row w100'>
          <Button type='primary' onClick={this.onSearch} className={`${styles['btn']} fz30 bold`}>下载</Button>
        </div>
      </div>
    );
  }
  onSearch = async () => {
    const { id, shop } = this.state;
    let res = await getExcel(id, shop);
    if (res.status === 200) {
      window.location = res.data;
    }
  }
}
export default Download;