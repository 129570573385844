//通用
const comom = [
  '宝贝已经收到了，包裹很严实，没有磨损，窗帘质量也很好，打开宝贝看，没有闻到异味，颜色我也很喜欢，遮光效果也很好，物流也很快。墨墨管家安排的师傅，效率高服务好',
  '东西做工不错，颜色很正。价格也不贵。等过阵子另外几套房子装修的时候来大量采购。客服态度很好，有问必答。发货也快。墨墨管家安排的师傅，人很好',
  '宝贝收到，第一感觉就是，色彩鲜艳，靓丽，视觉效果非常好，窗纱洗了还不缩水，装挂完毕，窗帘垂感棒棒哒，和家里的装修非常搭，给清新淡雅的家增加了一道靓丽的风景线，非常满意的一次购物，墨墨管家安排的师傅非常有耐心的沟通安装方案，体验感非常的不错，会推荐朋友来再次购买的',
  '快递收到了 物流很快 吃快递小哥态度也很好 总体而言之是一次满意的购物 窗帘没有色差 也没有异味 很漂亮 下午就叫人装上了 特别梦幻 亲戚朋友都夸我眼光好  包装也很严实 喜欢的朋友可以下手 买不了吃亏  墨墨管家安排的师傅服务也很好，值得推荐哦',
  '窗帘很好看 光鲜亮丽 把家里装饰的别有一番韵味 质量也挺好的 布料摸着舒服 挺喜欢的 而且也不贵 买的挺值的 喜欢的宝宝不要犹豫啦 墨墨管家安排的师傅，人很帅，也非常的有耐心，手艺也很好，总之都很好啦',
  '宝贝已经收到了，包裹很严实，没有磨损，窗帘质量也很好，打开宝贝看，没有闻到异味，颜色我也很喜欢，遮光效果也很好，物流也很快。墨墨管家安排的师傅服务超级好，辛苦师傅啦',
  '窗帘实物好看到，买起来放在客厅用的，质量很好，家人都很喜欢，准备卧室的也在这家买了！墨墨管家的师傅手艺也很好，装窗帘的时候还先去把手洗干净才挂的窗帘，给师傅点个赞',
  '给卖家客服们大大滴好评，因为对窗帘不了解，客服非常有耐心给我讲解并推荐合适的窗帘颜色和款式，现在挂上终于美美哒推荐买买买大家自己看吧～还有墨墨安排的师傅手艺也非常的好',
  '好棒！窗帘特别棒！以下为凑字数 看着挂好的窗帘，心情都非常的好，感觉特别棒！很棒！五分好评，墨墨安排的师傅服务真的是没有话说，给师傅点赞，给商家客服点赞',
  '喜欢的花色和图案，遮光布料厚实垂度感十足，做工精细长度适中，一次成功的网购，果断收藏店铺，老板态度不错，物流快又好，五分好评！墨墨安排的师傅服务上门非常快，安装的时候也很注意，梯子腿都用布包了一下，师傅说这是避免划伤地板专门弄的，真的是细节很到位呢',
  '定制的窗帘已收到，包装很认真，打开后一看更是很喜欢，马上就挂上了。窗帘图案很漂亮，布料也很厚实，外面有强光也有很好的遮挡效果。刚搬进新家，窗帘为新居带来不一样的舒适感受。因为要定做尺寸，客服很热情，介绍很详细，为客服也点赞，推荐购买。墨墨的师傅效率很高，服务也很好',
  '高端大气上档次选对店铺就是不一样，非常细腻丰富多彩。小区几个都看上我选的这几个花色了。已经把店铺介绍给大家。不客气啦！不要谢我，好东西要分享哈哈哈，还有安排的墨墨的师傅，非常细心，挂窗帘之前先去把手洗干净，然后带着白色的手套，说的怕给窗帘弄脏了，真是专业啊',
  '窗帘收到货后真的很满意，本来还很担心色差问题，后来发现我想多了，颜色很满意，遮光就更满意了，我的卧室白天特别亮，窗帘拉上以后几乎是没什么光的，特别满意！还有客服小姐姐的服务态度也特别好哦，准备以后如果有需要还会来这家店回购，很开心的一次购物体验！顺便表扬一下墨墨的师傅，手艺也非常的不错',
  '孩子的房间，我觉得很满意，温馨简单，质量也很满意，尤其是收边工艺很好，总之物美价廉，墨墨安排的师傅，服务也很好',
  '非常不错，遮光效果特别好，因为是定做的，客服人家也仔细讲解，总归来说非常不错，有喜欢的小伙伴可以在这家店看一下。墨墨安排的师傅，非常专业',
  '看图就知道和书柜很配，书房使我很漂亮，喜欢的很，男孩书房也不违和，喜欢，大爱，还是白色好看。墨墨的师傅也很好，进门穿鞋套，说话也很随和，点赞',
  '已经买过一次了这次是回购，很多朋友都说好看，不俗气，大爱，非常值得推荐的一家店铺哦，价格也非常的亲民，已经推荐朋友去看看啦，墨墨的师傅服务也很好',
  '是真的很喜欢这个窗帘，超级好看的。特意选了遮光度85的，这样房间不会太暗也不会太亮刚刚好哈哈。质量也不用说也很不错，当时拿到快递感觉超重的呢～质量很好，好看，全家人都很喜欢，比实体店实惠很多。会介绍朋友来买，墨墨安排的师傅非常专业',
  '窗帘挺漂亮，我这是灯饰店，跟店的风格很搭，在实体店问过价钱，真心比实体店便宜多了，值得购买。墨墨的师傅手艺非常的好，辛苦师傅了',
  '在淘宝看了好久了，最后还是选择了这家，没选错真的很好，质量搭配都不错，好看，选择这家没错放心拍，免打孔很好安装，不需要打孔。商家态度很好哦。店家服务很好，非常非常专业，从图片上看窗帘质量不错，颜色大气，性价比很高，送的伸缩杆也不需要打孔就可以安装，很方便牢固。',
  '比我想象的好太多，质量也可以，好看，这个价格料子也算比较好的了整体评价好这样的价格绝对值得购买，窗帘特别好布的质量还不错，总体来说还可以。着很舒服，和我家里很搭配，客服的态度很好哦。',
  '窗帘非常非常好看，挂上以后很合适，帘子很垂看起来很漂亮，买的时候一眼就看上了，实物和图片一样，而且做工精细非常好。性价比非常好，！简直喜欢到没法用言语表达啊。卖家发货也挺快，喜欢的亲们赶紧下手吧也挺快，总之对这次购物非常满意！',
  '窗帘收到了，遮光效果特别好。白天装的拉上瞬间变晚上。忘记拍张拉上的照片了。还有个客厅没装，会回购。喜欢他家的客服。都特别有耐心。窗帘挺好的，卖家还很贴心的把剩余的布料寄过来，布料还可以用盖沙发，而且窗帘做得也挺快的，遮光效果也很好，性价比很高呢！',
  '窗帘真的太好看了，大家可以看看细节，没有色差，纹路好细腻，摸起来软软的，布料很厚实有分量，也没有任何味道，产品质量过硬尤其是细节做得很好，感觉客厅装上这个太好看了，等新家装好了再来晒效果图。颜色真的超高级的，绝对值得下单呦',
  '收到货，迫不及待的就挂上了，几分钟就安装好了，不需要打孔，很简单，还真不错，比我想象的要好，商家服务态度很好，谢谢店家，下次有什么需要还会光顾贵店的噢，东西没让我失望。真没想到这个窗帘挺不错的，遮光效果也还不错，关键价位也合适，性价比很高了。比起我家以前的窗帘，已经好很多了。我这个高度是定制的，但发货很快！真心满意。',
  '速度很快，质量很好，也没味道，面料材质很防光的，纱挺软的的，但是有点点皱。商品品质整体的性价比很高。有些线头自己剪一下就好，墨墨师傅上门服务很好。安装的也很快。',
  '先后买了好几套这个窗帘，本来之前是打算在实体店买的，但是没看好，遮挡效果差，所以就在淘宝上面看了买了，一开始只买了两套回来看看效果，没想到确实不错哦，还有搭配的杆子，用来遮挡很合适了。家里人都特别满意，说这个窗帘很好了，比是实体店卖的还是要划算要好些，有需要的可以买这个试试',
  '在实体店没有看到好的窗帘，所以就在网上看了，对比了几家，选择了这个，因为这个有配套的杆子，就不需我再找人装那种打孔的杆子了，拿回家装上了，杆子不用打孔就是方便些，窗帘的质量也好，厚实，用来遮挡不错的，不是一般的那种，遮挡效果不好的，相反料子蛮好，而且很漂亮',
  '这个效果非常好，中午的时候拉上窗帘，房间几乎是全遮光的，非常棒，尽情享受短暂但非常惬意的午休。面料材质日常面料商品品质简单的包装，简单的窗帘，非常的有性价比。',
  '整体评价帘收到，料子很厚实，挂上有下垂感，加了衬布遮光也不错，窗帘做工很好，高度也按约定给改了尺寸，还送了抱枕套价比很高，客服小狐狸服务态度也非常好，不透风还隔音，晚上终于能睡个安稳觉了，物流也很快，超级满意！！！而且还是免费上门测量和安装。和我家墙特别搭！质量也厚实，如果全部拉上，屋里特别黑，遮光效果果然好！以后必须回购遮光效果很好，而且和别家不一样是根本就没有多少异味，非常方便。',
  '超喜欢超赞！最满意的一次网购啦是看着就开心的颜色呀用在朝西的最热是次卧的遮光度和防晒都很赞决定给家里阳台也换上啦',
  '对比质量的，无论是客服的服务热情，还是专业程度都是到位的，我搞错了尺寸客服还特意提醒我，细心专业。发货更快，收到货后第一时间装起来，我这个是跳色的，装起来效果特好，这个价格，实体店最少翻一翻还没这个效果，大家都说我装得漂亮，窗帘的质感也特别棒，垂直度很好，送的配件又特别多，特意给个不一样的评价，来告诉大家这家真的靠谱，靠谱，靠谱，一万个推荐不用怀疑和纠结了！！！',
  '客服也很耐心，布料很厚实，没有色差。果还好，无刺鼻气味，效果还是很好的。这是一次非常愉快的购物体验，遮挡效果好，东西不错哒。这个大小非常合适，好用，不负所望呀，放空中。米的窗帘很好',
  '窗帘已收到质量很好做工精细，布料厚实遮光效果不错，颜色很洋气显得很有档次，免打孔的安装很方便，而且拆卸也容易，面料厚实拼接色也很耐看，总的来说还是满意的，还会再来的',
  '窗帘今天已经安装好了，遮光效果还是可以的，材料很到位很足，装起来挺稳当，窗帘儿颜色拼接起来很洋气，也很上档次挺物美价廉的客服解答的很用心窗帘跟家里的装修也是和搭配很是满意',
  '窗帘真的太好看了，大家可以看看细节，没有色差，纹路好细腻，摸起来软软的，布料很厚实有分量，也没有任何味道，产品质量过硬尤其是细节做得很好，感觉客厅装上这个太好看了，等新家装好了再来晒效果图。颜色真的超高级的，绝对值得下单呦',
  '很素雅，适合炎热的季节。是想要的质感，没什么气味，很好安装，自己就搞定了。客服超级耐心，一遍一遍地为我解答各种问题，赞。尺寸完美！物流发的顺丰。',
  '很好很满意啊，窗帘没有味道，卖家也很细心地问我要不要改长度，最后刚刚好呢。挑了很多家货比三家最后选了这家，没有让人失望！很满意！喜欢喜欢！',
  '整体评价总体来说挺好的，买的时候担心安装不方法，收到东西安装起来用了5分钟搞定，尺寸也很合适，面料手感还不错。总体来说还是挺满意的，比实体店肯定是便宜很多的。',
  '自己选的这两个颜色，效果感觉还可以，其他颜色也没有挂，是不是比这两个色效果更好，也比较不出来。目前看，熨一下效果会更好！自己安装的手工还是比较满意的！布料质感手感都不错！',
  '感谢客服为我定制的窗帘，颜色好，尺寸大小合适，家人非常喜欢。客服热心，反复核对尺寸才下单，物流速度快，快递师傅送货及时。收到了，窗帘非常的好看，质量也很好',
  '完美啦，遮光效果不错，至于味道其实刚刚开始来的时候我自己感觉有一点点新布料的味道，不过不是奇怪的味道，味道有点像是新衣服的味道，挂好晾了两天味道就没有了。很清新的颜色，本来一直纠结选什么颜色，最后在客服建议下买了这款拼接色，很喜欢。',
  '很漂亮！纯色清爽，挂在书房或者客房最合适了，跟原来的窗帘替换着用，感觉更喜欢这颜色',
  '子还没进去住。放空中。已经挂上去啦。效果还是很好的。遮光应该也不错，开了灯拉上帘子也不见什么光透出来，晚上挂的，真心不错。卖家推荐的尺寸不错，褶皱效果也很好，挂上也不会太短，我不喜欢窗帘拖地，这个高度刚刚好。放客房用的，素色耐看，价格便宜，性价比很高的。发货也很快。',
  '很起来很好看，质量很不错，布料很厚实。颜色很好看的和家里配色觉得灰色可能会更适合，嘿嘿，遮光效果很不错垂感很好的，挂起来很有型，这样的价格的可以买的到这样的布料真心很不错的，很实在的一个卖家。比实体店好多了，以后需要还会再来',
  '窗帘收到就迫不及待的挂起来了，我家的风格是北欧，搭配这个窗帘非常时尚，颜色也大气，布料很好摸起来手感非常好，遮光效果也好！',
  '家里想换窗帘了，咨询了这家服务很好，没想到发货这么快，质量很好，还有收缩杆安装简单，遮阳效果也如商家所说的很好，很喜欢，我会推荐给朋友来买的，给予商家五星好评。',
  '因为后面的楼梯直通楼上面阳台，客厅开空调很费电，想在这里搞个隔断，一直担心这样的免打孔会不会因为太宽而安装不了，结果发现与期望的效果一致，很满意哦！而且客服态度也挺好的，有问必回，不厌其烦，发货也很快，我是定制的尺寸，也很快就发出了，今天到货，马上装上，确实很好，真心评价！',
  '在我精挑细选之后选择了这家店，宝贝收到了，布料非常满意，挂上之后也觉得很好看，因为房间比较亮堂，所以跟这个颜色还是挺搭的，原本我最想要的颜色不是这个，但由于年前没货，后来就选择的这个颜色了，结果出乎意料的满意，值得推荐购买！',
  '很漂亮！纯色清爽，特别是蓝色，挂在书房或者客房最合适了，跟原来的窗帘替换着用，感觉更喜欢这颜色，就是有点皱，得自己再熨烫。卖家有附了小图说明怎么挂，终于是挂好了',
  '挂上去了，挺好的，纱窗不是纱质飘的那种，比较有分量，文艺风，不是仙女范儿，配了灰色遮光窗帘，有一点点味道，等散味。能遮住百分之八十，不错，可以了，正正好好。',
  '全网比较后性价比最高的窗帘了。但是如果一个窗户挂一个窗帘，就略显单薄，看起来不高档。如果挂一副的话，应该会好一些。窗帘质量没话说，很好了！',
  '很不错，遮光效果非常好，比我想的要好很多，颜色非常好看的，没有色差，，真心推荐的，是我想要的东西。放了好几天没空装，今天才装上，窗帘不错一百多块钱能买到这个样子的太意外了，遮光度很好，拉上帘子屋子全黑了，要的就是这种效果，面料稍有点不垂诂计是放的时间太久了，很满意，以后有需要还会再来',
  '帘真的好美啊垂感和褶皱感都很棒果然是两倍褶皱是最好看布料也没有味道遮光虽然不是全遮光但对于正常家用而言完全足够了最后还是要感谢客服的耐心买的时候什么都不懂一步一步很耐心介绍给我听花了好几个小时搞定的看中的买家真心告诉你们这个窗帘值得你们拥有',
  '终于装上了，窗帘太棒了，尺寸准，是我想要的效果！卖家态度超赞，下单后几天发现窗帘尺寸和安装方式要变，当时卖家已经把窗帘做好了，而且不能在原基础上改，只能重新做，原以为我要再付一次费用呢，卖家居然说不用，太感动了！绝对是诚信卖家！',
  '窗帘买回来以后仔细的检查了一下，做工还是挺好的，特别的满意，也挺搭配我家房子的装修的风格的！以后会再来买的非常满意！价廉物美，值得够买。把以前的窗帘换了，特地买了这个效果非常的好，晚上使用感觉漆黑一片，睡眠质量都提高了很多',
  '颜色基本相符，一拿回来就洗了，这天气很快就干了。一层是用小米电动窗帘，另外配的便宜电动轨道。这样张开一层就可以透光，两层万全不透光',
  '效果很满意.比实体店便宜多了！褶皱很好看！挂钩质量非常好，挂上效果非常棒. 而且收边的地方还加了铅坠增加垂感.布料是我喜欢的那种，织的很细，柔软，不容易勾丝又有垂度， 装饰房间非常完美.发货速度很快的。',
  '收到货实在是出乎意料，质量非常好，做工也一丝不苟，良心商家啊 。绝对要五星好评。',
  '买了两个，颜色略暗，遮光效果很好，挡一下西的太还是挺好的，尺寸足够，长短合适，物美价廉，略有折痕不怎么影响使用，，满意，可以给五分好评的，有需要会在选购产品的',
  '贝质量真的很好，做工比较精细，窗帘有两层很遮光（图片显示的情况与实际情况不是很符合，真的遮光），在这都买两次了，真的很不错，物流也快，卖家也很细致，服务态度特不错',
  '宝贝已挂上了，美美哒，用了一星期了，非常喜欢。质量很好，厚实，摭光效果很好，宝贝跟描述相符，物美价廉很值得购买。同样的质量比实体店便宜很多，朋友也夸男孩房间用这窗帘很适合，把链接要去。诚信的店生意会越来越好。',
  '本来想等挂上照个照片再来评价的，但新家目前还装修没好，窗帘还没挂上，先来评个价吧，实物跟图片差不多，很好看的，也很便宜，容易安装，挺好的。',
  '窗帘特别好看 ，效果和图片差不多，颜色也好看，真是太划算了，比我之前在网上其他家买的好太多了，喜欢！下次需要肯定还会光顾的！',
  '看中了很久，逛了好几家店，最后决定在这家店买，布料质量不错的，那层纱是像蚊帐的材质，挂上去之后很漂亮的，小星星一闪一闪的，少女心炸裂呢，做工啥的各方面都很好，遮光也不错，早上醒来看星星很美的，总之性价比挺高的。',
  '窗帘一点色差都没有，质量特别好，非常喜欢，比实体店便宜多了，卖家服务态度很好，喜欢的朋友别犹豫。白天正常太阳光下，美翻了，跟我家整体的北欧风格也很搭',
  '很满意啊，超满意，物美价廉，看到心情都觉得美美哒，老妈让我再买一个给她也换了，少女心啊？感觉自己要不要这么能干，我都佩服自己的眼光，哈哈哈，还有表扬一下墨墨安排的师傅，手艺也非常好哦',
  '宝贝质量很好，款式也非常漂亮，比想象中的好，非常漂亮 大气 开始犹豫了好久 是不是要在网上订制 担心万一不合适会很麻烦 去了实体店 看不到合适的面料 看中的价格又太夸张。看了他家的咨询了款式和面料 就感觉还不错 主要是店家太细致 反复和我确认 连我想不到的 她都给我处理好 就凭这点就值得信赖 今天刚装上效果很棒 也很有质感 而且价格比实体店便宜好多 好评 绝对好评 赞赞 以后有需要就在她家了 真的很不错',
  '还是比较满意的。纱我很喜欢。尺寸都合适，只有幔长了一点。但是安装师傅给我想办法折叠进去了。赞一个这家店的服务还是满好的，第一次在网上买窗帘，很怕尺寸不合适。结果还是满意的',
  '很棒，全五分！从测量到安装，到效果都非常完美。客服小夏不厌其烦的解答，让人十分感动。货到后看到包装非常好，分类包装，清单也非常详细。安装师傅手脚麻利，热情大方。总之，全五分。',
  '非常漂亮，大气，只有三种颜色就都选了，每个屋都有不同的感觉，非常好',
  '窗帘真的很漂亮，非常满意，实物无色差，和图片一样漂亮，加了遮光布，效果更好。',
  '第一张是晚上拍的 后几张是早上拍的 遮光效果还是不错的 我家面东 早上阳光特别充足 有了它就可以美美的睡懒觉啦o((*^^*))o 实物比图片好看  已推荐给姐姐 她也买了 期待她粉色和蓝色的效果图~',
  '好看诶！虽然我还没有看到实物，只看到家人发的照片，很喜欢，好梦幻的感觉，据说有点味道，反正现在也没入住，通风放味吧！等入住了洗洗应该就好了',
  '之前一直太忙，没来评价，窗帘已经装上了，真的不要太梦幻，一装上，房间整个的感觉都不一样了，价钱也比实体店的便宜，没有另外的收费，还在犹豫的可以入手了！',
  '真的超级美，感觉房间很少女，男朋友觉得这个蕾丝边让他看起来很娘，没办法，谁叫我喜回，阳光透过窗帘的感觉真的特别美好，希望遮光布料再好一点就更完美了，敲美的，快来买，网红范妥妥的没毛病(*＞)skr~',
  '出乎意料的好看，粉粉的，很少女，最近就喜欢这种粉色的，而且布料很厚实，遮光度也十分的好，还有绑带。决定还要定制一款。',
  '货真价实，比实体店好多了，搞定，好看，刚弄好，晚上拍效果没有那么好，实际比图片更好看，比较这个价格，价值太值了，第一次这么认真的夜写评价',
  '宝贝收到，很满意的一次购物，很漂亮，是我喜欢的，有种仙仙的感觉，挂上一定好看！做工不错，快递给力！店家服务也周到，还送挂钩！',
  '窗帘收到了，质量非常的好，比在外面做划算多了，卖家服务很热情，款式很漂亮，发货速度超快的，质量不错，新房装修收尾还没有使用，效果一定很好，期待。',
  '忙忘记评价了，东西非常的好，价格很实惠，物美价廉，真的质量很好，比较了很多家，最后选择了这家，颜色和款式跟房间也很搭，下次还会来，值得购买哦满五星评价。大家放心购买。',
  '窗帘收到了，物美价廉质量挺好的。买来挂在阳台遮阳光的，很满意、会推荐给朋友们的，还没安上，等老公回来安上，这样阳台就不晒了，阳台是厨房、调料怕晒，很适合用，真好，',
  '之前咨询了好几家店，感觉本店服务态度挺好，也细心讲解，收到东西后，赶紧拆开看了，装上看了下效果，说实在的，颜色搭配起来很好看，窗帘面料的确不错，垂感也好，是我想要的样子！',
  '窗帘质量非常好，做工很精细没有线头，遮阳效果也是很到位，便于悬挂，颜色也很鲜艳的，非常满意，好评！！质量真不错，而且样子也很好看，符合现代简约风，颜色也跟图片一样没有色差，感觉比图片还好看，但是收到以后会觉得等的时间是值得的',
  '一直在找合适价廉物美的窗帘，选了好久选了这家，第一客服非常专业，给的尺寸准确的算出总价，不像我之前遇到的客服总是一句差不多多少。窗帘到货后立马装了遮阳效果还是不错的白天拍的。',
  '一直很喜欢北欧风，看了很久的窗帘，终于找到价格合适，风格满意的了，服务态度很好，发货速度快。真心不错，很喜欢这个图案了，简洁大方，！送了绑带和超多的挂钩，满意的网购！',
  '窗帘收到了挺意外的，挺漂亮又上档次，本来没抱什么希望买的试试看，超乎我的想象，买对了下次需要还会再来的',
  '买之前还担心会有色差，但是收到产品后，很满意，说真的。这窗帘挺好看的。尺寸也刚刚好是定制的。买看客厅跟房间的，效果非常的满意。下次有朋友要买窗帘我啊会介绍给你的。价格还挺实惠的。',
  '窗帘收到了，个人习惯清洗一下，还是挺好的，不缩水不掉色，没有异味，颜色没有多大差别，摸上去料子质量很不错，很有手感，很好的窗帘，我很喜欢，谢谢卖家！发货速度很快，解答很详细，赞',
  '窗帘 运输 安装都非常好，客服有问必答，非常专业耐心，全五星值得推荐',
  '先做了个小窗帘非常好，打开包裹那一刻就感受到了用心，要的小样尺寸比别家大更适合参考，窗帘包装完好，叠放的整整齐齐，只要挂上去就可以了。家人都说这窗帘提气，看着精神，定型可以，价格实惠，比起邻居们上千的窗帘儿，我感觉还是这个好。',
  '颜色非常满意 第一次在这里买 开始尺寸还量错了 卖家又重新帮我做了 太感谢了，窗帘物有所值，效果完全达到逾期，客服热情专业，推荐购买',
  '东西做工不错，颜色很正。价格也不贵。等过阵子另外几套房子装修的时候来大量采购。客服态度很好，有问必答。发货也快。',
  '收到了，第一时间挂起来。这质量也太好了吧。物超所值，好看。真心喜欢。物流也超快的。棒棒的。',
  '宝贝非常好，质量好做工好颜色好手感也特别好，我对我自己的选择非常骄傲，婆婆一个劲的夸我有眼光，哈哈，客服态度非常好，不厌其烦的教我测量尺寸，发货也很快，最主要的是，质量超好，绒绒的仿羊毛材质，垂感很好，下次还来这家店买！',
  '贝收到，第一感觉就是，色彩鲜艳，靓丽，视觉效果非常好，窗纱洗了还不缩水，装挂完毕，窗帘垂感棒棒哒，和家里的装修非常搭，给清新淡雅的家增加了一道靓丽的风景线，非常满意的一次购物',
  '快递收到了 物流很快 吃快递小哥态度也很好 总体而言之是一次满意的购物 窗帘没有色差 也没有异味 很漂亮 下午就叫人装上了 特别梦幻 大人都夸我眼光好 快递又不会说磕磕碰碰 包装也很严实 喜欢的朋友可以下手 买不了吃亏',
  '宝贝已收到，包裹严谨，窗帘质量很好，颜色很正好看，比想象中好很多打开也没有异味，一到手就拿来装上去了，很漂亮遮光效果非常好，洗了也不退色价格实惠，喜欢的放心购买吧',
  '真心不错，布料很好，做工也很精细没有线头，没有色差，买回来洗了不掉色，跟我家的风格很搭，尺寸也刚好呢，下次还来',
  '我是第一次在网上买窗帘的，一直担心买到的质量不能保证，所以我看了很多家窗帘，最后选择了这家的，收到货后真的很惊喜，窗帘完全没有我担心的那样，样子特别漂亮，质量也是很好的，特别喜欢，大家安心下单吧，绝对错不了的！！！',
  '喜欢的花色和图案，遮光布料厚实垂度感十足，做工精细长度适中，一次成功的网购，果断收藏店铺，老板态度不错，物流快又好，五分好评！',
  '很不错，物美价廉，值得购买，整个房间因为有窗帘，变得好看好多，孩子也喜欢，透过星星形状洒满阳光，很好看，遮阳效果也很棒，推荐',
  '产品物美价廉，质量一如既往地好，满意非常满意，窗帘不仅颜值高，而且质量好，尤其是客服的态度很赞，耐心的解决各种问题！大家可以放心的购买哦！快递送货的速度也很快！点赞！',

];
//麦文
const comom1 = [
  '商家免费赠送高温定型，发货时间也很快，颜色很高级，面料厚实做工精良，走线整整齐齐。整体颜色和卖家描述一致，安装好氛围感直接拉满。商家服务的确值得称道，测量设计安装一体，基本不用自己操心！',
  '整体评价：窗帘的质量真的惊艳到我了，面料摸起来细腻柔软，做工走线都特别好。安装好的效果远远超出我的预期，风格和我家很搭，商家赠送的高温定型窗帘更加垂顺美观。比我之前买的都好，推荐推荐！',
  '收到窗帘啦，实物很有质感，面料也是很高级的那种，软软糯糯特别舒服，而且整体的做工很精湛，细节地方都考虑的很好，连包边的用线都是使用同窗帘一个色的，整体感巨棒，简直就是窗帘天花板！！！',
  '软装的时候对比好几家网店小样，拿着小样和老公一起在家里比划了几天，一致认为麦文的小样更软糯，更合适家里，今天师傅挂好后的颜值很惊艳，颜色和当时寄来的小样是一样的，没有色差，而且我们还赠送做了高温定型，挂起的垂坠感也十分不错！最最重要的是面料不含甲醛，挂着家里一点异味都没有非常环保！！已经推荐给朋友了！',
  '对比了多家才选的麦文，麦文果然就是不一样，从视觉上看起来就有质感，颜值高的同时做工还特别精致，遮光还好窗帘一拉上外面的阳光根本就照不进来！！买过最好的窗帘就是这个了，家里人都夸我买对了！开心开心！！',
  '和闺蜜一起装修，一起对比好多家最后才选择的麦文，高端窗帘安装好后果然惊艳，浅色系的窗帘让整个房间都显的更加的有氛围感，而且商家竟然在窗帘上还加了很多细节小设计，专属的黑色带了logo，还很贴心的备用挂钩，感觉商家非常用心！',
  '窗帘刚到就感觉不错，挂上去果然惊艳，颜色是我看小红书选的，面料也好摸着柔软细腻，也没有任何异味，克重足足的垂度也非常好，整体看上去高级感满满。网购窗帘完美下车，麦文布艺果然名不虚传哈哈!',
  '感谢客服的推荐，这款窗帘真的非常适合我家，果然专业的事还是要交给专业的人，安装后瞬间就有家的感觉，窗帘摸着手感也很不错，细腻柔顺，而且我这种对气味比较敏感的，凑近闻也没有任何异味，我怎么就没有早点发现麦文这个宝藏窗帘呢~',
  '窗帘的面料质量都很好，全年适用，长度尺寸正好，很搭我家里的装修风格。我要特别感谢下接待我的客服，服务态度也挺好的，非常有耐心听我的询问，解答的也特别详细，感觉买完窗帘我都快成专家了！安装师傅也非常的专业！！',
  '整体评价：窗帘挂上以后感觉家里都变温暖了很多，面料厚实，垂坠感挺好的，看着很有质感 面料材质：安装测量都有师傅上门，做出来的尺寸正合适，离地既不会漏光也不会拖地，刚刚好！ 商品品质：麦文不仅窗帘做的好，服务还这么周到！有品牌的果然值得信赖，真心推荐给大家！！',
  '家里装修买了这么多的东西，这次购物是最让我满意的了，客服很有耐心有问必答，推荐给我的窗帘装上后确实不错，整体颜值超出了我的预期！！推荐大家不确定款式的可以让客服推荐，很专业，挂上去特别好看，遮光也很好，窗帘还送了高温定型，看着特别有型！！当时就是看到小红书姐妹推荐购买的！爱了爱了！打call！',
  '我是听朋友的推荐才了解到了麦文，朋友说是性价比特别高我本来还不相信，浏览对比了一下确实比其他家的好，客服服务态度很热情也很专业，推荐的窗帘很符合我家的装修风格里，我买的这两款窗帘都不错，邻居还问我要了链接！',
  '整体评价：窗帘安装后的颜值很高，感觉家里的格调都提升了很多 面料材质：材质看了检测报告，是母婴级的合格产品，非常的环保，太超前了 商品品质：来上门安装的师傅也很专业，安装的时候会特意带上手套，防止弄脏窗帘，安装好后，还热心的帮我收拾一下垃圾，并告诉我一些窗帘的注意事项，真的有被暖到',
  '窗帘用了一段时间了，各方面都很满意！因为我休息日都有睡懒觉的习惯，所以我们挑选的窗帘的时候除了颜值就是遮光效果了，最后在朋友的推荐下，看小样的时候我还用手电筒照了下，果然不透光，我还是特意加了层遮光布，安装后遮光效果确实是可以的，朝阳的窗户拉上后房间直接黑乎乎的，而且正面完全看不出有加遮光布，窗帘的其他方面也不错，方方面面都符合我的要求',
  '买之前还有点犹豫要不要买这么好的窗帘，收到货挂上去后感觉太惊艳了，颜色好看面料还很有质感，而且做工方面也很用心，包边走线都很直很工整，缝线是和面料颜色一致的，麦文能做到这么细节真是出乎我的意料！这种品质才称得上是专业做窗帘的，没有枉费我期待了这么久',
  '没有在网上购买过窗帘，一直很犹豫不敢下手，线下逛了好久都没有称心的，邻居家装的窗帘就是麦文的，看了一下小样款式都很漂亮，就和客服沟通细节了。窗帘挂上后的效果比我预期的还要好看，质量做工都挑不出毛病，没想到在网上能买到品质这么好的商品',
  '新房装修对比了很多家，收到货证明自己的选择没有错，果然是靠品质说话的，整体做工都很满意，窗帘的垂感十足，选的颜色也适合每个房间，最重要的是高温定型后不但垂感十足，关键是没有异味！下次需要还会再来的！到时候要给优惠喔！',
  '第一次在网上买窗帘，各种做攻略看布样！卖家给寄了样品，还告知不需要的话可以退款小样费用，整个沟通过程中态度都非常好，师傅测量安装也都是态度很好的，比线下便宜多了！一整个爱住了！！',
  '窗帘到货刚挂上就来分享了，做过高温定型的果然不一样，别家窗帘定型都要加钱，建议大家都试试麦文，挂上后很好看，整整齐齐的，给人非常顺心的感觉，窗帘的走线很平整，看得出店家对细节蛮用心的，比邻居在实体店买的棒多了，装修不踩坑！！',
  '买窗帘之前我建议大家一定要先看看小样，我摸到实物的面料才决定买这款，因为窗帘毕竟是软装中的灵魂，关系到整个软装搭配的嘛，麦文的这款虽然价格有点超出预算，但是一分钱一分货，但是品质没得说！',
  '我真没想到原来网购的窗帘这么香，特别想要那种法式的感觉，问了客服，推荐我选择了外面挂奶油色的布帘，里面挂法式纱帘增加氛围感，加了遮光布全遮光特别厚实，摸起来特别有质感，做了选2倍褶皱，做了高温定型特别有垂感，师傅上门尺寸量的特别正好，里面做的点点纱，就是我想要的那种法式的纱帘，完全长在我的审美上了',
  '追求氛围感的话闭眼入吧，真的是完全长在我的审美上太绝了吧！！神仙卖家沟通起来一点问题没有，师傅上门也是态度很好，很专业的一家！推荐推荐！！',
  '窗帘装上去几天了，每天回家看到都觉得心里美美哒，不管是颜色还是质量都是顶级的，看起来特别高级，氛围感没满满！窗纱的颜值也超绝，被风吹得轻轻飘动，阳光照进来都柔和了许多，看起来朦朦胧胧的特别的梦幻',
  '客服真的很有耐心，不仅教我量尺寸，还帮我搭配颜色，沟通起来很顺畅，有问必答，服务很到位。窗帘到货挂上后的效果整个客厅都有种温馨感，满意！！',
  '价格不算便宜，但是他家的客服回复真的很及时，款式颜色是客服帮忙推荐的，收到后还有专业的师傅上门安装，而且窗帘还赠送高温定型。窗帘挂上之后，颜色很搭配卧室的风格，质感非常的棒，做过定型后的效果确实不错，看着板板正正的，整体都超过了我的预期',
  '窗帘颜色和风格都符合我的审美，和家里的装修风格也很搭配，让人看了很放松，窗帘面料摸着就是好料子，凑近闻也闻不到什么异味，用料满分。而且麦文对细节的把控也很棒，侧边条上的logo看着还挺精致，整个窗帘的缝线用的都是和面料同色的线，看起来整体性更强了！！！',
  '服务真是太周到了，客服会根据我家的装修风格推荐颜色款式，我只需要跟这单方案下单就可以的，下单后，只要问客服窗帘做的怎么样了，客服都会很快的告诉您客服做到什么步骤了，不会像我之前遇到的一些客服，老是用官方回复来敷衍我。而且窗帘到手后，也是有客服安排师傅上门安装的，我都更本不用怎么出力，窗帘就给装好了！宝藏店铺!!!',
  '我一直对遮光是比较在意的，之前在实体店买的翻车了，于是决定重新选购一款窗帘，在朋友的推荐下才了解到麦文，看了各种不同的款式，最后听了客服的建议选择了这款窗帘，挂上后拉上窗帘遮光效果很棒，和之前的对比有明显的差距，蛮推荐购买麦文布艺的！！',
  '窗帘这种家具当然要以质量为首选，其次是颜色款式。看了好多小样，面料的质感遮光都不错，颜色也是我喜欢的！咨询了客服测量安装也有师傅上门，感觉挺方便的就决定在麦文购买了。这款窗帘装上之后效果很赞，褶皱整齐，垂感自然，平常也不用打理，遮光效果也足够使用，整体很满意！',
  '家里软装一直敲定不下来，线上线下选了很就都犹豫不决。偶然间看到这款窗帘眼前瞬间一亮，联系客服邮寄的小样质量也很满意，没有怎么犹豫就下单了。窗帘到的还挺快的，挂上墙后的效果很棒，整体素净典雅又不失大气，品牌店确实靠谱些！！',
  '买之前一直犹豫不决，先看了小样觉得质量颜色都很不错，卖家还告知轨道是十年质保，安全感满满的~看了很多店家都是不支持质保的！这家买了很放心！！又好看质量又好！！推荐犹豫的集美冲！！！',
];
//金蝉
const comom2 = [
  '窗帘挂上去后我还是挺满意的，店家免费给我做了高温定型，看着很有档次。颜色是气质十足的奶茶色，和家里的乳胶漆蛮搭的，客服给我搭配的幻影纱也很不错，透光不透人的隐私性很好，整体氛围感非常棒！金蝉品牌果然名不虚传，挂了几天我是越看越喜欢啊！',
  '当初买窗帘的时候，没经验，也怕麻烦！还好朋友给我推荐了这个店，挑选好颜色款式后，安装测量都是客服给我安排墨墨管家的师傅上门。自己没怎么动手窗帘就挂好了，整体的效果挺满意的，推荐我做的二倍褶皱很出效果，看上去立体多了！家里看见也整洁多了。',
  '先看了他家的小样，包装精美颜色和款式都很喜欢，和客服沟通了下细节，就赶紧下单了。卧室特意选择了颜色深一点，看着更有韵味和气质，同时遮光也会好一点让我睡的更舒服了！',
  '金蝉真是窗帘的宝藏店铺啊，因为家里急用没多少时间挑选款式，选择了评价不错的金蝉，客服根据我家的风格推荐的颜色款式。窗帘到货后确实给了我惊喜，比我想象的还要好看，之前不知道窗帘还有高温定型这项工艺，做了之后看着也太整齐了！',
  '金蝉的窗帘真是选对了，质量款型嘎嘎好，客服人员精明强干，计算准确，沟通顺畅。尤其值得一提的是墨墨管家的师傅上门测量和安装都按照预约时间准时准点，服务周到，而且安装流程合理，技术过硬，经验丰富，特别注意安装细节，安装完轨道和罗马杆，特意洗了手才开始挂窗纱和窗帘，太贴心啦！感觉五星好评不够用啦，想给十星呢哈哈哈哈哈哈哈…',
  '看了邻居家在实体店买的窗帘感觉不是很理想，毅然决然的网上买了，虽然只能通过线上沟通，但是金蝉的测量安装都是上门来的，客服热情耐心的服务让我很舒服，窗帘看着就很上档次，面料柔软细腻色泽也比较柔和，价格也比起邻居家的优惠很多，总体感觉买的很值',
  '安装上一段时间了，目前没有什么问题，我家的窗户还挺考验商家的做工的，本来想在实体店买的，看来看去都不符合心意，闺蜜说金蝉的窗帘还不错看了也很喜欢，上门测量的服务让我比较放心，窗帘做出来尺寸确实很合适。严丝合缝的，下面也给我留了合适的尺寸，非常的满意！',
  '窗帘到的还挺快的，克重很足满满的一大包！墨墨管家的师傅非常准时就到了，进门的时候还穿着鞋套，专业的技术很快就装好了，走的时候把纸箱什么的都带走了收拾的干干净净的！窗帘好看服务还这么好，金蝉不愧是窗帘界的top1啊！',
  '今天家里的窗帘终于安装好啦，很好看的颜色，上手摸到的质感也特别舒服，让我满意的是店家服务态度很好，我有疑问的时候，客服妮妮给了我很多中肯的建议，而且墨墨管家的师傅测量安装的有礼貌，技术也好，总的来说十分满意的一次购物。',
  '之前咨询了客服一些尺寸方面的事情都能帮我解答上，真的很让人省心。窗帘做工也让人很放心，面料的确属于不透光的那种，布料厚且有垂感，配送的挂钩质量也都很结实，买的值呀咱就是说！',
  '窗帘收到了，墨墨管家的师傅技术熟练，窗帘挂上去长度刚刚好，离地正正好好的没有拖地，日常打扫起来也方便。金蝉免费给做的高温定型，让窗帘显得板板正正的特别的高级，我家之前的窗帘没做过看着差距好大！',
  '老公经常上夜班想要买遮光效果好一点的窗帘，颜色想要我非常喜欢的奶白色啦！本来以为浅色还要遮光好的会不会不好选，金蝉真没让我失望，客服妮妮一下就把这款给我推荐了出来。窗帘挂上后也没让我失望，老公也说这几天的睡眠质量提升了不少，感谢金蝉！',
  '第一次在网上买窗帘，没想到这么顺利。商家的服务非常好，客服是有问必答，给我安排的墨墨管家的师傅也很到位。很感谢客服多多，给我推荐邮寄了很多的小样给我，还教我挑选，最后窗帘做出来是打心眼里喜欢！',
  '客服也很有耐心，沟通顺畅，小样礼盒包装也很精美就下单了。收到窗帘就联系客服预约师傅了，没有什么味道，摸上去手感也很好。最重要的是比小区附近的窗帘实体店便宜很多！可以说是我装修期间最满意的一次线上购物了。',
  '不得不说他家的客服蛮专业的，推荐的款式一下就戳中了我的点，在愉快的沟通后便买下了窗帘，没想到网购也可以这么顺利。到货挂上后整体看着挺满意的，特别是遮光性确实很不错哈哈，安全下车啦!',
  '金蝉窗帘是我见过售前售后最用心的一家，我是一个人住所以有些不太方便，所以从尺寸的窗帘到安装都是客服小姐姐在帮我张罗，之前我接触到的销售都恨不得客户多买，但是在这里我感受到了真诚和真心。为这样的商家点赞，真的很用心在服务',
  '客服的服务很好，响应的很及时，货物收到就帮我联系墨墨管家的师傅上门安装，师傅也是十分给力，干活不墨迹，期间发生了一些小问题，客服也都很快的帮忙解决，不拖拉。窗帘装上后的效果也是特别满意的，看了之后感觉心情都好了很多。',
  '这家从刚开始对接客服就非常专业 从测量到发小样到后续安装都全程跟踪服务到位 窗帘质量不错，安装的师傅都说物有所值 垂感刚挂上就很好，挂一段时间以后应该更有提升 面料材质：三个房间分别选了三种材质 都很可以 想要遮光度好的可以不要犹豫了 幻影纱纹路清晰连贯 商品品质：检查了下没有线头破损什么的，挂钩质量很好，距离地面的高度刚刚好 快递上门 非常满意的一次购物',
  '买之前看了不少店铺，只有他家的客服态度让我感觉挺舒服的，也解答了我所有的疑问。拿到手之后质量也是一流的，效果的话可以看图，实物比照片好看多了。总的来说我还是中意他家的，感受还是很好的！',
  '网购了这么久，这是很少有店家能服务到家的，老家这边基本上每次快递都要自己跑好远拿快递。而这次我是躺在家里等着快递上门，省心省力，窗帘的质量很好，就连墨墨管家的师傅都说这个价格能买到这样厚实的窗帘，真的划算！',
  '第一眼就看上了，然后跟客服确定尺寸，毕竟是订制品需要仔细一些，客服也非常负责任，认真核对再三确定才会下单制作，非常严谨，收到真被惊喜到，基本和图片没有色差，质量很好，挂起来很漂亮',
  '最满意的就是服务了，我家这边快递站离得很远，我又懒得出门，还好他家是送货上门的，真是懒人福音呀~收到后客服还安排了墨墨管家的师傅上门，全程不用动手就搞定了，大家看看窗帘搭配好后的效果图，完美融入其中，很难不爱呀~窗帘界的顶流~',
  '这次的购物体验很棒，客服木木特别专业，一直耐心地跟我沟通方案，而且也推荐了和搭配窗帘的款式颜色，并核对了窗帘的各种制作细节，最后安装的效果很搭我家的装修风格，面料柔软细腻，比在实体店买的还要好，非常满意！',
  '商家非常细心，服务态度好，还贴心询问了要几倍褶皱，打开外包装后，商品很干净完好，颜色没有色差，还送了窗帘绳，这点很不错，物品没有瑕疵，这是第一次写好评，真的懒得打字，但是看着上档次，无瑕疵，证明卖家做工很用心，和其他店铺同等价位的窗帘对比很不错，很划算！',
  '购买前，客服对提出的问题都进行了很详尽的回答，让我放心了不少。后面寄过来的小样都是包装过的，能感受到店家的用心。到货后安排师傅上门安装，也是跟着我们的时间，省心了不少呢！',
  '买之前看了好多小样，金蝉的包装更加精致，也有上门测量的服务，能感受到店家很有心意，所以就放心买了。窗帘挂上去很好看，颜色是我喜欢的奶茶色，与我的小卧室氛围非常搭配，让整个房间都变高级了。',
  '不得不说，店家很专业，开始我对测量很不懂，但是客服很有耐心，仔仔细细的告诉我测量方法，后来还是安排师傅上门来测量哈哈，窗帘做的高度和测量的一样，挂上尺寸非常合适，果然专业的事是得交给专业的人来做，窗帘整体效果十分高档，瞬间让人眼前一亮！！！',
  '窗帘的质量非常不错，款式十分好看，上墙后的效果也特别高级！客服专业认真，墨墨管家的师傅也是非常符合认真，从测量到最后的安装成功，每个环节都做的很棒！等下次有需要还会来买。',
  '收到小样去对比了线下，发现看不上线下的了，果断下单，客服很耐心，量尺安装特别顺畅，体验不错，也出效果。 面料材质：窗帘是我想要的质量，抗菌的面料，性价比很高，比实体店便宜很多，质量还很好。 商品品质：客服非常专业~我只是负责确定一下颜色面料，其他的事情都是店家完成的，节约了我的很多时间',
  '新家装修在朋友的推荐下买了这款窗帘。上墙的效果很符合我的预期，颜色款式让我很惊艳，版型也特别不错很符合卧室的风格，而且在购买过程中与客服沟通的感觉很流畅，每次都是非常耐心热情的帮我解决问题。',
  '窗帘已经安装完毕，从测量到安装都是一站式服务，让我这种上班族真的很省心，最终安装好的效果很合我的心意，客厅看上去特别高档，卧室的这款是选的是舒眠绒，遮光效果出众，毕竟是卧室嘛，肯定要高遮光才能满足于我哈',
  '网购窗帘不放心质量，但店家能免费寄小样这点做的很好，能让消费者先了解的产品质量，另外还能安排师傅上门进行的专业的测量和安装，也不用担心尺寸安装问题，于是全家的窗帘都定在他家了。安装好后个人感觉比实体店还要好，非常值得购买！',
  '比了实体店和网上的一些店铺，最终选择了金蝉，果然没让我失望，做出来的窗帘很有质感，高温定型效果很明显，自然又板正。接待我的客服都很有耐心，一遍遍的核对，一遍遍的沟通。制作周期大约是十几天左右，到货挺准时的',
  '从网上选购开始，这家的客服就不厌其烦的帮我参谋窗帘颜色材质，非常有耐心，还为我两次邮寄小样，让我感到心里非常舒坦，我认准了这个品牌。窗帘寄来后，发现产品质量手感都非常好，一点瑕疵没有，价格比实体店便宜多了，就是网上同类比较，这家产品的性价比也是最高的。',
  '客服挺专业的，问出的问题都得到了及时详细的回答，看了小样对比了多家还是决定在金蝉下单，快递是直接给我寄到家门口的，安装师傅也是客服给我预约的，挂上去的效果也是非常的满意',
  '说实话选择金蝉是因为店家的服务征服了我，态度特别的有耐心，根据我家装修风格推荐了合适的款式，然后测量完价格都标明了出处，我买的很放心，到货也有师傅上门安装，，每一步都安排的仔仔细细，给大家看看安装好的图片，太爱了',
  '窗帘和纱帘都非常美，效果完全超出我的预期。窗帘的面料很厚实，垂感很棒，做工也挺精细的。特别表扬上门安装师傅，服务太细心了，细节处理一切都刚刚好。卧室要是换窗帘肯定来你家~',
  '这家窗帘质量相当不错，服务也非常好，跟客服沟通好几次最后决定就在这家做了，客服反复跟我们确认每个房间的不同布料还有颜色，已保证没有差错，真的是给我非常好的体验。窗帘现在已经安装好了，准确无误，非常满意。',
  '客服服务的很好，响应很及时解决问题的速度也很快，刚收到货就帮我联系师傅上门安装了。十分也挺守时的，安装的动作迅速毫不墨迹，这一点是我最喜欢的。窗帘做了高温定型很整齐看上去十分的解压，窗帘挂上去后的效果很棒。各方面都做的不错，买的很值。',
  '从选样和客服沟通，再到师傅上门测量安装都流程都很顺利。窗帘收到也很惊艳，做了高温定型过的窗帘，挂上后褶皱层次感很分明，很整齐。值得信赖的商家。',

];
//三千金
const comom3 = [
  '窗帘窗纱质量非常好，轨道够厚，对得起这份价钱，而且可以匹配到我家墙的颜色。墨墨管家的师傅服务态度好，工艺精湛，客服小哥沟通能力强，很有耐心，各种问题解答的也非常到位，下次还会继续光顾。是一次非常愉快的购物经历',
  '这个窗帘安装起来的效果都远远的超出了我的预期，纯色的窗帘也很耐看，不会花里胡哨的，面料的手感摸起来很柔顺，高级感十足，垂顺不易皱，挂起来很有档次，褶皱也做的非常的好，整体非常满意',
  '非常满意的一款窗帘，不管是做工还是材质 都出乎预料的好！走线这些细节做的很精致，一眼就看中这个颜色的窗帘，收到的实物太美了，是我想要的感觉，和我的家具搭配很好看，挂上高级感十足',
  '我本人很喜欢，不管是款式和还是做工质量都达到了我的要求，欣慰的是没有任何别的味道的，材质细腻柔软，对比了样布后也没有发现色差，垂感度很好，挂起来就直了，是一款不错的窗帘，而且店家的服务非常敬业，一站式安排到位，爱了',
  '这个色简直是百搭啊，窗帘也不例外。挂起来后，与我家墙纸的样色很和谐。甚至将整个房间的格调都提升了。窗帘的质量也是好的没话说，仔细检查了一遍，没啥线头的都很工整！买她家的窗帘，我很放心',
  '色调是今年真的特别流行的，好几个姐妹家里换窗帘都是选了这个颜色，我也跟了一下风，真的很喜欢，遮光性也是特别的好，白天想睡觉的时候把窗帘一拉起来整个房间就特别暗，真心不错',
  '在实体店看了很多家，都没有心仪的，朋友推荐我来这家店看看，我也是第一次在网上买窗帘，什么都不懂，大概问了很多问题，客服都有在耐心的解答，还挺不错的，而且还有师傅上门测量、安装，给我省了不少事情，窗帘安装好后看着确实不错，档次很高！',
  '窗帘的做工很好，很有垂坠感。配件也很齐全，拿回来直接就可以挂上。最重要的是颜色看起来很高大上。整体来说这个窗帘不管是从质量质感还是做工都非常的棒。下次如果有需要还会来买。',
  '帘子的款式简单耐看，属于那种不过时型的，用手摸起来很柔顺，质量方面也不错，帘子的遮光性很好，这下可以安安心心睡个好觉啦~这个面料柔软厚实，锁边整齐结实，整体很不错 ！',
  '从测量、和设计师还有客服沟通、看小样、收货安装～一站式服务，都特别满意！关键花了最少的钱，装出了大价格的效果！感恩客服的耐心，安装和测量师傅干活利索，线下对比了好多家，还是这家店性价比高，效果一流，爱了爱了！',
  '已经第二次回购啦～家里所有窗帘都在这定做的，客服很耐心给核对尺寸并根据家里自测的尺寸给出建议，物流速度也很快，虽然发货稍迟了一点但是跟客服沟通过后帮换了快递 准时到达了～安装师傅也很尽职负责，很晚了还加班过来帮忙安装～',
  '挂上去特别有质感，遮光效果也很不错，而且家人也非常喜欢说我眼光太好了，挂上很显高档，和自己的装修风格非常搭，超值的一次购物，感觉美美哒，推荐大家购买！',
  '收到当天就装上了，好的不得了，漂亮大气没味道，料子摸着很有质感，样式也不错，颜色也跟样布上看到的没色差。卖家也挺细致的，仔细询问具体尺寸，提供建议，买回来装上刚刚好。',
  '啥神仙窗帘啊！面料厚实颜色也很正，包装的蛮牢固的哈，没有破损。拆开后没有异味，洗完以后马上就挂上了，窗帘加工也很不错，细节方面处理的挺好的，客服非常耐心，之前和他沟通，反复的和我确定尺寸，这个尺寸都刚刚好，跟我家的风格很搭',
  '窗帘收到了！先拍了小样，感觉还不错，是我喜欢的颜色，面料很温柔也挺阔，遮光效果也不错。实物颜色和店家的拍的一样，我用手机没拍出来那个效果，好清新好温柔的颜色！正好和家里的风格能搭配起来！喜欢！',
  '头一次在网上购买窗帘，没想到效果还不错，颜色是客服帮忙搭配的，和房间搭配起来真的很好看，让房间的档次也提高了不少，质量很好，面料摸起来很厚实，很喜欢，还会购买的',
  '这款窗帘真是感觉永远都不腻的感觉，整体非常的治愈，窗帘的质量也是绝佳，而且价格也不高，真的是太绝了，窗帘尺寸也是一点都没有出错，挂起来很有垂感，颜色非常的高级，物超所值，非常满意',
  '挺好的，挂上窗帘就算是中午的大太阳也不怕晒了，遮光又遮阳，窗帘挂上后也是我想要的效果。布料质量也不错，颜色正，手感厚重舒服，做工走线都很好的，尺寸也是正好合适，整体性价比很高的产品了，值得推荐',
  '这款窗帘挂上后，家里的颜值瞬间高了一大截，褶皱做的很好，远远看去有一种自然美。买之前担心布料会有异味，结果是我多虑了，用着很放心！',
  '这家的窗帘我个人觉得是用料很走心，走线很工整，颜色看着也很舒服，家里基本上都是在这买的，都挺好看的，简单大方，很符合我的心意。',
  '这个窗帘现在是越看越真的是太好看了，材质不用说，，整个窗帘的走线和做工都特别细致！客服反馈的尺寸和建议也很专业准确。垂坠感很到位，遮光度也是达到预期了的，难得遇到这样好的布料和精细的做工，简直太幸运了',
  '窗帘颜色很高级，挂在家里满满的轻奢感，面料很厚实，遮光效果也很不错的，窗帘垂感也很棒，如果你们对品质有要求的话，选择他们家的窗帘真心不错，版型颜色都超级正，窗帘看着非常有质感',
  '挂好后就坐在对面傻傻地欣赏了！真的太喜欢了！颜色比图片上的还漂亮，颜色更柔和！布料手感也特别好，高档轻奢！颜色质地还有窗帘挂上后给人的感觉都是最好的！给了卖家高度尺寸后卖家又电话联系确认，非常负责任！',
  '比别家贵一点，但是质感真的好！ 首先我要求的遮光性真的很好，其次颜色很好看，非常的正，和样布一模一样，挂起来后越看越好看！',
  '窗帘面料厚实、耐用，遮光效果很好，安装上睡眠效果都好了。窗帘算得上是家里必不可少的东西，因为也是要长期使用，在选择上就更用心一点。实体店和网上都对比过，觉得他家最满意，不是那种面料色差很大的产品，收到货也没让我失望，推荐的',
  '新房子装修，我都是货比三家，细心挑选。事实证明我的选择是对的。买的几个都装上了，这个窗帘挡光效果不错，颜色也很搭，整体出来的效果超有氛围感',
  '很满意！！！网上看了很久，买了四家小样回来对比，最终选了这家！！！到手实物果然美腻！手机拍不出它的美！还有幻影纱确实透光不透人，隐私性好！配的挂钩也足够！还免费给我每个窗帘配了同款窗帘绑带，加鸡腿！',
  '窗帘的做工很棒，包边走线都没有问题， 面料闻着也没有异味，品质很不错，颜色也很正，和我看小样的时候是一致的，挂在家里档次很高，推荐',
  '速度很快，安装测量的师傅速度快到离谱，客服全程沟通无障碍，中间有些瑕疵及时发现顺利解决了，总之没比线下麻烦，还是很省心省事儿的，窗帘品质和做工都不错，家里人难得夸我办事得力，期待下次合作.',
];
//寻家
const comom4 = [
  '寻家窗帘的服务很到位，客服小姐姐很专业，人也很好。后续都安排了师傅来上门测量和安装，整个购买流程都很让人舒心，大品牌的服务确实比别家的好很多',
  '在寻家买窗帘真的是一种享受，快递送货上门，测量和安装都有师傅，最后窗帘也很好看，购物体验很好。',
  '窗帘是挺好的，面料厚实柔软，安装上去非常垂，效果很不错，比在家居城那里的好',
  '窗帘挂上啦，颜色真的很好看，家里人都挺喜欢的，很耐看。选的这个遮光效果也特别的棒，关上后根本不透光，满意',
  '窗帘做工好，走线整齐，尺寸标准，客服态度很好，是一次满意的购物，我准备再买几幅窗帘，家里面都给装满上',
  '窗帘挂起来不仅遮光，还挺洋气，也没什么味道，想买窗帘可以考虑寻家，真的不错',
  '窗帘装上啦，家里的装修档次都提升了不少，师傅的技术也很棒，真的很满意！',
  '在网上和这边的家居店找了好几家样布对比，最终还是敲定寻家，定制的全屋窗帘，安装效果非常好，比我预想的好太多了，客服、师傅、安装效果各方面都特别好，真的满意！',
  '窗帘很棒，遮光效果很好，比我之前那幅窗帘好得多。必须好评',
  '窗帘遮光效果非常的好，拉上屋子就暗下来了，有遮光需求但是又想要颜值的，建议跟我一样加个遮光布更好',
  '看到抗菌才买的，收到后也发现这个窗帘品质确实好，闻着也没味道，很放心',
  '窗帘今天刚刚挂上，效果10分满意给9.9分吧，比我买过的都好的多，整体是非常满意的，这0.1分是希望寻家越来越好，继续朝着完美的地方更近一步。',
  '客服推荐的这个窗帘和我的装修风格真的好搭，整体看着特别很和谐，很上档次的感觉，价格稍微贵了一些，但是质量这一块是没话说的。',
  '窗帘质量非常好，定型效果很板正，看着十分高级，很带劲。比我期待的还要好，有点出乎意料，太感动了，感谢寻家',
  '果然买对了，亲戚朋友们来家里都夸这款窗帘买的值，既美观遮光又好',
  '设计师给我推荐的窗帘颜色搭配得很好，奶霜白加上幻影纱，整体看着就很赏心悦目，家人和朋友们都夸这款窗帘选的对，非常符合我家的整体风格',
  '家里全部装修好，窗帘是最好最有性价比的。店家服务态度也很好，跟他们沟通也很愉快。',
  '寻家的这款云感棉2代窗帘的布料选择真的非常好，触感柔软细腻，光泽度也很好，给人一种高贵又不失温馨的感觉，真的是物有所值',
  '对于寻家窗帘的售后服务，我只能用“赞”来形容。 无论是咨询还是售后，都能得到及时且专业的回应，而且对于我这种不是很懂的人来说回答得也很通俗易懂，很有耐心，真的被寻家的服务所折服了。安装后的效果也是非常的让我满意，大品牌的服务和品质确实很棒',
  '这款窗帘真的非常的满意，质量好，厚实，安装上去质感很好。不愧是tb数一数二的店，真物有所值',
];

export { comom, comom1, comom2, comom3, comom4, };
