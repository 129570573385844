import request from '../utils/request';
export async function getFeeds() {
  return await request('platbiz/reasonlist', 'get');
}

export async function putFeedbackList(data) {
  return await request('shopbiz/feedbacklist', 'put', data);
}
export async function postFeedback(data) {
  return await request('shopbiz/addfeedback', 'post', data);
}



export async function getFeedback(id) {
  return await request(`platbiz/feedback?id=${id}`, 'get');
}
export async function mdyFeedback(data) {
  return await request('platbiz/mdyfeedback', 'post', data);
}

export async function putFeedback(data) {
  return await request('platbiz/feedback', 'put', data);
}

