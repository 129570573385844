import React, { Component } from 'react';
import { Button, Input, message, Radio, Table, Upload, Popconfirm, Checkbox, } from 'antd'
import { PlusCircleOutlined, EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import styles from './index.module.css'
import Regin from '../../components/region';
import convert from '../../utils/convert';
import { ossUpload, } from '../../utils/ossUpload.js';
import { getDepartment_Data } from '../../config/storage';
import { getVideoInfo, getVideoPlay, getVideoSub } from '../../services/newOder';
import { getBasedata, putBasedata, getShopVideos, putShopVideos } from '../../services/storeManagement';
import IndexDrawer from './indexDrawer';

const init = {
  address: '',
  city: '',
  district: '',
  id: 0,
  name: '',
  phone: '',
  province: '',
  users: [],
  weixin: '',
}
const inits = {
  avatar: '',
  id: 0,
  enable: '是',
  name: '',
  phone: '',
  role: '会员',
  uid: '',
  weixin: '',
}
const videoModel = {
  measure: {
    pics: [],
    vod: [],
  },
  install: {
    pics: [],
    vod: [],
  },
};

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accountType: '是',
      data: { ...init },
      shopInfo: { shopId: 0 },
      videos: [],

      measureVideo: {
        pics: [],
        vod: [],
      },
      installVideo: {
        pics: [],
        vod: [],
      },
    };
    this.childRef = React.createRef();
    this.baseUrl = getDepartment_Data().service;
    this.columns = [
      {
        title: '姓名',
        align: 'center',
        dataIndex: 'name',
        width: 100,
      },
      {
        title: '账号',
        align: 'center',
        dataIndex: 'uid',
        width: 100,
      },
      {
        title: '手机号',
        align: 'center',
        dataIndex: 'phone',
        width: 100,
      },
      {
        title: '微信',
        align: 'center',
        dataIndex: 'weixin',
        width: 100,
      },
      {
        title: '用户角色',
        align: 'center',
        dataIndex: 'role',
        width: 100,
      },
      // {
      //     title: '备注信息',
      //     align: 'center',
      //     dataIndex: 'state',
      //     width: 100,
      // },
      {
        title: '操作',
        align: 'center',
        width: 100,
        render: (text, record, index) => {
          return (
            <div className='font-underline cursor-pointer color3' onClick={this.onDrawer.bind(this, record)}>
              编辑
            </div>
          )
        }
      }
    ];
  }
  componentDidMount() {
    this.getInfoData();
    let shopInfo = getDepartment_Data();
    this.setState({
      shopInfo
    }, () => this.getVideos());
  }
  render() {
    const { accountType, data, videos, measureVideo, installVideo, } = this.state;
    return (
      <div>
        <div className='w100 padding20 back-color0 col vertical-lt'>
          <div>
            <div className='row-sb'>
              <div className='row-l'>
                <div className={styles['title-style']}>商户信息</div>
                <div className={styles['title-tips-style']}>(不可修改; 如需修改, 请联系墨墨管家客服,感谢您的理解)</div>
              </div>
              <div className={styles['preservation']} onClick={this.preservation}>保存</div>
            </div>
            <div className='row-l margin-t20 margin-b20'>
              <div className='row-l margin-l20'>
                <div className='margin-l20 margin-r10 w60 tr'>商户名称:</div>
                <Input disabled className='w295' value={data.name} onChange={this.putInput.bind(this, 'name')} />
              </div>
              <div className='row-l margin-l20'>
                <div className='margin-r10 w60 tr'>商户地址:</div>
                {/* <Input className='w295' value={data.province + data.city + data.district}/> */}
                <Regin value={[data.province, data.city, data.district]} onChange={this.onReginChange.bind()} />
              </div>
              <div className='row-l margin-l20'>
                <div className='margin-r10 w60 tr'>详细地址:</div>
                <Input className='w590' value={data.address} onChange={this.putInput.bind(this, 'address')} />
              </div>
            </div>
          </div>
          <div>
            <div className={styles['title-style']}>联系方式</div>
            <div className='row-l margin-t20 margin-b20'>
              <div className='row-l margin-l20'>
                <div className='margin-l20 margin-r10 w60 tr'>手机号:</div>
                <Input className='w295' value={data.phone} onChange={this.putInput.bind(this, 'phone')} />
              </div>
              {/* <div className='row-l margin-l20'>
                              <div className='margin-r10 w60 tr'>QQ号码:</div>
                              <Input className='w295'/>
                          </div> */}
              <div className='row-l margin-l20'>
                <div className='margin-r10 w60 tr'>微信号码:</div>
                <Input className='w295' value={data.weixin} onChange={this.putInput.bind(this, 'weixin')} />
              </div>
              {/* <div className='row-l margin-l20'>
                              <div className='margin-r10 w60 tr'>旺旺号:</div>
                              <Input className='w295'/>
                          </div> */}
            </div>
          </div>
        </div>
        <div className='w100 padding20 back-color0 margin-t10'>
          <div className='row-sb'>
            <div className={styles['title-style']}>模板</div>
            <div className={`${styles['preservation']} cursor-pointer`} onClick={this.saveVideo}>保存</div>
          </div>
          {/* 测量 */}
          <div className='row-l margin-t10 margin-b5'>
            <div className='fz18 bold margin-r20'>测量图片：</div>
            <Upload
              type='file'
              beforeUpload={this.onAddPic}
              fileList={[]}
              accept='image/*'
              multiple={true}
            >
              <Button type='primary'>新增</Button>
            </Upload>
          </div>
          <div className='row-w w100 margin-l10 '>
            {
              measureVideo.pics.map((item, index) => {
                return (
                  <div className={`${styles['video-box']} col-t vertical-lt`} key={index}>
                    <div className='row-sb margin-b5'>
                      <div className='bold w60'>名称：</div>
                      <Input value={item.name} className='w160' onChange={this.changeTempete.bind(this, index, 'pics', 'name')} />
                    </div>
                    <div className='row-l'>
                      <div key={index} className={`${styles['content']}`}>
                        <img
                          alt="暂无图片"
                          className={`${styles['pic']}`}
                          src={`${this.baseUrl}/upload/${item.url}`}
                        />
                        <div className={`row ${styles['content-div']} cursor-pointer`}>
                          <EyeOutlined
                            className={`${styles['pic-icon-E']}`}
                            // onClick={this.preview.bind(this, `${this.baseUrl}/upload/${item}`)}
                            theme="outlined"
                            title="预览"
                          />
                        </div>
                      </div>
                      <div className='col'>
                        <Popconfirm
                          title="您确定要删除该图片模板吗?"
                          onConfirm={this.delVideo.bind(this, index, 'pics')}
                          okText="确认"
                          cancelText="取消"
                        >
                          <Button type='danger' className='margin-b10'>删除模板</Button>
                        </Popconfirm>
                        <Checkbox checked={item.selected} onChange={this.changeTempete.bind(this, index, 'pics', 'selected')}>默认</Checkbox>

                      </div>
                    </div>

                  </div>
                );
              })
            }

          </div>
          <div className='margin-t10'>
            <div className='row-l margin-t10 margin-b5'>
              <div className='fz18 bold margin-r20'>测量视频：</div>
              <Upload
                type='file'
                beforeUpload={this.uploadVideo}
                customRequest={() => { }}
                fileList={[]}
                multiple={false}
                maxCount={1}

              >
                <Button type='primary'>新增测量视频</Button>
              </Upload>
            </div>
          </div>
          <div className='row-w margin-t10'>
            {
              measureVideo.vod.map((item, index) => {
                return (
                  <div className={`${styles['video-box']} col-t vertical-lt`} key={index}>
                    <div className='row-sb margin-b5'>
                      <div className='bold w60'>名称：</div>
                      <Input value={item.name} className='w200' onChange={this.changeTempete.bind(this, index, 'vod', 'name')} />
                    </div>
                    <div className={`${styles['content-video']}`}>
                      <video controls='controls' className={styles['content-image']} src={item.videoUrl} />
                    </div>
                    <div className='row w100 margin-t5'>
                      <Popconfirm
                        title="您确定要删除该视频模板吗?"
                        onConfirm={this.delVideo.bind(this, index, 'vod')}
                        okText="确认"
                        cancelText="取消"
                      >
                        <Button type='danger' className='w50 margin-r20'>删除模板</Button>
                      </Popconfirm>
                      <Checkbox checked={item.selected} onChange={this.changeTempete.bind(this, index, 'vod', 'selected')}>默认</Checkbox>

                    </div>
                  </div>
                );
              })
            }
          </div>
          {/* 安装 */}
          <div className='row-l margin-t10 margin-b5'>
            <div className='fz18 bold margin-r20'>安装图片：</div>
            <Upload
              type='file'
              beforeUpload={this.onAddPic2}
              fileList={[]}
              accept='image/*'
              multiple={true}
            >
              <Button type='primary'>新增</Button>
            </Upload>
          </div>
          <div className='row-w w100 margin-l10 '>
            {
              installVideo.pics.map((item, index) => {
                return (
                  <div className={`${styles['video-box']} col-t vertical-lt`} key={index}>
                    <div className='row-sb margin-b5'>
                      <div className='bold w60'>名称：</div>
                      <Input value={item.name} className='w160' onChange={this.changeTempete2.bind(this, index, 'pics', 'name')} />
                    </div>
                    <div className='row-l'>
                      <div key={index} className={`${styles['content']}`}>
                        <img
                          alt="暂无图片"
                          className={`${styles['pic']}`}
                          src={`${this.baseUrl}/upload/${item.url}`}
                        />
                        <div className={`row ${styles['content-div']} cursor-pointer`}>
                          <EyeOutlined
                            className={`${styles['pic-icon-E']}`}
                            // onClick={this.preview.bind(this, `${this.baseUrl}/upload/${item}`)}
                            theme="outlined"
                            title="预览"
                          />
                        </div>
                      </div>
                      <div className='col'>
                        <Popconfirm
                          title="您确定要删除该图片模板吗?"
                          onConfirm={this.delVideo2.bind(this, index, 'pics')}
                          okText="确认"
                          cancelText="取消"
                        >
                          <Button type='danger' className='margin-b10'>删除模板</Button>
                        </Popconfirm>
                        <Checkbox checked={item.selected} onChange={this.changeTempete2.bind(this, index, 'pics', 'selected')}>默认</Checkbox>

                      </div>
                    </div>

                  </div>
                );
              })
            }

          </div>
          <div className='margin-t10 '>
            <div className='row-l margin-t10 margin-b5'>
              <div className='fz18 bold margin-r20'>安装视频：</div>
              <Upload
                type='file'
                beforeUpload={this.uploadVideo2}
                customRequest={() => { }}
                fileList={[]}
                multiple={false}
                maxCount={1}
              >
                <Button type='primary'>新增安装视频</Button>
              </Upload>

            </div>
          </div>
          <div className='row-w margin-t10 margin-l10'>
            {
              installVideo.vod.map((item, index) => {
                return (
                  <div className={`${styles['video-box']} col-t vertical-lt`} key={index}>
                    <div className='row-sb margin-b5'>
                      <div className='bold w60'>名称：</div>
                      <Input value={item.name} className='w200' onChange={this.changeTempete2.bind(this, index, 'vod', 'name')} />
                    </div>
                    <div className={`${styles['content-video']}`}>
                      <video controls='controls' className={styles['content-image']} src={item.videoUrl} />
                    </div>
                    <div className='row w100 margin-t5'>
                      <Popconfirm
                        title="您确定要删除该视频模板吗?"
                        onConfirm={this.delVideo2.bind(this, index, 'vod')}
                        okText="确认"
                        cancelText="取消"
                      >
                        <Button type='danger' className='w50 margin-r20'>删除模板</Button>
                      </Popconfirm>
                      <Checkbox checked={item.selected} onChange={this.changeTempete2.bind(this, index, 'vod', 'selected')}>默认</Checkbox>

                    </div>

                  </div>
                );
              })
            }
          </div>
        </div>
        <div className='w100 padding20 back-color0 margin-t10'>
          <div className={styles['title-style']}>员工账户</div>
          <div className='row-l margin-l20 margin-t10'>
            <div className='margin-l20 margin-r10'>账户状态:</div>
            <Radio.Group onChange={this.onChangeRadio} value={accountType}>
              <Radio value={'是'}>启用</Radio>
              <Radio value={'否'}>停用</Radio>
            </Radio.Group>
          </div>
          <div className={`${styles['add-button']} margin-b20`} onClick={this.onDrawer.bind(this, inits)}> <PlusCircleOutlined /> 新增员工账户</div>
          <Table
            columns={this.columns}
            dataSource={data.users.filter(r => r.enable === accountType)}
            size={'small'}
            rowKey={record => record.id}
            bordered
            pagination={{ defaultPageSize: 15 }}
          />
          <IndexDrawer
            ref={this.childRef}
            updateTable={this.updateTable}
          />
        </div>
      </div >
    );
  }
  //抽屉
  onDrawer = (data) => {
    this.childRef.current.showModal(data);
  }
  updateTable = (row) => {
    let newData = { ...this.state.data };
    let users = [...newData.users]
    let index = users.findIndex(r => r.id === row.id)
    if (index > -1) {
      users[index] = row;
    } else {
      users.push(row);
    }
    newData.users = users;
    this.setState({
      data: newData
    })
  }
  //保存
  preservation = async () => {
    let res = await putBasedata(this.state.data);
    if (res.status !== 204) {
      message.error(res.data);
      return;
    }
    message.success('保存成功')
  }

  //输入框
  putInput = (type, e) => {
    let newData = { ...this.state.data };
    newData[type] = e.target.value;
    this.setState({
      data: newData
    })
  }
  //省市区
  onReginChange = (e) => {
    let newData = { ...this.state.data };
    newData.province = e[0];
    newData.city = e[1];
    newData.district = e[2];
    this.setState({
      data: newData
    })
  }
  //账户状态
  onChangeRadio = (e) => {
    this.setState({
      accountType: e.target.value
    })
  }
  //店铺数据
  getInfoData = async () => {
    let res = await getBasedata();
    if (res.status !== 200) {
      message.error(res.data);
      return
    }
    this.setState({
      data: res.data
    })
  }

  //测量
  onAddPic = async (file, fileList) => {
    let measureVideo = _.cloneDeep(this.state.measureVideo)
    if (fileList[fileList.length - 1] !== file) {
      return;
    }
    let leng = measureVideo.pics.length;
    for (let i = 0; i < fileList.length; i++) {
      let name = await ossUpload(fileList[i], null);
      measureVideo.pics[leng + i] = {
        name: '测量图片',
        url: name,
        selected: true,
      };
    }
    this.setState({
      measureVideo
    })
  }
  changeTempete = (index, category, type, e) => {
    let measureVideo = _.cloneDeep(this.state.measureVideo);
    if (type === 'selected') {
      measureVideo[category][index][type] = e.target.checked;
    } else {
      measureVideo[category][index][type] = e.target.value ? e.target.value : '';
    }
    this.setState({
      measureVideo
    })
  }
  delVideo = (index, type) => {
    let measureVideo = _.cloneDeep(this.state.measureVideo);
    measureVideo[type].splice(index, 1)
    this.setState({
      measureVideo
    })
  }
  uploadVideo = async (file, fileList) => {
    if (fileList[fileList.length - 1] !== file) {
      return;
    }
    if (fileList.length > 1) {
      message.warn('只能上传单个视频');
      return;
    }
    let decimal = (new Date()).valueOf();
    let name = `${convert.toString36(decimal)}`.replace(/:/g, '-');
    let res = await getVideoInfo(name, file.name);
    let uploadAddress = JSON.parse(decodeURI(atob(res.data.uploadAddress)));
    let uploadInfos = {
      video: res.data.videoId,
      Endpoint: uploadAddress.Endpoint,
      Bucket: uploadAddress.Bucket,
      object: null,
    };
    let that = this;
    this.uploader = new AliyunUpload.Vod({
      userId: '112',
      region: 'cn-shanghai',
      partSize: 1048576,
      parallel: 5,
      retryCount: 3,
      retryDuration: 2,
      'onUploadstarted': async (uploadInfo) => {
        this.uploader.setUploadAuthAndAddress(uploadInfo, res.data.uploadAuth, res.data.uploadAddress, res.data.videoId);
      },
      'onUploadEnd': async function (uploadInfo) {
        let rsp = await getVideoSub(res.data.videoId);
        if (rsp.status === 204) {
          that.getVideoUrl(res.data.videoId);
        } else {
          message.error(rsp.data);
        }
      }
    });
    this.uploader.addFile(file, uploadInfos.Endpoint, uploadInfos.Bucket, null, '{"Vod":{}}');
    this.uploader.startUpload();
  }
  getVideoUrl = async (e) => {
    let res = await getVideoPlay(e);
    if (res.status === 200) {
      let measureVideo = _.cloneDeep(this.state.measureVideo);
      measureVideo.vod.push({
        name: '新模板',
        videoId: e,
        videoUrl: res.data.playURL,
        selected: true,
      });
      this.setState({
        measureVideo
      })
    }
  }
  //安装
  onAddPic2 = async (file, fileList) => {
    let installVideo = _.cloneDeep(this.state.installVideo)
    if (fileList[fileList.length - 1] !== file) {
      return;
    }
    let leng = installVideo.pics.length;
    for (let i = 0; i < fileList.length; i++) {
      let name = await ossUpload(fileList[i], null);
      installVideo.pics[leng + i] = {
        name: '安装图片',
        url: name,
        selected: true,
      };
    }
    this.setState({
      installVideo
    })
  }
  changeTempete2 = (index, category, type, e) => {
    let installVideo = _.cloneDeep(this.state.installVideo);
    if (type === 'selected') {
      installVideo[category][index][type] = e.target.checked;
    } else {
      installVideo[category][index][type] = e.target.value ? e.target.value : '';
    }
    this.setState({
      installVideo
    })
  }
  delVideo2 = (index, type) => {
    let installVideo = _.cloneDeep(this.state.installVideo);
    installVideo[type].splice(index, 1)
    this.setState({
      installVideo
    })
  }
  uploadVideo2 = async (file, fileList) => {
    if (fileList[fileList.length - 1] !== file) {
      return;
    }
    if (fileList.length > 1) {
      message.warn('只能上传单个视频');
      return;
    }
    let decimal = (new Date()).valueOf();
    let name = `${convert.toString36(decimal)}`.replace(/:/g, '-');
    let res = await getVideoInfo(name, file.name);
    let uploadAddress = JSON.parse(decodeURI(atob(res.data.uploadAddress)));
    let uploadInfos = {
      video: res.data.videoId,
      Endpoint: uploadAddress.Endpoint,
      Bucket: uploadAddress.Bucket,
      object: null,
    };
    let that = this;
    this.uploader = new AliyunUpload.Vod({
      userId: '112',
      region: 'cn-shanghai',
      partSize: 1048576,
      parallel: 5,
      retryCount: 3,
      retryDuration: 2,
      'onUploadstarted': async (uploadInfo) => {
        this.uploader.setUploadAuthAndAddress(uploadInfo, res.data.uploadAuth, res.data.uploadAddress, res.data.videoId);
      },
      'onUploadEnd': async function (uploadInfo) {
        let rsp = await getVideoSub(res.data.videoId);
        if (rsp.status === 204) {
          that.getVideoUrl2(res.data.videoId);
        } else {
          message.error(rsp.data);
        }
      }
    });
    this.uploader.addFile(file, uploadInfos.Endpoint, uploadInfos.Bucket, null, '{"Vod":{}}');
    this.uploader.startUpload();
  }
  getVideoUrl2 = async (e) => {
    let res = await getVideoPlay(e);
    if (res.status === 200) {
      let installVideo = _.cloneDeep(this.state.installVideo);
      installVideo.vod.push({
        name: '新模板',
        videoId: e,
        videoUrl: res.data.playURL,
        selected: true,
      });
      this.setState({
        installVideo
      })
    }
  }

  getVideos = async (e) => {
    let res = await getShopVideos(this.state.shopInfo.shopId);
    if (res.status !== 200) {
      message.error(res.data);
      return
    } else {
      let videos = _.cloneDeep(videoModel)
      let installVideo = {};
      let measureVideo = {};
      if (res.data && JSON.parse(res.data)) {
        videos = JSON.parse(res.data);
      }
      installVideo = videos.install;
      measureVideo = videos.measure;
      let that = this;
      if (installVideo.vod.length > 0) {
        installVideo.vod.map((item, index) => {
          (async () => {
            let locate = index;
            let rsp = await getVideoPlay(item.videoId);
            if (rsp.status === 200) {
              installVideo.vod[locate].videoUrl = rsp.data.playURL
              that.setState({
                installVideo
              });
            }
          })()
        })
      }
      if (measureVideo.vod.length > 0) {
        measureVideo.vod.map((item, index) => {
          (async () => {
            let locate = index;
            let rsp = await getVideoPlay(item.videoId);
            if (rsp.status === 200) {
              measureVideo.vod[locate].videoUrl = rsp.data.playURL
              that.setState({
                measureVideo
              });
            }
          })()
        })
      }
      this.setState({
        installVideo, measureVideo,
      })
    }
  }
  saveVideo = async () => {
    const { shopInfo, installVideo, measureVideo } = this.state;
    let data = _.cloneDeep(videoModel);
    data.measure = measureVideo;
    data.install = installVideo;
    data.measure.vod = measureVideo.vod.map(r => {
      let single = {
        name: r.name,
        videoId: r.videoId,
        selected: r.selected,
      };
      if (r.name === '') {
        check = false;
      }
      return single;
    });
    data.install.vod = installVideo.vod.map(r => {
      let single = {
        name: r.name,
        videoId: r.videoId,
        selected: r.selected,
      };
      if (r.name === '') {
        check = false;
      }
      return single;
    });
    let errors1 = measureVideo.pics.filter(r => r.name === '');
    let errors2 = measureVideo.vod.filter(r => r.name === '');
    let errors3 = installVideo.pics.filter(r => r.name === '');
    let errors4 = installVideo.vod.filter(r => r.name === '');
    let errrorCount = errors1.concat(errors2, errors3, errors4);
    if (errrorCount.length > 0) {
      message.warn('有名称未填写');
      return;
    }
    data = JSON.stringify(data);
    let res = await putShopVideos(shopInfo.shopId, data);
    if (res.status === 200) {
      message.success('保存成功');
    } else {
      message.error(res.data);
    }
  }
}

export default Index;