import React, { Component } from 'react';
import { Select, Input, DatePicker, Button, Checkbox, Table, Radio, message, Modal, InputNumber, Image, Popconfirm } from 'antd';
import { getShopTransfer } from '../../services/orderTrans';
import { getDepartment_Data } from '../../config/storage';
import IndexModal from '../orderRenters/indexModal';
import PageTurning from '../../components/pageTurning/index';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/zh_CN';
import 'moment/locale/zh-cn';

const dateFormat = 'YYYY/MM/DD-HH:mm'
const dateFormat2 = 'YYYY/MM/DD'

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transferSearch: {
        state: '已转化',
        type: '下单',
        start: moment().subtract(1, 'month'),
        end: moment(),
        skip: 0,
        take: 10,
        total: 0,
        trans: 0,
        rate: 0,
        info: '',
      },
    };
    this.shopInfo = getDepartment_Data();
    this.pageModalTrans = React.createRef();
    this.indexModal = React.createRef();
    this.columnsTransfer = [

      {
        title: '订单信息',
        align: 'center',
        width: 230,
        dataIndex: 'orderInfo',
        key: 'orderInfo',
        render: (text, record, index) => {
          return (
            // <div>{record.service}-{record.type}-{record.state}</div>
            <div>
              <div>{record.id}-{record.category}-{record.service}-{record.type}
                【{record.state}】
              </div>
              <div>下单时间：{moment(record.create_time).format('YYYY/MM/DD-HH:mm:ss')}</div>
            </div>
          );
        }
      },
      {
        title: '测量订单编号',
        align: 'center',
        width: 100,
        dataIndex: 'id',
        key: 'id',
        render: (text, record, index) => {
          return (
            <div>{record.id}</div>
          );
        }
      },
      {
        title: '客户信息',
        align: 'center',
        width: 250,
        dataIndex: 'info',
        key: 'info',
        render: (text, record, index) => {
          return (
            <div>
              <div>{record.name}，{record.phone}，
                {record.province}{record.city}{record.district},{record.address}
              </div>
            </div>
          );
        }
      },
      {
        title: '商户费用',
        align: 'center',
        width: 100,
        dataIndex: 'price_app',
        key: 'price_app',
      },
      {
        title: '订单是否已完成',
        align: 'center',
        width: 200,
        dataIndex: 'state',
        render: (text, record, index) => {
          return (
            <div>
              <div>{record.state}</div>
              <div>完成时间：{record.compete_time && record.compete_time !== '1970-01-01T00:00:00' ? moment(record.compete_time).format('YYYY/MM/DD-HH:mm:ss') : ''}</div>
            </div>
          );
        }
      },
      {
        title: '转化状态',
        align: 'center',
        width: 100,
        dataIndex: 'status',
        key: 'status',
        render: (text, record, index) => {
          return (
            <div >{record.order ? '是' : '否'}</div>
          );
        }
      },
      {
        title: '转化时间',
        align: 'center',
        width: 80,
        dataIndex: 'trans_time',
        key: 'trans_time',
        render: (text, record, index) => {
          return (
            <div>{record.trans_time && record.trans_time !== '1970-01-01T00:00:00' && record.trans_time !== '0001-01-01T00:00:00' ? moment(record.trans_time).format('YYYY/MM/DD-HH:mm:ss') : ''}</div>
          );
        }
      },
      {
        title: '转化订单编号',
        align: 'center',
        width: 80,
        dataIndex: 'order',
        key: 'order',
        render: (text, record, index) => {
          return (
            <div onClick={this.toOrderInfo.bind(this, record)} className='color3 font-underline cursor-pointer'>{record.order ? record.order : ''}</div>
          );
        }
      },
      {
        title: '说明',
        align: 'center',
        width: 220,
        dataIndex: 'bonus_remark',
        key: 'bonus_remark',
      },

    ];
  }
  componentDidMount() {
    this.toSearchTransfer();
  }
  render() {
    const { transferSearch, transfetData } = this.state;
    return (
      <div>
        <div className='bar'>
          <div className='row-l margin-t10'>
            <div>转化状态：</div>
            <Radio.Group value={transferSearch.state} onChange={this.changeTransInfo.bind(this, 'state')}>
              {
                ['已转化', '未转化'].map((item, index) => {
                  return (
                    <Radio value={item} key={index}>{item}</Radio>
                  );
                })
              }
            </Radio.Group>
            <div className='margin-l20'>时间类型：</div>
            <Radio.Group value={transferSearch.type} onChange={this.changeTransInfo.bind(this, 'type')}>
              {
                ['下单', '完成', '转化'].map((item, index) => {
                  return (
                    <Radio value={item} key={index}>{item}</Radio>
                  );
                })
              }
            </Radio.Group>
            <div>时间范围：</div>
            <DatePicker locale={locale} onChange={this.changeTransInfo.bind(this, 'start')} allowClear={false}
              value={transferSearch.start} format={dateFormat2} />
            至
            <DatePicker locale={locale} onChange={this.changeTransInfo.bind(this, 'end')} allowClear={false}
              value={transferSearch.end} format={dateFormat2} />
          </div>
          <div className='row-l margin-t10'>
            <div>客户信息：</div>
            <Input value={transferSearch.info} onChange={this.changeTransInfo.bind(this, 'info')}
              className='w200' />
            <Button onClick={this.toSearchTransfer} type='primary' className='margin-r20'>搜索</Button>
            <Button onClick={this.toExcelTransfer} type='primary'>导出Excel</Button>
            <div className='margin-l20 bold fz20'>转化率：{transferSearch.rate}</div>
          </div>
        </div>
        <div className='bar margin-t10'>
          <Table
            columns={this.columnsTransfer}
            dataSource={transfetData}
            size={'small'}
            className='w100 margin-t10'
            rowKey={record => record.id}
            bordered
            pagination={false}
          />
          <PageTurning pageMaps={this.pageMapsTransfer} ref={this.pageModalTrans} />
        </div>
        <IndexModal
          ref={this.indexModal}
        />
      </div>
    );
  }
  changeTransInfo = (type, e) => {
    let transferSearch = { ...this.state.transferSearch };
    switch (type) {
      case 'state':
      case 'type':
      case 'info':
        transferSearch[type] = e.target.value;
        break;
      case 'start':
      case 'end':
        transferSearch[type] = e;
        break;
      default: break;
    }
    this.setState({
      transferSearch
    });
  }
  toSearchTransfer = () => {
    this.pageModalTrans.current.returnFirst();
    let transferSearch = { ...this.state.transferSearch };
    transferSearch.skip = 0;
    this.setState({
      transferSearch
    }, () => this.getTransfer());
  }
  getTransfer = async () => {
    let transferSearch = _.cloneDeep(this.state.transferSearch);
    let start = moment(transferSearch.start).format(dateFormat2);
    let end = moment(transferSearch.end).format(dateFormat2);
    let res = await getShopTransfer(this.shopInfo.shopId, transferSearch.state, transferSearch.type, start, end, transferSearch.skip, transferSearch.take, transferSearch.info);
    if (res.status === 200) {
      transferSearch.total = res.data.total;
      transferSearch.trans = res.data.trans;
      if (transferSearch.total) {
        transferSearch.rate = Number(transferSearch.trans / transferSearch.total * 100).toFixed(1) + '%';
      } else {
        transferSearch.rate = 0;
      }
      this.setState({
        transfetData: res.data.list,
        transferSearch
      })
    }
  }
  pageMapsTransfer = ({ skip, take }) => {
    let transferSearch = { ...this.state.transferSearch };
    transferSearch.skip = skip;
    this.setState({
      transferSearch,
    }, async () => this.getTransfer())
  }
  toOrderInfo = (e) => {
    this.indexModal.current.onShowModal(e, '订单详情');
  }
}
export default Index