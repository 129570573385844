import {
  ContainerOutlined,
  CloudServerOutlined,
  CloudDownloadOutlined,
  CustomerServiceOutlined,
  AccountBookOutlined,
  InsertRowLeftOutlined,
  AppstoreAddOutlined,
  BarChartOutlined,
  MenuUnfoldOutlined,
  ClusterOutlined,
} from '@ant-design/icons';
import Workbench from '../../routes/workbench/workbench';
import OrderRenter from '../../routes/orderRenters';
import NewOrder from '../../routes/newOrder';
import MeasureData from '../../routes/measureData';
import AfterSale from '../../routes/afterSale';
import Finance from '../../routes/finance';
import StoreManagement from '../../routes/storeManagement';
import ServiceArea from '../../routes/serviceArea';
import ProductOrder from '../../routes/productOrder/productOrder';
import Bonus from '../../routes/orderRenters/bonusList';
import OrderTrans from '../../routes/orderTrans/index';
import RodrailShop from '../../routes/rodrailShop/index';
import Feedback from '../../routes/feedback/index';
export const assembly = [
  {
    path: 'workbench',
    name: '工作台',
    icon: <MenuUnfoldOutlined />
  },
  {
    path: 'newOrder',
    name: '新建订单',
    icon: <CloudServerOutlined />
  },
  {
    path: 'orderRenter',
    name: '订单中心',
    icon: <ContainerOutlined />,
  },
  {
    path: 'bonus',
    name: '好评订单',
    icon: <ContainerOutlined />,
  },
  {
    path: 'rodrail',
    name: '杆轨商城',
    icon: <ClusterOutlined />,
    childRoutes: [
      {
        path: 'productOrder',
        name: '杆轨订单',
        // icon: <ContainerOutlined />,
      },
      {
        path: 'rodrailShop',
        name: '选品广场',
        // icon: <AppstoreAddOutlined />,
      },
    ],
  },

  {
    path: 'measureData',
    name: '测量数据',
    icon: <CloudDownloadOutlined />
  },
  {
    path: 'afterSale',
    name: '售后列表',
    icon: <CustomerServiceOutlined />
  },
  {
    path: 'feedback',
    name: '反馈列表',
    icon: <CustomerServiceOutlined />
  },
  {
    path: 'orderTrans',
    name: '订单转化',
    icon: <BarChartOutlined />,
  },
  {
    path: 'finance',
    name: '财务中心',
    icon: <AccountBookOutlined />
  },
  {
    path: 'storeManagement',
    name: '店铺管理',
    icon: <InsertRowLeftOutlined />
  },
  // {
  //   path: 'serviceArea',
  //   name: '服务区域',
  //   icon: <SendOutlined />
  // },
];

export const getChildren = (type, fn) => {
  switch (type) {
    case '工作台':
      return <Workbench />
    case '订单中心':
      return <OrderRenter fun={fn} />
    case '好评订单':
      return <Bonus />
    case '新建订单':
      return <NewOrder fun={fn} />
    case '杆轨订单':
      return <ProductOrder />
    case '选品广场':
      return <RodrailShop />
    case '测量数据':
      return <MeasureData />
    case '售后列表':
      return <AfterSale />
    case '反馈列表':
      return <Feedback />
    case '订单转化':
      return <OrderTrans />
    case '财务中心':
      return <Finance />
    case '店铺管理':
      return <StoreManagement />
    case '服务区域':
      return <ServiceArea />
  }

}