import React from 'react';
import { Router, Redirect, Switch, Route } from 'dva/router';
import { renderRouteConfig } from '../utils/createRoot';
import Login from '../routes/login/login';
import BasicLayout from '../layouts/BasicLayout';
import { getSession } from './storage';
import OrderRenter from '../routes/orderRenters';
import NewOrder from '../routes/newOrder';
import MeasureData from '../routes/measureData';
import AfterSale from '../routes/afterSale';
import Finance from '../routes/finance';
import StoreManagement from '../routes/storeManagement';
import ServiceArea from '../routes/serviceArea';
import PaySuccess from '../routes/paySuccess/index';
import Download from '../routes/download/download';
import FastRemark from '../routes/fastRemark/fastRemark';
import DirectOrder from '../routes/directOrder/directOrder';
import OrderInfo from '../routes/orderInfo';
import OrderTrans from '../routes/orderTrans/index';
import _ from 'lodash';
import {
  ContainerOutlined,
  CloudServerOutlined,
  CloudDownloadOutlined,
  CustomerServiceOutlined,
  AccountBookOutlined,
  InsertRowLeftOutlined,
  SendOutlined,
  BarChartOutlined,
} from '@ant-design/icons';


export const menuGlobal = [
  {
    path: '/',
    component: BasicLayout,
    childRoutes: [
      {
        path: '/orderRenter',
        name: '订单中心',
        icon: <ContainerOutlined />,
        component: OrderRenter,
      },
      {
        path: 'newOrder',
        name: '新建订单',
        icon: <CloudServerOutlined />,
        component: NewOrder,
      },
      {
        path: 'measureData',
        name: '测量数据',
        icon: <CloudDownloadOutlined />,
        component: MeasureData,
      },
      {
        path: 'afterSale',
        name: '售后列表',
        icon: <CustomerServiceOutlined />,
        component: AfterSale,
      },
      {
        path: 'orderTrans',
        name: '订单转化',
        icon: <BarChartOutlined />,
        component: OrderTrans,
      },
      {
        path: 'finance',
        name: '财务中心',
        icon: <AccountBookOutlined />,
        component: Finance,
      },
      {
        path: 'storeManagement',
        name: '店铺管理',
        icon: <InsertRowLeftOutlined />,
        component: StoreManagement,
      },
      {
        path: 'serviceArea',
        name: '服务区域',
        icon: <SendOutlined />,
        component: ServiceArea,
      },

    ]
  }
];

export function RouterConfig({ history, app }) {
  let route = renderRouteConfig(menuGlobal, '/');
  return (
    <Router history={history} basename={'/'}>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route path="/success" component={PaySuccess} />
        <Route path="/download" component={Download} />
        <Route path="/direct" component={DirectOrder} />
        <Route path="/orderinfo" component={OrderInfo} />
        <Route path="/fastremark" component={FastRemark} />
        {/* <Route path="/" render={() => { return route }} /> */}
        <Route path="/" render={() => {
          return getSession() ? route : <Redirect to="/login" />;
        }} />

      </Switch>
    </Router>
  );
}
export function MenuConfig(role) {
  return menuGlobal;
}
