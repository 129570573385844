import React, { Component } from 'react';
import { Modal, Upload, Radio, Image, Button, message, Input, Table, Spin, InputNumber, Popconfirm } from 'antd';
import styles from './index.module.css';

const roomType = ['主卧', '次卧', '客厅', '厨房', '客餐厅', '阳台', '书房', '卫生间', '儿童房', '餐厅', '保姆房', '商务年会'];
class RoomModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      index: -1,
      name: '',
      editLoading: false,
    };
  }
  render() {
    const { visible, editLoading, name } = this.state;
    return (
      <Modal
        title={'修改窗帘名称'}
        width={700}
        visible={visible}
        onCancel={this.onCancel}
        onOk={this.onOk}
        okText='确定'
        cancelText='取消'
        footer={
          <div className='row-r'>
            <Button onClick={this.onCancel}>取消</Button>
            <Button onClick={this.onOk} type='primary' loading={editLoading}>确定</Button>
          </div>
        }
      >
        <div className='col-t vertical-lt'>
          <div className='row-l margin-b10'>
            <div>房间名称：</div>
            <Input value={name} className='w500' onChange={this.changeName} />
          </div>
          <div className='row-w'>
            {
              roomType.map((item, index) => {
                return (
                  <div key={index} className={styles['room_type_div']} onClick={this.onRoomModalValue.bind(this, item)}>{item}</div>
                )
              })
            }
          </div>
        </div>
      </Modal>
    );
  }
  changeName = (e) => {
    this.setState({
      name: e.target.value,
    });
  }
  onRoomModalValue = (e) => {
    this.setState({
      name: e,
    });
  }
  onShow = (index, name) => {
    this.setState({
      visible: true,
      index, name
    });
  }
  onOk = () => {
    const { index, name } = this.state;
    if (name === '') {
      message.warning('名称不能为空');
      return;
    }
    this.props.updateRoom('room', index, name)
    this.onCancel()
  }
  onCancel = () => {
    this.setState({
      visible: false,
      index: -1,
      name: '',
    });
  }
}
export default RoomModal;