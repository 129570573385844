import React, { Fragment, Component } from 'react';
import DocumentTitle from 'react-document-title';
import styles from './index.module.css';
import { Image } from 'antd';
import logo from '../../resource/png/mmLogo.png';
import success from '../../resource/png/paysuccess.png';

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  render() {
    return (
      <Fragment>
        <DocumentTitle title="支付成功">
          <div className='w100 h100 col-t'>
            <div className={`${styles['wi60']} h100`}>
              <Image src={logo} preview={false} />
              <div className={styles['border']}>
                <div className={`${styles['borTop']} row-l`}>
                  <Image src={success} preview={false} className={`${styles['success']}`} />
                  <div className='fz22 margin-l10 row-l'>
                    <div>支付成功，您已成功充值 。</div>
                    <a href='https://sh.immgj.com.cn'>返回</a>
                  </div>
                </div>
                <div className={`${styles['borbottom']} row-l`}>
                  <div className='fz16 margin-l10'>您的充值已经打到墨墨总部，请重新登录查看您的余额，如有问题请联系客服</div>
                </div>
              </div>
            </div>
          </div>
        </DocumentTitle>
      </Fragment>
    );
  }
}
export default Index;