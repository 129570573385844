import React, { Component } from 'react';
import styles from './index.module.css';
import { Form, Input, message, Button, InputNumber, Modal, TimePicker } from 'antd';
import { addRemark, editRemark, delRemark, sortRemark } from '../../services/newOder';

const remark = {
  id: 0,
  service: '',
  shop: 0,
  remark: '',
  sort: 0,
};

class RemarkModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      shop: 0,
      type: '',
      remarkList: [],
      editIndex: -1,
      newRemark: {
        id: 0,
        service: '',
        shop: 0,
        remark: '',
        sort: 0,
      },

    };
  }
  render() {
    const { visible, remarkList, editIndex, newRemark } = this.state;
    return (
      <Modal
        title='商家备注管理'
        visible={visible}
        width={700}
        cancelText='关闭'
        onCancel={this.onCancel}
        okText={null}
      >
        <div className='col-t vertical-lt w100'>
          <div className={`${styles['box']} col-t vertical-lt`}>
            <div className='margin-b10'></div>
            {
              remarkList.map((item, index) => {
                return (
                  <div className='row-l margin-b10 margin-l10' key={index}>
                    <Input.TextArea
                      value={item.remark}
                      className={`${styles['singleRemark']} ${styles[editIndex === index ? 'remark-edit' : 'remark']}`}
                      onChange={this.changeInput.bind(this, index)}
                      disabled={editIndex !== index}
                    />
                    <Button className={`${styles[editIndex === index ? 'remart-btn-edit' : 'remark']} h54 bold fz16`}
                      onClick={this.toEdit.bind(this, index)}
                    >
                      {editIndex === index ? '保存' : '编辑'}
                    </Button>
                    <div className='col-sb w60'>
                      <Button onClick={this.sortLocateRemark.bind(this, 'up', index)} className={`${styles['btn-move']}`}>上移</Button>
                      <Button onClick={this.sortLocateRemark.bind(this, 'down', index)} className={`${styles['btn-move']}`}>下移</Button>
                    </div>
                    <Button onClick={this.deleteRemark.bind(this, index)} className='h54 back-color5 color0 bold border-r5 fz16'>删除</Button>
                  </div>
                );
              })
            }
          </div>
          <div className='row-l margin-t10'>
            <Input.TextArea value={newRemark.remark} onChange={this.editNewRemark} className={styles['singleRemark']} />
            <Button onClick={this.newRemark} type='primary' className='h54'>新增</Button>
          </div>
        </div>
      </Modal>
    );
  }
  toEdit = async (e) => {
    const { editIndex } = this.state;
    let remarkList = [...this.state.remarkList];
    if (editIndex === e) {
      let res = await editRemark(remarkList[e].id, remarkList[e].remark);
      if (res.status === 204) {
        message.success('修改成功');
        this.setState({
          editIndex: -1
        });
        this.props.updataRemark(remarkList, this.state.type);
      }
    } else {
      this.setState({
        editIndex: e
      })
    }
  }
  editNewRemark = (e) => {
    let newRemark = { ...this.state.newRemark };
    newRemark.remark = e.target.value;
    this.setState({
      newRemark
    });
  }
  changeInput = (index, e) => {
    let remarkList = [...this.state.remarkList];
    remarkList[index].remark = e.target.value;
    this.setState({
      remarkList,
    });
  }
  newRemark = async () => {
    const { shop, type, } = this.state;
    let remarkList = [...this.state.remarkList];
    let newRemark = { ...this.state.newRemark };
    newRemark.service = type;
    newRemark.shop = shop;
    let res = await addRemark(newRemark);
    if (res.status == 200) {
      remarkList.push(res.data)
      message.success('新增成功');
      this.setState({
        remarkList,
        newRemark: {
          id: 0,
          service: '',
          shop: 0,
          remark: '',
          sort: 0,
        },
      })
      this.props.updataRemark(remarkList, type);
    }
  }
  deleteRemark = async (e) => {
    let remarkList = [...this.state.remarkList];
    let res = await delRemark(remarkList[e].id);
    if (res.status === 204) {
      remarkList.splice(e, 1)
      message.success('删除成功');
      this.setState({
        remarkList
      });
      this.props.updataRemark(remarkList, this.state.type);
    }
  }
  sortLocateRemark = async (type, index) => {
    let remarkList = [...this.state.remarkList];
    if (type === 'up') {
      if (index > 0) {
        let res = await sortRemark(remarkList[index].id, remarkList[index - 1].id);
        if (res.status === 204) {
          let changeRemark = { ...remarkList[index] };
          remarkList[index] = { ...remarkList[index - 1] };
          remarkList[index - 1] = changeRemark;
          this.setState({
            remarkList
          });
          this.props.updataRemark(remarkList, this.state.type);
        }
      }
    } else {
      if (index < remarkList.length - 1) {
        let res = await sortRemark(remarkList[index].id, remarkList[index + 1].id);
        if (res.status === 204) {
          let changeRemark = { ...remarkList[index] };
          remarkList[index] = { ...remarkList[index + 1] };
          remarkList[index + 1] = changeRemark;
          this.setState({
            remarkList
          });
          this.props.updataRemark(remarkList, this.state.type);
        }
      }
    }
  }

  onShow = (shop, type, data) => {
    this.setState({
      visible: true,
      shop,
      type,
      remarkList: data,
    })
  }
  onCancel = () => {
    this.setState({
      visible: false,
    })
  }
}
export default RemarkModal;