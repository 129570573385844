import React, { Component } from 'react';
import { Modal, Select, Radio, Image, Button, message, Input, Table, Spin, InputNumber } from 'antd';
import styles from './index.module.css';
import moment from 'moment';

class AppointModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      appointList: [],
    };
    this.column = [
      {
        title: '时间',
        align: 'center',
        dataIndex: 'submit_time',
        width: 150,
        render: (text, record, index) => {
          return (<div>{moment(record.submit_time).format('YYYY/MM/DD-HH:mm:ss')}</div>);
        }
      },
      {
        title: '提交人',
        align: 'center',
        dataIndex: 'name',
        width: 200,
        render: (text, record, index) => {
          return (<div>{record.name}({record.ascription})</div>);
        }
      },
      {
        title: '记录',
        align: 'center',
        dataIndex: 'content',
        width: 200,
      },

    ];
  }
  render() {
    const { visible, appointList } = this.state;
    return (
      <Modal
        title={'预约记录'}
        width={700}
        visible={visible}
        onCancel={this.onCancel}
        cancelText='取消'
        footer={
          <div className='row-r'>
            <Button onClick={this.onCancel} type='primary'>关闭</Button>
          </div>
        }
      >
        <Table
          columns={this.column}
          dataSource={appointList}
          size={'small'}
          rowKey={record => record.id}
          bordered
        />
      </Modal>
    );
  }
  onShow = (e) => {
    this.setState({
      visible: true,
      appointList: e,
    })
  }
  onCancel = () => {
    this.setState({
      visible: false,
      appointList: [],
    })
  }
}
export default AppointModal;
