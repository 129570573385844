/* eslint-disable import/no-anonymous-default-export */
const toString62 = function (num) {
  let res = '';
  let str = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  do {
    let rem = num % 62;
    num = Math.floor(num / 62);
    let z = str.substr(rem, 1);
    res = `${z}${res}`;
  }
  while (num > 0);
  return res;
};

const toString36 = function (num) {
  let res = '';
  let str = '0123456789abcdefghijklmnopqrstuvwxyz';
  do {
    let rem = num % 36;
    num = Math.floor(num / 36);
    let z = str.substr(rem, 1);
    res = `${z}${res}`;
  }
  while (num > 0);
  return res;
};

export default {
  toString62: toString62,
  toString36: toString36
};