import request from '../utils/request';

export async function getFinancebase() {
  return await request('shopBiz/financebase', 'get');
}


export async function getFinanceList(info, type, exports, start, end, method, skip, take) {
  return await request(`shopBiz/financelist?info=${info}&type=${type}&export=${exports}&start=${start}&end=${end}&method=${method}&skip=${skip}&take=${take}`, 'get');
} 3

export async function getCharge(price) {
  return await request(`shopBiz/alipay?price=${price}`, 'get');
}

