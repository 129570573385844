import React, { Component } from 'react';
import { Input, Popconfirm, Table, Button, Modal, Select, DatePicker } from 'antd';
import { getDepartment_Data, setBalance } from '../../config/storage';
import PageTurning from '../../components/pageTurning';
import { getOrderList } from '../../services/orderRenter';
import { delOrder, getAccount } from '../../services/product';
import OrderModal from './orderModal';
import ChargeModal from './chargeModal';
import moment from 'moment';

const dateFormat = 'YYYY-MM-DD';
const startDate = moment().subtract(1, 'month').format(dateFormat);
const endDate = moment().add(1, 'day').format(dateFormat);
const take = 10;
class ProductOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      services: [],
      searchInfo: {
        serviceId: 0,
        search: '',
        start: startDate,
        end: endDate,
      },
      skip: 0,
      dataSource: [],
      balance: 0,
    };
    this.pageTurning = React.createRef();
    this.orderModal = React.createRef();
    this.chargeModal = React.createRef();
    this.shop = getDepartment_Data();
    this.columns = [
      {
        title: '订单编号',
        align: 'center',
        dataIndex: 'id',
        width: 100,
      },
      {
        title: '订单状态',
        align: 'center',
        dataIndex: 'state',
        width: 100,
        render: (text, record, index) => {
          return (<div>{record.state === '待预约' ? '加工中' : record.state}</div>);
        }
      },
      {
        title: '关联订单',
        align: 'center',
        dataIndex: 'links',
        width: 100,
        render: (text, record, index) => {
          let noSell = record.links.filter(r => r.type !== '销售');
          return (
            <div className='w100'>
              {
                record.links.map((litem, lindex) => {
                  return (
                    <div className='tl' key={lindex}>{litem.type + '-' + litem.link + '-' + litem.state}</div>
                  );
                })
              }

            </div>);
        }
      },
      {
        title: '客户名',
        align: 'center',
        dataIndex: 'name',
        width: 100,
      },
      {
        title: '手机号',
        align: 'center',
        dataIndex: 'phone',
        width: 100,
      },
      {
        title: '下单时间',
        align: 'center',
        dataIndex: 'phone',
        width: 100,
      },
      {
        title: '创建人',
        align: 'center',
        dataIndex: 'operator',
        width: 100,
      },
      {
        title: '操作',
        align: 'center',
        dataIndex: 'operate',
        width: 100,
        render: (text, record, index) => {
          return (
            <div className='row'>
              <Button onClick={this.toOrderInfo.bind(this, record)} type='primary' className='margin-r10'>{record.state === '草稿箱' ? '编辑' : '查看'}</Button>
              <Popconfirm
                title='您确定要删除这条订单吗?'
                onConfirm={this.delOrder.bind(this, record)}
                okText='确认'
                cancelText='取消'
                disabled={record.state !== '草稿箱'}
              >
                <Button disabled={record.state !== '草稿箱'} type='danger'>删除</Button>
              </Popconfirm>
            </div>
          );
        }
      },

    ];
  }
  componentDidMount() {
    let shopUsers = getDepartment_Data().shopUsers;
    shopUsers.unshift(
      {
        id: 0,
        name: '全部'
      },
      {
        id: getDepartment_Data().id,
        name: getDepartment_Data().userName,
      }
    )
    this.setState({
      services: shopUsers,
      serviceId: shopUsers[0].id,
    }, () => this.getList())
  }
  render() {
    const { services, searchInfo, dataSource, balance, } = this.state;
    return (
      <div>
        <div className='bar col-t vertical-lt w100 margin-b10'>
          <div className='w100 row-sb margin-b10'>
            <div className='row-l'>
              <span className='w80'>下单客服:</span>
              <Select
                showSearch
                className='w295'
                placeholder="请输入/选择下单客服"
                optionFilterProp="children"
                value={searchInfo.serviceId}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                }
                onChange={this.onChangeSelect.bind(this, 'serviceId')}
              >
                {
                  services.map(item => {
                    return (
                      <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>
                    )
                  })
                }
              </Select>
              <span className='margin-l20 w80'>下单时间:</span>
              <DatePicker placeholder='开始时间' className='w198' allowClear={false}
                value={moment(searchInfo.start, dateFormat)} onChange={this.onChangeSelect.bind(this, 'start')} />
              <div>至</div>
              <DatePicker placeholder='结束时间' className='w198' allowClear={false}
                value={moment(searchInfo.end, dateFormat)} onChange={this.onChangeSelect.bind(this, 'end')} />
            </div>
            <div className='row'>
              <div className='fz20 bold margin-r10'>余额：{balance}</div>
              <Button onClick={this.toCharge} type='primary'>充值</Button>
            </div>
          </div>
          <div className='row-l'>
            <span className='w80'>搜索:</span>
            <Input value={searchInfo.search} className='w295'
              onChange={this.onChangeSelect.bind(this, 'search')} />
            <Button onClick={this.toSearch} type='primary'>搜索</Button>
            <Button onClick={this.toOrderInfo.bind(this, { id: 0 })} type='primary' className='margin-l20'>新建</Button>
          </div>
        </div>
        <div className='bar'>
          <Table
            columns={this.columns}
            dataSource={dataSource}
            size={'small'}
            rowKey={record => record.id}
            bordered
            pagination={false}
          />
          <PageTurning ref={this.pageTurning} pageMaps={this.pageMaps} pageSize={take} />
        </div>
        <OrderModal ref={this.orderModal} updateList={this.getList} />
        <ChargeModal ref={this.chargeModal} />
      </div>
    );
  }
  toCharge = () => {
    this.chargeModal.current.onShow();
  }
  //下单客服
  onChangeSelect = (type, e) => {
    let searchInfo = { ...this.state.searchInfo };
    switch (type) {
      case 'search':
        searchInfo[type] = e.target.value;
        break;
      case 'serviceId':
        searchInfo[type] = e;
        break;
      case 'start':
      case 'end':
        searchInfo[type] = moment(e).format(dateFormat);
        break;
    }
    this.setState({
      searchInfo
    });
  }
  toSearch = () => {
    this.pageTurning.current.returnFirst();
    this.setState({
      skip: 0,
    }, () => this.getList());
  }
  getList = async () => {
    const { searchInfo, skip } = this.state;
    let data = {
      user: searchInfo.serviceId,
      search: '销售订单',
      info: searchInfo.search,
      start: searchInfo.start,
      end: searchInfo.end,
      category: '全部',
      service: '全部',
      state: '全部',
      types: '全部',
      skip: skip,
      take: take,
    }
    let balance = 0;
    let res = await getOrderList(data);
    let rsp = await getAccount(this.shop.lj_shop);
    if (rsp.status === 200) {
      balance = rsp.data;
    }
    if (res.status === 200) {
      this.setState({
        dataSource: res.data,
        balance,
      });
    }
  }
  toOrderInfo = (e) => {
    this.orderModal.current.onShow(e);
  }
  delOrder = async (e) => {
    let orderid = e.links[0].link;
    let res = await delOrder(e.id, orderid);
    if (res.status === 200) {
      this.getList();
    }
  }
  pageMaps = ({ skip, take }) => {
    this.setState({
      skip: skip,
    }, () => this.getList())
  }
}
export default ProductOrder;
